<template>
  <div v-if="task" class="bg-[#FBFBFB]">
    <div class="border-vgmedturq flex flex-col border-b-4 border-solid p-4">
      <button v-if="!uiStore.tabletOrSmaller" class="flex" @click="backToTask">
        <fa-icon
          icon="fa-regular fa-chevron-left"
          class="bg-vgmedturq h-8 w-8 rounded-full px-1 pt-1 text-center text-lg text-white"
          style="line-height: 1.5rem"
        />
        <span
          class="text-vgmedturq self-center pl-2 text-left text-sm uppercase"
          >Back to task</span
        >
      </button>
      <p class="ml-8 text-sm uppercase">Edit Task Time Entries:</p>
      <p class="ml-8 font-semibold">TASK: {{ task.title }}</p>
    </div>
    <div class="flex-1 p-4">
      <div class="flex justify-end">
        <button
          v-if="
            !editing.timeEntry &&
            TIME_EDITABLE_TASK_STATUS.includes(task.status)
          "
          class="bg-vgmedturq rounded-xl p-4 text-base font-semibold uppercase text-white"
          @click="showNewEntry"
        >
          Add
        </button>
      </div>
      <form
        v-if="editing.timeEntry && !editing.timeEntry.uuid"
        ref="timeEntryFormAdd"
        class="mx-auto flex max-w-xl gap-4 bg-white p-4 shadow"
        @submit.prevent="handleAddTimeEntry"
      >
        <div class="flex-1">
          <div class="flex max-w-xs flex-row items-end gap-4">
            <label class="col-span-full text-base">
              Start Date
              <fa-icon class="text-vgmedturq" icon="fa-regular fa-asterisk" />
              <v-menu
                ref="start_date_menu"
                v-model="startDatePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset="y"
                min-width="290px"
              >
                <template #activator="{ props }">
                  <v-text-field
                    v-model="editing.timeEntry.start_date"
                    class="min-w-[10rem]"
                    variant="outlined"
                    :color="vgMedTurq"
                    readonly
                    :rules="[requiredRule]"
                    required
                    v-bind="props"
                  >
                    <template #prepend-inner>
                      <fa-icon
                        icon="fa-regular fa-calendar-day"
                        class="-mt-1 pr-2 text-lg text-slate-800"
                      />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  :model-value="
                    new Date(editing.timeEntry.start_date + 'T00:00:00')
                  "
                  :color="vgMedTurq"
                  :max="today"
                  @update:modelValue="
                    (event) => {
                      editing.timeEntry.start_date = event
                        .toISOString()
                        .split('T')[0];
                      startDatePicker = false;
                    }
                  "
                >
                </v-date-picker>
              </v-menu>
            </label>
            <label>
              Start Time
              <fa-icon class="text-vgmedturq" icon="fa-regular fa-asterisk" />
              <v-text-field
                v-model="editing.timeEntry.start_time"
                type="time"
                class="min-w-[10rem] shrink-0"
                variant="outlined"
                :color="vgMedTurq"
              />
            </label>
          </div>
          <div class="flex max-w-xs flex-row items-end gap-4">
            <label class="col-span-full text-base">
              End Date
              <fa-icon class="text-vgmedturq" icon="fa-regular fa-asterisk" />
              <v-menu
                ref="end_date_menu"
                v-model="endDatePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset="y"
                min-width="290px"
              >
                <template #activator="{ props }">
                  <v-text-field
                    v-model="editing.timeEntry.end_date"
                    class="min-w-[10rem]"
                    variant="outlined"
                    :color="vgMedTurq"
                    readonly
                    :rules="[requiredRule]"
                    required
                    v-bind="props"
                  >
                    <template #prepend-inner>
                      <fa-icon
                        icon="fa-regular fa-calendar-day"
                        class="-mt-1 pr-2 text-lg text-slate-800"
                      />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  :model-value="
                    new Date(editing.timeEntry.end_date + 'T00:00:00')
                  "
                  :color="vgMedTurq"
                  :max="today"
                  @update:modelValue="
                    (event) => {
                      editing.timeEntry.end_date = event
                        .toISOString()
                        .split('T')[0];
                      endDatePicker = false;
                    }
                  "
                >
                </v-date-picker>
              </v-menu>
            </label>
            <label>
              End Time
              <fa-icon class="text-vgmedturq" icon="fa-regular fa-asterisk" />
              <v-text-field
                v-model="editing.timeEntry.end_time"
                type="time"
                class="min-w-[10rem] shrink-0"
                variant="outlined"
                :color="vgMedTurq"
              />
            </label>
          </div>
        </div>
        <button
          class="text-vgmedturq self-end p-4 text-base font-semibold uppercase"
          @click.prevent="editing.timeEntry = null"
        >
          Cancel</button
        ><button
          class="bg-vgmedturq self-end rounded-xl p-4 text-base font-semibold uppercase text-white"
          type="submit"
        >
          Add
        </button>
      </form>
      <div class="flex grow justify-end">
        <span
          class="text-vgmedturq-700 bg-vgmedturq-100/50 mt-8 rounded-lg p-4 font-semibold"
          >Total Time Logged:
          <span class="text-vgmedturq">
            {{ formatDurationShort(task.time_logged_minutes) }}
          </span>
        </span>
      </div>
      <div class="px-4 font-semibold">Results ({{ timeEntries.length }})</div>
      <div
        v-if="timeEntries.length === 0"
        class="p-8 text-center text-slate-500"
      >
        No time entries yet!
      </div>
      <div
        v-for="entry in timeEntries"
        :key="entry.uuid"
        class="group my-4 grid w-full grid-flow-row gap-4 rounded-lg bg-white p-4 text-base shadow lg:grid-flow-col"
        role="row"
      >
        <div class="flex self-center">
          <v-avatar size="42">
            <img
              :src="
                entry.avatar_id
                  ? `${API_URL}/file/avatar/${entry.avatar_id}`
                  : '/img/img-quote_avatar.png'
              "
              alt="avatar"
            />
          </v-avatar>
          <div class="ml-4 flex grow flex-col self-center" role="cell">
            {{ entry.known_as }}
          </div>
        </div>
        <form
          v-if="editing.timeEntry?.uuid === entry.uuid"
          ref="timeEntryForm"
          class="flex gap-4"
          @submit.prevent="handleUpdateTimeEntry"
        >
          <div class="flex-1">
            <div class="flex max-w-xs flex-row items-center gap-4">
              <label class="col-span-full text-base">
                Start
                <fa-icon class="text-vgmedturq" icon="fa-regular fa-asterisk" />
                <v-menu
                  ref="start_date_menu"
                  v-model="startDatePicker"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset="y"
                  min-width="290px"
                >
                  <template #activator="{ props }">
                    <v-text-field
                      v-model="editing.timeEntry.start_date"
                      class="min-w-[10rem]"
                      variant="outlined"
                      :color="vgMedTurq"
                      readonly
                      :rules="[requiredRule]"
                      required
                      v-bind="props"
                    >
                      <template #prepend-inner>
                        <fa-icon
                          icon="fa-regular fa-calendar-day"
                          class="-mt-1 pr-2 text-lg text-slate-800"
                        />
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    :model-value="
                      new Date(editing.timeEntry.start_date + 'T00:00:00')
                    "
                    :color="vgMedTurq"
                    :max="today"
                    @update:modelValue="
                      (event) => {
                        editing.timeEntry.start_date = event
                          .toISOString()
                          .split('T')[0];
                        startDatePicker = false;
                      }
                    "
                  >
                  </v-date-picker>
                </v-menu>
              </label>
              <label>
                Start Time
                <fa-icon class="text-vgmedturq" icon="fa-regular fa-asterisk" />
                <v-text-field
                  v-model="editing.timeEntry.start_time"
                  type="time"
                  class="min-w-[10rem] shrink-0"
                  variant="outlined"
                  :color="vgMedTurq"
                />
              </label>
            </div>
            <div class="flex max-w-xs flex-row items-center gap-4">
              <label class="col-span-full text-base">
                End
                <fa-icon class="text-vgmedturq" icon="fa-regular fa-asterisk" />
                <v-menu
                  ref="end_date_menu"
                  v-model="endDatePicker"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset="y"
                  min-width="290px"
                >
                  <template #activator="{ props }">
                    <v-text-field
                      v-model="editing.timeEntry.end_date"
                      class="min-w-[10rem]"
                      variant="outlined"
                      :color="vgMedTurq"
                      readonly
                      :rules="[requiredRule]"
                      required
                      v-bind="props"
                    >
                      <template #prepend-inner>
                        <fa-icon
                          icon="fa-regular fa-calendar-day"
                          class="-mt-1 pr-2 text-lg text-slate-800"
                        />
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    :model-value="
                      new Date(editing.timeEntry.end_date + 'T00:00:00')
                    "
                    :color="vgMedTurq"
                    :max="today"
                    @update:modelValue="
                      (event) => {
                        editing.timeEntry.end_date = event
                          .toISOString()
                          .split('T')[0];
                        endDatePicker = false;
                      }
                    "
                  >
                  </v-date-picker>
                </v-menu>
              </label>
              <label>
                End Time
                <fa-icon class="text-vgmedturq" icon="fa-regular fa-asterisk" />
                <v-text-field
                  v-model="editing.timeEntry.end_time"
                  type="time"
                  class="min-w-[10rem] shrink-0"
                  variant="outlined"
                  :color="vgMedTurq"
                />
              </label>
            </div>
          </div>
          <button
            class="text-vgmedturq self-end p-4 text-base font-semibold uppercase"
            @click.prevent="editing.timeEntry = null"
          >
            Cancel</button
          ><button
            class="bg-vgmedturq self-end rounded-xl p-4 text-base font-semibold uppercase text-white"
            type="submit"
          >
            Update
          </button>
        </form>
        <div v-else class="grid grid-cols-3 items-start gap-8 lg:items-center">
          <div>
            <span class="font-semibold uppercase text-slate-500"> Start: </span>
            {{ formatShortDateTime(entry.start_time) }}
          </div>
          <div>
            <span class="font-semibold uppercase text-slate-500"> End: </span>
            {{ formatShortDateTime(entry.end_time) || 'Ongoing' }}
          </div>
          <div>
            <span class="font-semibold uppercase text-slate-500">
              Duration: </span
            ><span class="text-vgmedturq font-semibold">
              {{ formatDurationSeconds(entry.duration) }}</span
            >
          </div>
        </div>
        <div
          v-if="TIME_EDITABLE_TASK_STATUS.includes(task.status)"
          class="shrink-0 self-center justify-self-end"
        >
          <fa-icon
            v-if="
              userStore.user &&
              (userStore.user.permissions.includes('askbetty_ppa') ||
                userStore.user.uuid === entry.user_uuid)
            "
            icon="fa-solid fa-pencil"
            class="text-slate-400"
            @click="handleEditTimeEntry(entry)"
          />
          <fa-icon
            v-if="
              userStore.user &&
              (userStore.user.permissions.includes('askbetty_ppa') ||
                userStore.user.uuid === entry.user_uuid)
            "
            icon="fa-solid fa-trash"
            class="ml-4 text-slate-400"
            @click="handleDeleteEntry(entry)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapStores } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useUiStore } from '@/stores/ui';
import { getTask } from '@/services/taskService';
import {
  getTimeEntriesForTask,
  saveTimeEntry,
  deleteTimeEntry,
} from '@/services/timeEntryService';
import { canGoBack } from '@/services/httpService';
import { getSocket } from '@/services/clientSessionService';
import {
  formatShortDateTime,
  formatShortDate,
  formatShortTime,
  formatDurationShort,
  formatDurationSeconds,
} from '@/services/formattingService';
import { API_URL } from '@/config';
import tailwind from 'tailwind.config';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'TaskTimeEntries',
  components: {},
  data() {
    const router = useRouter();
    const route = useRoute();
    return {
      route,
      router,
      task: null,
      timeEntries: [],
      today: new Date(),
      editing: {
        timeEntry: null,
        loading: false,
      },
      startDatePicker: false,
      endDatePicker: false,
      requiredRule: (v) => !!v || 'Required',
      API_URL,
      vgMedTurq: tailwind.theme.extend.colors.vgmedturq[500],
      vgNavy: tailwind.theme.extend.colors.vgnavy[500],
      TIME_EDITABLE_TASK_STATUS: [
        'active',
        'ppa to client review',
        'ppa to betty review',
      ],
    };
  },
  computed: {
    ...mapStores(useUserStore, useUiStore),
  },
  async created() {
    try {
      await this.taskUpdatedListener({ uuid: this.route.params.task_uuid });
    } catch (error) {
      this.$root.$snackbar.error(
        error.data?.message ?? 'An error occurred while getting task details'
      );
    }
    getSocket().on('askbetty.task_updated', this.taskUpdatedListener);
  },
  beforeUnmount() {
    getSocket().off('askbetty.task_updated', this.taskUpdatedListener);
  },
  methods: {
    formatShortDate,
    formatShortDateTime,
    formatDurationShort,
    formatDurationSeconds,
    backToTask() {
      if (canGoBack()) {
        this.router.go(-1);
      } else {
        this.router.push(`/tasks/${this.route.params.task_uuid}`);
      }
    },
    async taskUpdatedListener(payload) {
      if (payload.uuid === this.route.params.task_uuid) {
        try {
          this.task = await getTask(this.route.params.task_uuid);
          this.timeEntries = await getTimeEntriesForTask(
            this.route.params.task_uuid
          );
        } catch (error) {
          this.$root.$snackbar.error(
            error.data?.message ??
              'An error occurred while getting time entry details'
          );
        }
      }
    },
    showNewEntry() {
      if (this.editing.timeEntry) {
        return this.$root.$snackbar.error(
          'Save the current time entry form before adding a new time entry'
        );
      }
      this.editing.timeEntry = {
        start_date: this.today.toISOString().split('T')[0],
        end_date: this.today.toISOString().split('T')[0],
        start_time: '',
        end_time: '',
      };
    },
    handleEditTimeEntry(entry) {
      if (this.editing.timeEntry) {
        return this.$root.$snackbar.error(
          'Save the current time entry form before editing another time entry'
        );
      }
      this.editing.timeEntry = {
        uuid: entry.uuid,
        start_date: formatShortDate(entry.start_time, true),
        end_date: formatShortDate(entry.end_time, true),
        start_time: formatShortTime(entry.start_time),
        end_time: formatShortTime(entry.end_time),
      };
    },
    async handleAddTimeEntry() {
      try {
        if (
          !this.editing.timeEntry.start_date ||
          !this.editing.timeEntry.start_time ||
          !this.editing.timeEntry.end_date ||
          !this.editing.timeEntry.end_time
        ) {
          return this.$root.$snackbar.error('Please fill in all fields');
        }
        this.editing.loading = true;
        const newTimeEntry = {
          start_time: new Date(
            `${this.editing.timeEntry.start_date} ${this.editing.timeEntry.start_time}`
          ).toISOString(),
          end_time: new Date(
            `${this.editing.timeEntry.end_date} ${this.editing.timeEntry.end_time}`
          ).toISOString(),
        };

        await saveTimeEntry(this.task, newTimeEntry);
        this.editing.timeEntry = null;
      } catch (error) {
        return this.$root.$snackbar.error(
          error.data?.message ?? 'An error occurred while adding time entry'
        );
      } finally {
        this.editing.loading = false;
      }
    },
    async handleUpdateTimeEntry() {
      try {
        this.editing.loading = true;
        const existingTimeEntry = this.timeEntries.find(
          (entry) => entry.uuid === this.editing.timeEntry.uuid
        );
        if (!existingTimeEntry) {
          return this.$root.$snackbar.error(
            'An error occurred while saving time entry'
          );
        }
        existingTimeEntry.start_time = new Date(
          `${this.editing.timeEntry.start_date} ${this.editing.timeEntry.start_time}`
        ).toISOString();
        existingTimeEntry.end_time = new Date(
          `${this.editing.timeEntry.end_date} ${this.editing.timeEntry.end_time}`
        ).toISOString();
        await saveTimeEntry(this.task, existingTimeEntry);
        this.editing.timeEntry = null;
      } catch (error) {
        return this.$root.$snackbar.error(
          error.data?.message ?? 'An error occurred while saving time entry'
        );
      } finally {
        this.editing.loading = false;
      }
    },
    async handleDeleteEntry(entry) {
      if (
        !(await this.$root.$confirm.open(
          `Delete time entry?`,
          `Once deleted, it can not be restored. To confirm, please enter <b>CONFIRM</b> exactly as shown here and click <b>Delete</b>.`,
          {
            okText: 'Delete',
            cancelText: 'Cancel',
            requiredEntry: 'CONFIRM',
          }
        ))
      ) {
        return;
      }
      try {
        await deleteTimeEntry(this.task, entry);
      } catch (error) {
        return this.$root.$snackbar.error(
          error.data?.message ?? 'An error occurred while deleting time entry'
        );
      }
    },
  },
};
</script>
