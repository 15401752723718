<template>
  <div class="bg-[#FBFBFB] p-4">
    <div
      v-if="!uiStore.mobile"
      ref="tablist"
      class="m-4 flex gap-2 overflow-x-auto text-sm font-semibold"
    >
      <router-link to="/profile">
        <button
          class="grow rounded-lg border border-solid border-slate-300 px-6 py-2 uppercase tracking-wide lg:max-w-[8rem]"
          :class="
            $route.path === '/profile'
              ? 'bg-vgmedturq hover:bg-vgmedturq text-white'
              : 'bg-white hover:bg-slate-200 text-slate-500'
          "
        >
          Profile
        </button>
      </router-link>
      <router-link to="/billing">
        <button
          class="grow rounded-lg border border-solid border-slate-300 px-6 py-2 uppercase tracking-wide lg:max-w-[8rem]"
          :class="
            $route.path === '/billing'
              ? 'bg-vgmedturq hover:bg-vgmedturq text-white'
              : 'bg-white hover:bg-slate-200 text-slate-500'
          "
        >
          Billing
        </button>
      </router-link>
    </div>
    <div class="rounded-xl bg-white px-2 py-4 md:p-8">
      <div class="flex justify-end font-semibold">
        <h1 class="flex-1 pt-3 text-xl">Billing</h1>
        <router-link to="/billing/history" class="text-vgmedturq pt-3">
          <fa-icon icon="fa-regular fa-history" /> Billing History
        </router-link>
      </div>
      <div>
        <h2 class="mt-8 text-center text-lg font-semibold md:text-left">
          Account Balance
        </h2>
        <div
          class="mb-8 flex flex-col items-center justify-center gap-4 md:flex-row md:justify-start"
        >
          <div class="text-vgmedturq mt-1 text-xl font-semibold md:mx-16">
            {{ formatDurationShort(userStore.client?.client?.minutes_balance) }}
          </div>
          <button
            v-if="
              userStore.client?.client?.has_cc ||
              userStore.client?.client?.has_bypass_cc
            "
            class="bg-vgmedturq w-full rounded-full p-2 px-6 text-base font-semibold uppercase text-white disabled:opacity-50 md:w-fit"
            @click="addMinutes"
          >
            Add time
          </button>
        </div>
      </div>
      <hr />
      <h2 class="mt-4 text-lg font-semibold">Payment Methods:</h2>
      <div>
        <ClientCreditCards :disabled="!valid" :error="error">
          <template #address>
            <div class="mt-4 flex flex-col text-lg font-semibold">
              <p class="pb-2 text-base font-semibold">
                Billing Location
                <fa-icon
                  class="text-vgorange"
                  icon="fa-regular fa-asterisk mr-2"
                />
              </p>
              <div class="max-w-[30rem]" @click="editLocation()">
                <FullAddress
                  :edit="addressEdit"
                  :show-buttons="false"
                  :required="true"
                  :address="fullAddress"
                  :error="addressError"
                  @save="($event) => updateLocation($event)"
                  @hide="addressError = false"
                />
              </div>
            </div>
          </template>
        </ClientCreditCards>
      </div>
      <PurchaseMinutesDialog ref="purchaseMinutes"></PurchaseMinutesDialog>
    </div>
  </div>
</template>

<script>
import { useUserStore } from '@/stores/user';
import { useUiStore } from '@/stores/ui';
import { formatDurationShort } from '@/services/formattingService';
import ClientCreditCards from '@/components/ClientCreditCards.vue';
import PurchaseMinutesDialog from '@/dialogs/PurchaseMinutesDialog.vue';
import FullAddress from '@/components/FullAddress.vue';

export default {
  name: 'ClientBilling',
  components: {
    ClientCreditCards,
    PurchaseMinutesDialog,
    FullAddress,
  },
  data() {
    const userStore = useUserStore();
    const uiStore = useUiStore();
    const client = userStore.getClient;
    return {
      client,
      userStore,
      uiStore,
      loadingCard: false,
      loading: false,
      addressEdit: false,
      addressError: true,
      error: '',
      valid: null,
    };
  },
  computed: {
    fullAddress() {
      return {
        address_line_1: this.client.address_line_1,
        address_line_2: this.client.address_line_2,
        city: this.client.city,
        state_prov: this.client.state_prov,
        country: this.client.country,
        zipcode: this.client.zipcode,
      };
    },
  },
  created() {
    if (!this.client.city) {
      this.addressEdit = true;
    } else {
      this.valid = true;
    }
  },
  methods: {
    formatDurationShort,
    addMinutes() {
      this.$refs.purchaseMinutes.open(this.userStore.user);
    },
    updateLocation(data) {
      if (data.valid === false) {
        this.error =
          data.error || 'Please provide a complete address and try again';
        this.valid = false;

        return;
      }

      this.valid = true;

      try {
        this.userStore.client.client = {
          ...this.client,
          address_line_1: data.address.address_line_1,
          address_line_2: data.address.address_line_2,
          city: data.address.city,
          state_prov: data.address.state_prov,
          country: data.address.country,
          zipcode: data.address.zipcode,
          timezone: data.timezone,
          timezone_offset: data.timezone_offset,
        };
      } catch (error) {
        this.stage = 'error';
      } finally {
        this.addressEdit = false;
        this.addressError = false;
      }
    },
    editLocation() {
      if (this.userStore.user.is_primary_contact) {
        this.addressError = false;
        this.addressEdit = true;
      }
    },
  },
};
</script>
<style scoped>
* :deep(.stripe-card) {
  @apply mt-0 shadow-none  rounded-lg border-slate-400;
}
* :deep(.stripe-card:hover) {
  @apply border-slate-700;
}
</style>
