import { getSocket } from '@/services/clientSessionService';
import {
  getTaskMessages,
  saveTaskMessage,
  getTaskChatParticipants,
  getLastReadUuid,
} from '@/services/chatService';
import { MESSAGE_PAGINATION_LIMIT } from '@/config';
import { defineStore } from 'pinia';

export const useTaskChatStore = defineStore('taskChat', {
  state: () => ({
    tasks: {},
    selectedTaskUuid: null,
  }),
  getters: {
    selectedTask() {
      return this.tasks[this.selectedTaskUuid];
    },
    messages() {
      return this.selectedTask?.messages || [];
    },
  },
  actions: {
    async initMessages(task_uuid) {
      const taskChatUpdatedListener = (payload) => {
        this.loadNewMessages(payload.entity_uuid);
      };
      const taskChatTaskUpdatedListener = async (payload) => {
        if (task_uuid !== payload.uuid) {
          return;
        }

        const participants = await getTaskChatParticipants(task_uuid);
        this.tasks = {
          ...this.tasks,
          [task_uuid]: {
            ...this.tasks[task_uuid],
            participants,
          },
        };
      };
      if (
        !getSocket()
          .listeners('askbetty.chat_updated')
          ?.some((f) => f.name === 'taskChatUpdatedListener')
      ) {
        getSocket().on('askbetty.chat_updated', taskChatUpdatedListener);
      }
      if (
        !getSocket()
          .listeners('askbetty.task_updated')
          ?.some((f) => f.name === 'taskChatTaskUpdatedListener')
      ) {
        getSocket().on('askbetty.task_updated', taskChatTaskUpdatedListener);
      }

      const [messages, last_read_uuid, participants] = await Promise.all([
        getTaskMessages(task_uuid),
        getLastReadUuid(task_uuid),
        getTaskChatParticipants(task_uuid),
      ]);
      this.tasks = {
        ...this.tasks,
        [task_uuid]: {
          messages,
          last_read_uuid,
          participants,
          newMessage: '',
          showOlderButton: messages.length >= MESSAGE_PAGINATION_LIMIT,
        },
      };
      this.selectedTaskUuid = task_uuid;
    },
    async loadOlderMessages(task_uuid) {
      if (!this.tasks[task_uuid]) {
        return;
      }

      const oldestMessageUuid = this.tasks[task_uuid].messages[0]?.uuid;

      if (!oldestMessageUuid) {
        return;
      }

      const messages = await getTaskMessages(task_uuid, [
        `before=${oldestMessageUuid}`,
      ]);
      messages.reverse().forEach((newMessage) => {
        if (
          !this.tasks[task_uuid].messages.some(
            (message) => message.uuid === newMessage.uuid
          )
        ) {
          this.tasks[task_uuid].messages.unshift(newMessage);
        }
      });
      if (messages.length < MESSAGE_PAGINATION_LIMIT) {
        this.tasks[task_uuid].showOlderButton = false;
      }
    },
    async loadNewMessages(task_uuid) {
      if (!this.tasks[task_uuid]) {
        return;
      }

      let filters = [];

      if (this.tasks[task_uuid].messages.length > 0) {
        filters.push(
          `since=${
            this.tasks[task_uuid].messages[
              this.tasks[task_uuid].messages.length - 1
            ]?.uuid
          }`
        );
      }

      const messages = await getTaskMessages(task_uuid, filters);

      messages.forEach((newMessage) => {
        if (
          !this.tasks[task_uuid].messages.some(
            (message) => message.uuid === newMessage.uuid
          )
        ) {
          this.tasks[task_uuid].messages.push(newMessage);
        }
      });
      if (messages.length < MESSAGE_PAGINATION_LIMIT) {
        this.tasks[task_uuid].showOlderButton = false;
      }
    },
    async sendMessage() {
      if (!this.tasks[this.selectedTaskUuid]) {
        return;
      }
      if (!this.tasks[this.selectedTaskUuid].newMessage) {
        return;
      }

      await saveTaskMessage(this.selectedTaskUuid, {
        message: this.tasks[this.selectedTaskUuid].newMessage,
        source: 'main',
      });
      this.tasks[this.selectedTaskUuid] = {
        ...this.tasks[this.selectedTaskUuid],
        newMessage: '',
      };
    },
  },
});
