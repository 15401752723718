import { getJSON, postJSON, deleteAction } from '@/services/httpService';

export const getTaskAttachments = (task_uuid) =>
  getJSON(`/askbetty/v2/task/attachment/${task_uuid}`);

export const deleteTaskAttachment = (uuid, attachmentKey) =>
  deleteAction(`/askbetty/v2/task/attachment/${uuid}/${attachmentKey}`);

export const uploadTaskAttachment = (uuid, user_uuid, file) => {
  return postJSON(
    `/askbetty/v2/task/attachment/${uuid}?filename=${file.name}&user_uuid=${user_uuid}`,
    file,
    {
      'Content-Type': file.type,
    }
  );
};

export const uploadTaskUrl = (uuid, payload) => {
  return postJSON(`/askbetty/v2/task/url/${uuid}`, payload);
};

export const uploadAvatar = (body, user_uuid) => {
  return postJSON(`/file/avatar/${user_uuid}`, body, {
    'Content-Type': 'image/png',
  });
};
