<template>
  <NotificationBadge
    :dot="!!task.unread"
    class="ring-vgmedturq my-4 grid w-full flex-none cursor-pointer grid-cols-12 rounded-lg border border-solid border-slate-100 bg-white p-4 text-left text-base shadow ring-offset-1 hover:border-white hover:ring-1"
    role="row"
    @click="$router.push(`/tasks/${task.uuid}`)"
  >
    <router-link
      class="col-span-11 place-self-start text-sm"
      :class="task.status === 'new' ? 'md:col-span-6' : 'md:col-span-9'"
      :to="`/tasks/${task.uuid}`"
      role="cell"
    >
      {{ task.title }}
    </router-link>
    <div
      class="order-3 col-span-6 font-semibold text-slate-500 md:order-2 md:col-span-2 lg:place-self-center"
      role="cell"
    >
      <fa-icon icon="fa-regular fa-paperclip" />
      {{ task.attachmentCount ?? 0 }}
    </div>
    <div v-if="uiStore.mobile" id="task-menu-container" class="order-2">
      <v-menu attach="#task-menu-container" location="bottom">
        <template #activator="{ props }">
          <button
            v-bind="props"
            class="focus:ring-vgmedturq rounded p-2 focus:ring-2"
            aria-label="Task menu"
            title="Task menu"
          >
            <fa-icon icon="fa-regular fa-ellipsis" />
          </button>
        </template>
        <div
          class="text-vgmedturq-600 flex flex-col rounded-lg border border-slate-100 shadow-sm shadow-gray-600"
        >
          <button
            v-for="(item, i) in options"
            :key="i"
            :aria-label="item.title"
            class="hover:bg-vgmedturq-100 focus:bg-vgmedturq-100 focus:ring-vgmedturq bg-white px-4 py-2 first:rounded-t-lg last:rounded-b-lg focus:ring-2"
            @click.stop="item.action(task)"
          >
            {{ item.title }}
          </button>
        </div>
      </v-menu>
    </div>
    <div
      v-if="!uiStore.mobile && task.status === 'new'"
      class="order-2 col-span-4 flex justify-between sm:order-3"
    >
      <router-link
        class="text-vgmedturq my-auto flex items-center place-self-center font-semibold uppercase tracking-wider sm:col-span-3 lg:place-self-end"
        role="cell"
        :to="`/tasks/${task.uuid}`"
      >
        <fa-icon icon="fa-regular fa-plus" class="px-1 text-lg font-bold" />
        <span class="text-sm">Add Task Details</span>
      </router-link>
      <div
        class="col-span-1 place-self-end self-center text-slate-500"
        role="cell"
      >
        <fa-icon
          icon="fa-regular fa-trash"
          class="text-base"
          @click.stop="handleDeleteTask(task)"
        />
      </div>
    </div>
  </NotificationBadge>
</template>

<script setup>
import { deleteTask } from '@/services/taskService';
import { inject, ref } from 'vue';
import NotificationBadge from '@/components/NotificationBadge.vue';
import { useRouter } from 'vue-router';
import { useUiStore } from '@/stores/ui';

const router = useRouter();
const snackbar = inject('snackbar');
const confirm = inject('confirm');
const uiStore = useUiStore();

const options = ref([
  {
    title: 'Add task details',
    action: (task) => router.push(`/tasks/${task.uuid}`),
  },
  {
    title: 'Delete',
    action: (task) => handleDeleteTask(task),
  },
]);
defineProps({
  task: {
    type: Object,
    default() {
      return {};
    },
  },
  handOffTo: {
    type: String,
    default() {
      return '';
    },
  },
});
async function handleDeleteTask(task) {
  try {
    if (
      !(await confirm.open(
        `Delete task?`,
        `Deleting task: <strong>${task.title}</strong><br><br>Once deleted, it can not be restored. To confirm, please enter <b>CONFIRM</b> exactly as shown here and click <b>Delete</b>.`,
        {
          okText: 'Delete',
          cancelText: 'Cancel',
          requiredEntry: 'CONFIRM',
        }
      ))
    ) {
      return;
    }
    await deleteTask(task.uuid);
  } catch (error) {
    return snackbar.error(
      error.data?.message ?? 'An error occurred while deleting task'
    );
  }
}
</script>
