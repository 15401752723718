import { getSocket } from '@/services/clientSessionService';
import { getClient, getClients } from '@/services/clientService';
import { getTasks, saveTask } from '@/services/taskService';
import {
  getClientMessages,
  saveClientMessage,
  getLastReadUuid,
} from '@/services/chatService';
import { playDing } from '@/services/notificationService';
import { MESSAGE_PAGINATION_LIMIT } from '@/config';
import { defineStore } from 'pinia';

export const usePpaClientStore = defineStore('ppaClient', {
  state: () => ({
    clients: {},
    selectedClientUuid: null,
  }),
  getters: {
    clientCount() {
      return Object.keys(this.clients).length;
    },
    selectedClient() {
      return this.selectedClientUuid && this.clients[this.selectedClientUuid];
    },
    allTasks() {
      return this.selectedClient?.tasks;
    },
    newTasks() {
      return this.selectedClient?.tasks.filter((task) => task.status === 'new');
    },
    activeTasks() {
      return this.selectedClient?.tasks.filter(
        (task) => task.status === 'active'
      );
    },
    activeTasksNotificationCount() {
      return this.activeTasks?.reduce(
        (accumulator, task) =>
          accumulator + (task.unread_count || 0) + (task.unread ? 1 : 0),
        0
      );
    },
    completeTasks() {
      return this.selectedClient?.tasks.filter(
        (task) => task.status === 'complete'
      );
    },
    completeTasksNotificationCount() {
      return this.completeTasks?.reduce(
        (accumulator, task) =>
          accumulator + (task.unread_count || 0) + (task.unread ? 1 : 0),
        0
      );
    },
    reviewTasks() {
      return this.selectedClient?.tasks.filter((task) =>
        task.status.endsWith('review')
      );
    },
    reviewTasksNotificationCount() {
      return this.reviewTasks?.reduce(
        (accumulator, task) =>
          accumulator + (task.unread_count || 0) + (task.unread ? 1 : 0),
        0
      );
    },
    selectedTasks() {
      if (!this.selectedClientUuid) {
        return [];
      }
      switch (this.selectedClient?.tab) {
        case 'new':
          return this.newTasks;
        case 'active':
          return this.activeTasks;
        case 'complete':
          return this.completeTasks;
        case 'review':
          return this.reviewTasks;
        default:
          return [];
      }
    },
    tab() {
      return this.selectedClient?.tab;
    },
    newTaskTitle() {
      return this.selectedClient?.newTaskTitle;
    },
  },
  actions: {
    registerTaskListener() {
      const taskUpdatedListener = async (payload) => {
        const user_uuid =
          payload.user_uuid ??
          Object.values(this.clients).find(
            (client) =>
              client.tasks?.find(
                (task) =>
                  task.uuid === payload.uuid ||
                  task.uuid === payload.entity_uuid
              ) || client.uuid === payload.entity_uuid
          )?.uuid;

        if (!user_uuid) {
          return;
        }
        const result = await getTasks([`user_uuid=${user_uuid}`]).catch(() => ({
          tasks: [],
          rowCount: 0,
        }));
        this.setClient({
          uuid: user_uuid,
          tasks: result.tasks,
        });
        if (user_uuid === payload.entity_uuid) {
          this.getClient(user_uuid);
        }
      };
      const ppaClientUpdatedListener = (payload) => {
        this.getClient(payload.uuid);
      };
      if (
        !getSocket()
          .listeners('askbetty.task_updated')
          ?.some((f) => f.name === 'taskUpdatedListener')
      ) {
        getSocket().on('askbetty.task_updated', taskUpdatedListener);
      }
      if (
        !getSocket()
          .listeners('askbetty.chat_updated')
          ?.some((f) => f.name === 'taskUpdatedListener')
      ) {
        getSocket().on('askbetty.chat_updated', taskUpdatedListener);
      }
      if (
        !getSocket()
          .listeners('askbetty.client_updated')
          ?.some((f) => f.name === 'ppaClientUpdatedListener')
      ) {
        getSocket().on('askbetty.client_updated', ppaClientUpdatedListener);
      }
    },
    async loadClients() {
      this.registerTaskListener();
      const result = await getClients().catch(() => []);
      result.forEach((client) => {
        this.setClient(client);
        this.loadTasks(client.uuid);
      });
    },
    async getClient(uuid) {
      const result = await getClient(uuid);
      if (
        this.clients[uuid] &&
        this.clients[uuid].unread_count < result.unread_count
      ) {
        playDing();
      }
      this.setClient(result);
    },
    async loadTasks(uuid) {
      this.registerTaskListener();
      const result = await getTasks([`user_uuid=${uuid}`]).catch(() => ({
        tasks: [],
        rowCount: 0,
      }));
      this.setClient({
        uuid,
        tasks: result.tasks,
      });
    },
    async selectClient(uuid) {
      if (!uuid) {
        this.selectedClientUuid = null;
        return;
      }

      if (uuid === this.selectedClientUuid) {
        return;
      }

      await this.getClient(uuid);
      this.selectedClientUuid = uuid;
    },
    async createTask() {
      this.setClient({
        uuid: this.selectedClientUuid,
        newTaskLoading: true,
      });

      let result = [];
      try {
        result = await saveTask({
          title: this.selectedClient.newTaskTitle,
          user_uuid: this.selectedClientUuid,
        });
      } finally {
        this.setClient({
          uuid: this.selectedClientUuid,
          newTaskTitle: '',
          tab: 'new',
          tasks: [result, ...this.selectedClient.tasks],
          newTaskLoading: false,
        });
      }

      return result;
    },
    async initMessages() {
      const [messages, last_read_uuid] = await Promise.all([
        getClientMessages(this.selectedClientUuid),
        getLastReadUuid(this.selectedClientUuid),
      ]);
      this.setClient({
        uuid: this.selectedClientUuid,
        messages,
        last_read_uuid,
        showOlderButton: messages.length === MESSAGE_PAGINATION_LIMIT,
      });
    },
    async loadOlderMessages() {
      const oldestMessageUuid = this.selectedClient?.messages[0]?.uuid;

      if (!oldestMessageUuid) {
        return;
      }
      const messages = await getClientMessages(this.selectedClientUuid, [
        `before=${oldestMessageUuid}`,
      ]);
      this.setClient({
        uuid: this.selectedClientUuid,
        messages: [...messages, ...this.selectedClient.messages].filter(
          (message, index, array) =>
            array.findIndex(
              (otherMessage) => otherMessage.uuid === message.uuid
            ) === index
        ),
        showOlderButton:
          messages.length < MESSAGE_PAGINATION_LIMIT
            ? false
            : this.selectedClient.showOlderButton,
      });
    },
    async loadNewMessages() {
      let filters = [];

      if (this.selectedClient.messages.length > 0) {
        filters.push(
          `since=${
            this.selectedClient.messages[
              this.selectedClient.messages.length - 1
            ]?.uuid
          }`
        );
      }

      const messages = await getClientMessages(
        this.selectedClientUuid,
        filters
      );

      this.setClient({
        uuid: this.selectedClientUuid,
        messages: [...this.selectedClient.messages, ...messages].filter(
          (message, index, array) =>
            array.findIndex(
              (otherMessage) => otherMessage.uuid === message.uuid
            ) === index
        ),
        showOlderButton:
          messages.length < MESSAGE_PAGINATION_LIMIT
            ? false
            : this.selectedClient.showOlderButton,
      });
    },
    async sendMessage() {
      if (!this.selectedClient.newMessage) {
        return;
      }

      await saveClientMessage(this.selectedClientUuid, {
        message: this.selectedClient.newMessage,
        source: 'main',
      });
      this.setClient({
        uuid: this.selectedClientUuid,
        newMessage: '',
      });
    },
    setClient(payload) {
      if (!payload.uuid) {
        return;
      }
      this.clients = {
        ...this.clients,
        [payload.uuid]: {
          tab: 'new',
          newTaskTitle: '',
          newTaskLoading: false,
          newMessage: '',
          showOlderButton: false,
          tasks: [],
          messages: [],
          ...this.clients[payload.uuid],
          ...payload,
        },
      };
    },
  },
});
