<template>
  <div
    class="min-h-screen"
    style="
      background-image: url('/img/login/early_access_bg.png');
      background-repeat: no-repeat;
      background-size: cover;
    "
  >
    <header class="flex items-center gap-4 border border-b-slate-300 p-2">
      <a href="/" aria-label="VG OnDemand home link"
        ><img
          src="/img/VG-OnDemand-logo-horizontal.svg"
          class="max-h-14 p-2"
          alt="VG OnDemand logo"
      /></a>
    </header>
    <div class="grid grid-cols-1 gap-8 px-8 pt-16 lg:grid-cols-2 lg:px-20">
      <div style="max-width: 600px">
        <v-form ref="form" v-model="valid" @submit.prevent="submit">
          <div v-if="nologin">
            <p class="text">
              We're sorry, but this email address can't be used to log into VG
              OnDemand because it's associated with another Virtual Gurus
              account. Please provide another email address.
            </p>
          </div>
          <div v-else-if="show === 'register'" class="max-w-sm">
            <div class="flex flex-col">
              <h1 class="text-3xl font-semibold">
                You’re
                <span
                  class="border-vgteal box-border inline-block border-b-4 pb-0 leading-5"
                >
                  almost there!</span
                >
              </h1>
              <p class="mb-4 mt-8 font-semibold">
                Get personalized service by adding your name and industry
              </p>
              <div class="col-span-2 mb-4 text-base md:col-span-1">
                <v-text-field
                  v-model="registerForm.full_name"
                  class="text-field bg-white text-base"
                  rounded="xl"
                  required
                  :color="vgMedTurq"
                  density="compact"
                  placeholder="Your Name (required)"
                  :rules="requiredRules"
                  variant="outlined"
                ></v-text-field>
              </div>
              <div class="col-span-2 mb-4 text-base md:col-span-1">
                <v-select
                  v-model="registerForm.industry"
                  :items="clientIndustries"
                  variant="outlined"
                  :color="vgMedTurq"
                  density="compact"
                  clearable
                  class="bg-white text-base"
                  rounded="xl"
                  placeholder="Your industry"
                ></v-select>
              </div>
              <div>
                <v-btn
                  type="submit"
                  class="bg-vgmedturq my-4 font-semibold"
                  :loading="loading"
                  color="#fff"
                  rounded
                  variant="text"
                  size="large"
                >
                  <span class="px-6 text-sm"> Sign-Up!</span>
                </v-btn>
              </div>
            </div>
          </div>
          <div v-else-if="show === 'reset_password'" class="max-w-md">
            <div>
              <h1 class="mb-6 text-3xl font-semibold">
                Check
                <span
                  class="border-vgteal box-border inline-block border-b-4 leading-5"
                  >your inbox!</span
                >
              </h1>
              <h2 class="text mb-4 tracking-wide">
                We’ve sent a confirmation email with a unique code to
                {{ email }}.
              </h2>
              <h2 class="text mb-4 tracking-wide">
                Please enter the code to set your password below:
              </h2>
              <div class="flex items-end gap-4">
                <label class="w-full font-semibold">
                  <div class="my-2">Enter Code:</div>
                  <v-text-field
                    v-model="resetPasswordToken"
                    :color="vgMedTurq"
                    density="compact"
                    class="text-base font-normal"
                    rounded="xl"
                    variant="outlined"
                    hide-details
                    bg-color="#fff"
                    placeholder="Confirmation Code"
                    required
                  ></v-text-field>
                </label>
                <div v-if="!noNewTokenButton">
                  <v-btn
                    :loading="loading"
                    variant="text"
                    block
                    size="large"
                    color="#fff"
                    rounded
                    class="bg-vgmedturq font-semibold"
                    @click="forgotPassword(true)"
                    ><span class="text-sm">Send new token</span></v-btn
                  >
                </div>
              </div>
              <label class="mt-6 flex flex-col font-semibold">
                <div class="mb-2 mt-6">Create your password:</div>
                <v-text-field
                  v-model="password"
                  :color="vgMedTurq"
                  variant="outlined"
                  class="text-base font-normal"
                  rounded="xl"
                  density="compact"
                  bg-color="#fff"
                  placeholder="New Password"
                  autocomplete="new-password"
                  :rules="passwordRules"
                  required
                  type="password"
                ></v-text-field>
                <v-text-field
                  v-model="confirmPassword"
                  :color="vgMedTurq"
                  variant="outlined"
                  density="compact"
                  class="text-base font-normal"
                  rounded="xl"
                  bg-color="#fff"
                  placeholder="Confirm Password"
                  autocomplete="new-password"
                  :rules="confirmPasswordRules"
                  required
                  type="password"
                ></v-text-field
              ></label>
              <div class="mt-6 max-w-[12rem]">
                <v-btn
                  type="submit"
                  class="bg-vgmedturq border-grey font-semibold"
                  :loading="loading"
                  color="#fff"
                  variant="text"
                  block
                  rounded
                  size="large"
                >
                  <span class="text-sm">Submit</span>
                </v-btn>
              </div>
            </div>
          </div>
          <div v-else-if="show === 'email'">
            <div class="pb-4 text-3xl font-semibold">
              <h1>
                Welcome to
                <span
                  class="border-vgteal box-border inline-block border-b-4 pb-0 leading-5"
                  >VG OnDemand!</span
                >
              </h1>
              <h1>We’re glad you’re here</h1>
            </div>
            <div class="flex flex-col gap-y-4 leading-6 tracking-wide">
              <p>With VG OnDemand, you get:</p>
              <div>
                <ul class="list-disc pl-6">
                  <li>On-demand support for all your admin needs</li>
                  <li>Flexible pay-as-you-go pricing</li>
                  <li>Your tasks done and your time saved!</li>
                </ul>
              </div>
            </div>
            <div class="mt-8 text-base font-semibold">
              <h3>Get started by entering your email</h3>
            </div>
          </div>
          <div>
            <template v-if="show === 'password'">
              <div class="flex flex-col">
                <h1 class="text-3xl font-semibold">
                  Welcome to
                  <span
                    class="border-vgteal box-border inline-block border-b-4 pb-0 leading-5"
                    >VG ONDEMAND!!</span
                  >
                </h1>
                <h1 class="text-3xl font-semibold">We’re glad you’re here</h1>
              </div>
            </template>
          </div>
          <div>
            <div v-if="show === 'email'">
              <div class="mb-4">
                <v-text-field
                  v-model.trim="email"
                  md6
                  placeholder="E-mail"
                  class="text-field mb-0 max-w-sm pb-0 text-base"
                  density="compact"
                  variant="outlined"
                  rounded="xl"
                  :rules="emailRules"
                  :error-messages="checkEmailError"
                  :error="!!checkEmailError"
                  :color="vgMedTurq"
                  required
                  @keypress.enter="checkEmail"
                ></v-text-field>
              </div>
              <v-btn
                class="bg-vgmedturq font-semibold"
                :loading="loading"
                color="#fff"
                variant="text"
                rounded
                size="large"
                @click="checkEmail"
              >
                <span class="px-6 text-sm">Continue</span>
              </v-btn>
            </div>
            <transition name="slide-fade" mode="out-in">
              <div v-if="show === 'password'" class="mb-4 mt-8 w-64">
                <label class="mt-8 w-full font-semibold">
                  Enter your password
                  <v-text-field
                    ref="loginPassword"
                    v-model="password"
                    md6
                    placeholder="Password"
                    autocomplete="new-password"
                    class="text-base"
                    rounded="xl"
                    :color="vgMedTurq"
                    variant="outlined"
                    density="compact"
                    bg-color="#fff"
                    :rules="requiredRules"
                    required
                    type="password"
                  ></v-text-field>
                </label>
                <v-btn
                  class="bg-vgmedturq font-semibold"
                  type="submit"
                  :loading="loading"
                  color="#fff"
                  variant="text"
                  rounded
                  size="large"
                  ><span class="px-6 text-sm">Submit</span></v-btn
                >
              </div>
            </transition>
            <transition name="slide-fade" mode="out-in">
              <div v-show="show === 'password'" class="pt-4">
                <a
                  class="text-vgorange cursor-pointer text-base font-semibold tracking-normal hover:underline"
                  style="margin: 0 auto"
                  @click.prevent.stop="forgotPassword(false)"
                >
                  Forgot your Password?
                </a>
              </div>
            </transition>
            <transition name="slide-fade" mode="out-in">
              <div
                v-show="show === 'password' || show === 'register'"
                class="mt-2"
              >
                <a
                  href="javascript:void(0)"
                  class="text-vgorange pb-4 text-base font-semibold tracking-normal hover:underline"
                  style="margin: 0 auto"
                  @click="
                    show = 'email';
                    email = '';
                    password = '';
                    confirmPassword = '';
                  "
                  >Use another account</a
                >
              </div>
            </transition>
          </div>
        </v-form>
      </div>
      <div class="mx-auto flex min-h-[400px] max-w-xs lg:mx-0">
        <img
          src="/img/login/early_access_hero-img.png"
          aria-hidden="true"
          class="max-h-[25rem]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  login,
  register,
  hasSession,
  checkUserExistByEmail,
  resetPassword,
  changePasswordByToken,
} from '@/services/clientSessionService';
import tailwind from 'tailwind.config';
import { validatePassword, isValidEmail } from '@/services/validationService';
import { mapStores } from 'pinia';
import { useUiStore } from '@/stores/ui';
import { useUserStore } from '@/stores/user';
import { useRouter, useRoute } from 'vue-router';

export default {
  name: 'Login',
  data() {
    const router = useRouter();
    const route = useRoute();

    return {
      route,
      router,
      loading: false,
      valid: false,
      submitted: false,
      nologin: false,
      noNewTokenButton: false,
      registerForm: {},
      password: '',
      resetPasswordToken: '',
      confirmPassword: '',
      requiredRules: [(v) => !!v || 'Required'],
      emailRules: [
        (v) => !!v || 'Required',
        (v) => isValidEmail(v) || 'E-mail must be valid',
      ],
      checkEmailError: '',
      show: 'email',
      passwordRules: [(v) => !!v || 'Required', validatePassword],
      confirmPasswordRules: [
        (v) => !!v || 'Required',
        (v) => v === this.password || 'Confirm Password must match',
      ],
      vgMedTurq: tailwind.theme.extend.colors.vgmedturq[500],
    };
  },

  computed: {
    ...mapStores(useUiStore, useUserStore),
    email: {
      get() {
        return this.uiStore.email;
      },
      set(email) {
        this.uiStore.setEmail(email);
      },
    },
    clientIndustries: function () {
      if (!this.userStore.clientConfig) {
        return [];
      }
      return this.userStore.clientConfig.client_industries
        .filter((industry) => [3, 11, 18, 42, 54, 999].includes(industry.id))
        .map((industry) => {
          return { value: industry.id, title: industry.value };
        });
    },
  },
  created() {
    if (hasSession()) {
      if (
        this.userStore.user.permissions.includes('askbetty_ppa') ||
        this.userStore.user.permissions.includes('askbetty_va') ||
        this.userStore.user.permissions.includes('askbetty_client')
      ) {
        this.router.push(this.userStore.homeLink);
      }
      return;
    }
    this.userStore.initClientConfig();
  },

  methods: {
    async forgotPassword(send_new_token) {
      this.loading = true;
      try {
        await resetPassword(this.email, send_new_token);
        this.show = 'reset_password';
      } catch (error) {
        return this.$root.$snackbar.error(
          error?.data?.message ??
            `Unable to reset password. Please try again later.`
        );
      } finally {
        this.loading = false;
      }

      if (send_new_token) {
        this.$root.$snackbar.message(
          `A new Password Reset Token has been sent to ${this.email}.`
        );
      }
    },
    async submit() {
      if (this.loading) {
        return;
      }

      if (!(await this.$refs.form.validate()).valid) {
        return;
      }

      if (this.show === 'register') {
        try {
          this.loading = true;
          await register(this.registerForm);
          this.show = 'reset_password';
          this.noNewTokenButton = true;
        } catch (error) {
          return this.$root.$snackbar.error(
            error?.data?.message ?? 'Sign up failed'
          );
        } finally {
          this.loading = false;
        }
        return;
      }

      try {
        this.loading = true;
        if (this.show === 'email') {
          await this.checkEmail();
          return;
        }

        if (this.show === 'reset_password') {
          await this.handleSetNewPassword();
        }

        const response = await login({
          email: this.email,
          password: this.password,
          referral_token: this.uiStore.referralToken,
        });

        this.userStore.saveSession({
          token: response.token,
          user: {
            ...response,
            token: undefined,
          },
        });
      } catch (error) {
        if (error?.data?.reason === 'early_access') {
          return this.router.push('/early_access');
        }
        return this.$root.$snackbar.error(
          error?.data?.message ?? 'Sign in failed'
        );
      } finally {
        this.loading = false;
        this.userStore.initUser();
      }

      if (this.route.query.redirect) {
        this.router.push(this.route.query.redirect);
      } else {
        this.router.push(this.userStore.homeLink);
      }
    },

    async checkEmail() {
      if (!(await this.$refs.form.validate()).valid) {
        return;
      }
      this.nologin = false;
      this.noNewTokenButton = false;

      try {
        const result = await checkUserExistByEmail(this.email);
        const matches = result.matches;
        this.noNewTokenButton = !!result.unverified;

        if (result.nextStage === 'register') {
          (this.registerForm = {
            full_name: '',
            business_email: this.email,
            industry: null,
          }),
            (this.show = 'register');
          return;
        } else if (result.nextStage === 'verify') {
          return;
        } else if (result.nextStage === 'reset_password') {
          this.show = 'reset_password';
          return;
        }

        if (matches.length === 1) {
          // TODO: Reverted #6402 to fix issue with TP clients logging in.
          // This can be cleaned up but for now keeping the original for the prod hotfix
          if (
            !matches[0].permissions.includes('askbetty_client') &&
            matches[0].permissions.includes('client')
          ) {
            // this.loginBrands = ['virtualgurus'];
          } else if (
            matches[0].permissions.includes('askbetty_client') &&
            matches[0].permissions.includes('client')
          ) {
            // this.loginBrands = ['askbetty', 'virtualgurus'];
          } else if (
            !matches[0].permissions.includes('askbetty_client') &&
            !matches[0].permissions.includes('askbetty_va') &&
            !matches[0].permissions.includes('askbetty_ppa')
          ) {
            this.nologin = true;
            this.show = 'email';
            return;
          }
        }

        this.show = 'password';
        this.$refs.loginPassword.$el.focus();
      } catch (error) {
        return;
      } finally {
        this.loading = false;
      }
    },
    async handleSetNewPassword() {
      try {
        await changePasswordByToken(
          this.email,
          this.resetPasswordToken,
          this.password
        );

        this.$root.$snackbar.message('Password successfully changed.');
        this.show = 'password';
        this.submit();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-enter {
  transform: translateX(15px);
  opacity: 0;
}
.slide-fade-leave-to {
  transform: translateX(-15px);
  opacity: 0;
}
</style>
