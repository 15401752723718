<template>
  <button v-if="hasClickListener" type="button" v-bind="$attrs">
    <i :class="icon"> </i>
  </button>
  <i v-else v-bind="$attrs" :class="icon"> </i>
</template>

<script>
export default {
  name: 'FaIcon',
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
  computed: {
    hasClickListener() {
      return Boolean(this.$attrs.onClick);
    },
  },
};
</script>
