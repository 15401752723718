<template>
  <div
    :class="`fixed top-0 bottom-0 border-r-2 border-slate-200
     ${uiStore.tabletOrSmaller ? 'z-[500]' : ''}`"
    @click="() => uiStore.navDrawerOpen && uiStore.setNavDrawerOpen(false)"
  >
    <nav
      v-if="navLinks.length > 0"
      :class="`min-h-screen max-h-screen overflow-y-auto flex flex-col ${
        uiStore.navDrawerOpen ? 'translate-x-0' : '-translate-x-full'
      } fixed lg:relative opacity-100 transition transform lg:translate-x-0 bg-white ${
        uiStore.tabletOrSmaller ? 'w-full' : 'w-32'
      }`"
    >
      <div v-if="uiStore.tabletOrSmaller" class="mx-auto p-4">
        <button
          class="absolute right-4 top-4"
          @click.prevent.prevent="uiStore.setNavDrawerOpen(false)"
        >
          <fa-icon icon="fa-light fa-xmark-large" class="text-2xl md:text-xl" />
        </button>
      </div>
      <router-link
        :to="userStore.homeLink"
        :aria-current="$route.path === userStore.homeLink"
      >
        <img
          :src="
            uiStore.tabletOrSmaller
              ? '/img/VG-OnDemand-logo-horizontal.svg'
              : '/img/VG-OnDemand-logo-vertical.svg'
          "
          class="mx-auto max-w-[20rem] p-8 lg:max-w-[9rem]"
          alt
        />
      </router-link>
      <div
        v-if="!uiStore.mobile"
        role="list"
        class="mb-8 flex flex-auto flex-col px-4 pt-4"
      >
        <div class="relative mx-auto mb-8 mt-2 max-w-[10rem] py-1">
          <v-menu v-model="profileMenu" offset="-16" min-width="180">
            <template #activator="{ props }">
              <fa-icon
                class="absolute -right-4 -top-2 h-4 w-4 cursor-pointer rounded-full border-2 border-solid border-slate-400 text-center font-bold text-slate-500"
                style="line-height: 1.5; font-size: 8px"
                icon="fa-light fa-chevron-down"
                v-bind="props"
              />
            </template>
            <div
              class="flex min-w-[10rem] flex-col gap-4 rounded-2xl bg-white p-4 shadow-lg"
            >
              <router-link
                v-if="
                  userStore.user.permissions.includes('askbetty_client') ||
                  userStore.user.permissions.includes('askbetty_ppa')
                "
                v-slot="{ navigate, href }"
                to="/profile"
              >
                <a
                  :href="href"
                  @click.prevent="() => handleMainLinkClick($event, navigate)"
                  >Profile
                </a>
              </router-link>
              <!-- <div class="">Notifications</div> -->

              <router-link
                v-if="
                  userStore.user?.permissions.includes('askbetty_client') &&
                  userStore.user?.is_primary_contact
                "
                v-slot="{ navigate, href }"
                to="/billing"
                custom
              >
                <a
                  :href="href"
                  @click.prevent="() => handleMainLinkClick($event, navigate)"
                >
                  Billing
                </a>
              </router-link>
              <a href="#" @click.prevent="logout"> Logout </a>
            </div>
          </v-menu>

          <router-link
            v-if="userStore.user.permissions.includes('askbetty_client')"
            v-slot="{ navigate, href }"
            to="/profile"
            custom
          >
            <a
              :href="href"
              class="flex flex-col py-2 text-left"
              @click.prevent="() => handleMainLinkClick($event, navigate)"
            >
              <v-avatar
                size="52"
                class="mx-auto box-content border-4 border-slate-300"
              >
                <img :src="avatarLink || '/img/img-quote_avatar.png'" />
              </v-avatar>
              <div v-if="full" class="grow self-center text-center">
                {{ displayName }}
              </div>
            </a>
          </router-link>
          <template v-else>
            <v-avatar
              size="52"
              class="mx-auto box-content border-4 border-slate-300"
            >
              <img :src="avatarLink || '/img/img-quote_avatar.png'" />
            </v-avatar>
            <div v-if="full" class="grow self-center text-center">
              {{ displayName }}
            </div>
          </template>
        </div>
        <div v-for="item in navLinks" :key="item.text" class="my-2 py-1">
          <router-link v-slot="{ navigate, href }" :to="item.route" custom>
            <a
              :href="href"
              class="text-vgmedturq flex flex-col uppercase"
              :class="`${
                item === currentLink ? 'font-semibold' : 'font-normal'
              }`"
              @click.prevent="() => handleMainLinkClick($event, navigate)"
            >
              <fa-icon
                class="h-12 w-12 self-center rounded-full px-1 pt-1 text-center text-2xl leading-10"
                :class="
                  item !== currentLink && !full
                    ? 'text-vgmedturq bg-white hover:opacity-50'
                    : !dialogsOpen
                    ? ' bg-vgmedturq text-white '
                    : ''
                "
                :icon="item.icon"
              />
              <span class="self-center text-center text-sm">
                {{ item.text }}
              </span>
            </a>
          </router-link>
        </div>
        <div
          v-if="
            userStore.user.permissions.includes('askbetty_client') ||
            (userStore.user.permissions.includes('askbetty_ppa') &&
              (route.name === 'ppaClientWorkspace' ||
                route.name === 'taskDetails'))
          "
        >
          <button
            class="text-vgmedturq mx-auto flex flex-col font-semibold uppercase"
            :class="`${
              'templates' === currentLink ? 'font-semibold' : 'font-normal'
            }`"
            @click="uiStore.showTemplatesDialog = true"
          >
            <fa-icon
              class="h-12 w-12 self-center rounded-full px-1 pt-1 text-center text-2xl leading-10"
              :class="
                uiStore.showTemplatesDialog
                  ? 'bg-vgmedturq text-white '
                  : 'text-vgmedturq bg-white hover:opacity-50'
              "
              icon="fa-light fa-wand-magic-sparkles"
            />
            <span class="self-center text-center text-sm"> Templates </span>
          </button>
        </div>
        <div
          v-if="userStore.user.permissions.includes('askbetty_client')"
          class="text-vgmedturq flex flex-auto flex-col-reverse gap-2 text-center font-semibold uppercase"
        >
          <button
            class="flex shrink flex-col items-center"
            @click="uiStore.showFeedbackDialog = true"
          >
            <fa-icon
              icon="fa-regular fa-bullhorn fa-xl"
              class="h-12 w-12 self-center rounded-full px-1 text-center text-2xl leading-10"
              :class="
                uiStore.showFeedbackDialog
                  ? 'bg-vgmedturq text-white '
                  : 'text-vgmedturq bg-white hover:opacity-50'
              "
            />
            <span class="text-base uppercase">Send Feedback!</span>
          </button>
        </div>
      </div>

      <!-- Mobile View -->
      <div v-else class="mt-6 flex grow flex-col">
        <div
          v-for="(link, idx) in mobileLinks"
          :key="idx"
          class="text-vgmedturq flex h-20 w-full items-center border-t-[1px] border-solid font-semibold"
        >
          <button
            class="grid h-full w-full grid-cols-5 place-items-start items-center gap-3 uppercase"
            @click="link.action ? link.action() : $router.push(link.route)"
          >
            <div class="col-span-1 flex w-full justify-end text-xl">
              <fa-icon
                :class="[
                  link.route !== $route.path
                    ? 'text-vgmedturq bg-white hover:opacity-50'
                    : !dialogsOpen
                    ? ' bg-vgmedturq rounded-full text-white  '
                    : '',
                  ` ${link.class ? link.class : 'p-2'}`,
                ]"
                :icon="link.icon"
              />
            </div>
            <span class="col-span-4">{{ link.text }}</span>
          </button>
        </div>
        <div class="flex grow flex-col place-content-end border-t-[1px]">
          <button
            v-if="userStore.user?.permissions.includes('askbetty_client')"
            class="text-vgmedturq flex h-16 w-full items-center justify-center gap-2 border-t border-solid text-sm"
            @click="uiStore.showFeedbackDialog = true"
          >
            <fa-icon
              icon="fa-regular fa-bullhorn fa-xl"
              class="rounded-full px-1 text-center text-2xl"
            />
            <span class="uppercase">Send Feedback!</span>
          </button>
          <button
            class="bg-vgmedturq flex h-16 w-full items-center justify-center gap-2 text-white"
            @click="logout()"
          >
            <fa-icon
              icon="fa-solid fa-arrow-right-from-bracket fa-xl"
              class="rounded-full px-1 text-center text-2xl"
            />
            <span class="text-base uppercase">Sign out</span>
          </button>
        </div>
      </div>
    </nav>
    <TaskTemplates
      v-if="
        userStore.user?.permissions.includes('askbetty_client') ||
        userStore.user?.permissions.includes('askbetty_ppa')
      "
    />
    <FeedbackDialog
      v-if="userStore.user?.permissions.includes('askbetty_client')"
    />
  </div>
</template>
<script>
import { nextTick } from 'vue';
import { sessionUserHasPermission } from '@/services/clientSessionService';
import { API_URL } from '@/config';
import { mapStores } from 'pinia';
import { useUiStore } from '@/stores/ui';
import { useUserStore } from '@/stores/user';
import FeedbackDialog from '../dialogs/FeedbackDialog.vue';
import TaskTemplates from '@/components/TaskTemplates.vue';
import { useRoute } from 'vue-router';

export default {
  name: 'NavDrawer',
  components: { FeedbackDialog, TaskTemplates },
  data() {
    const route = useRoute();
    return {
      route,
      initialLoad: true,
      collapseCurrent: false,
      profileMenu: false,
    };
  },
  computed: {
    ...mapStores(useUiStore, useUserStore),
    navLinks: function () {
      if (!this.userStore.user) {
        return [];
      }
      if (this.userStore.user?.permissions.includes('askbetty_ppa')) {
        return [
          {
            icon: 'fa-light fa-user-group',
            text: 'Clients',
            route: '/clients',
          },
          // {
          //   icon: 'fa-light fa-folder-arrow-up',
          //   text: 'Content Library',
          //   route: '/content-library',
          // },
          // {
          //   icon: 'fa-light fa-pen-field',
          //   text: 'Credentials',
          //   route: '/credentials',
          // },
        ];
      }
      if (this.userStore.user?.permissions.includes('askbetty_va')) {
        return [
          {
            icon: 'fa-light fa-ballot-check',
            text: 'Tasks',
            route: '/tasks',
          },
        ];
      }
      if (this.userStore.user?.permissions.includes('askbetty_client')) {
        return [
          {
            icon: 'fa-light fa-ballot-check',
            text: 'Tasks',
            route: '/tasks',
          },
          // {
          //   icon: 'fa-light fa-folder-arrow-up',
          //   text: 'Content Library',
          //   route: '/content-library',
          // },
          // {
          //   icon: 'fa-light fa-pen-field',
          //   text: 'Credentials',
          //   route: '/credentials',
          // },
        ];
      }
      return [];
    },
    mobileLinks() {
      if (this.userStore.user?.permissions.includes('askbetty_client')) {
        return [
          {
            icon: 'fa-light fa-ballot-check',
            text: 'Tasks',
            route: '/tasks',
          },
          {
            icon: 'fa-light fa-wand-magic-sparkles',
            text: 'Templates',
            route: '/templates',
            action: () => {
              nextTick(() => {
                this.uiStore.showTemplatesDialog = true;
              });
            },
          },
          {
            icon: 'fa-light fa-face-smile',
            text: 'Profile',
            route: '/profile',
          },
          {
            icon: 'fa-regular fa-receipt',
            text: 'Billing',
            route: '/billing',
            class: 'px-[10px] py-2',
          },
        ];
      }
      if (this.userStore.user?.permissions.includes('askbetty_ppa')) {
        return [
          {
            icon: 'fa-light fa-user-group',
            text: 'Clients',
            route: '/clients',
          },
        ];
      }
      if (this.userStore.user?.permissions.includes('askbetty_va')) {
        return [
          {
            icon: 'fa-light fa-ballot-check',
            text: 'Tasks',
            route: '/tasks',
          },
        ];
      }
      return [];
    },
    currentLink: function () {
      return this.navLinks.find(
        (link) =>
          link.route === this.route.path ||
          link.route === this.route.meta.navRelated
      );
    },
    full: function () {
      return this.uiStore.navDrawerExpanded || this.uiStore.navDrawerOpen;
    },
    displayName: function () {
      return this.userStore.user?.full_name ?? '';
    },
    avatarLink: function () {
      return this.userStore.user?.avatar_id
        ? `${API_URL}/file/avatar/${this.userStore.user?.avatar_id}`
        : '';
    },
    dialogsOpen() {
      return (
        this.uiStore.showFeedbackDialog || this.uiStore.showTemplatesDialog
      );
    },
  },
  watch: {
    route() {
      this.collapseCurrent = false;
    },
  },
  created() {
    nextTick(() => {
      this.initialLoad = false;
    });
  },
  methods: {
    sessionUserHasPermission,
    correctSubNavRelated(url) {
      if (!url || url.indexOf(':') < 0) {
        return url;
      }

      const matches = url.match(/:[^/]*/g);
      matches.forEach((match) => {
        url = url.replaceAll(match, this.route.params[match.slice(1)]);
      });
      return url;
    },
    async logout() {
      await this.userStore.endSession();
      location.href = '/';
    },
    handleMainLinkClick(event, navigate) {
      this.uiStore.setNavDrawerOpen(false);
      this.profileMenu = false;
      navigate(event);
    },
  },
};
</script>
