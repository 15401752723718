import { getJSON, postJSON, putJSON } from '@/services/httpService';

export const getBettys = (filters) =>
  getJSON(`/askbetty/v2/betty?${filters.join('&')}`);

export const getBetty = (id) => getJSON(`/askbetty/v2/betty/${id}`);

export const inviteBetty = (invite) =>
  postJSON(`/askbetty/v2/betty/invite`, invite);

export const updateInvite = (invite) =>
  putJSON(`/askbetty/v2/betty/invite/${invite.task_uuid}`, invite);
