import 'babel-polyfill';
import { createApp } from 'vue';
import App from '@/App.vue';
import FaIcon from '@/components/FaIcon.vue';
import router from '@/router';
import { createPinia } from 'pinia';
import { useUiStore } from '@/stores/ui';
import { useUserStore } from '@/stores/user';
import { useConfigStore } from '@/stores/config';
import vuetify from '@/plugins/vuetify';
import VueGtm from '@gtm-support/vue-gtm';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import { QuillEditor } from '@vueup/vue-quill';
import { GTM_ID, WS_URL } from '@/config';
import { saveSocket } from '@/services/clientSessionService';
import { build, checkBuildVersion } from '@/services/buildService';
import io from 'socket.io-client';
import '@/assets/main.scss';
import '@/assets/tailwind.css';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

const app = createApp(App);
const pinia = createPinia();
app.use(pinia);

app.use(vuetify);
app.use(VueDOMPurifyHTML, {
  default: {
    ALLOWED_TAGS: [
      'a',
      'b',
      'br',
      'strong',
      'i',
      'em',
      'mark',
      'small',
      'del',
      'ins',
      'sub',
      'sup',
      'p',
      'span',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'strong',
      'em',
      'u',
      's',
      'ol',
      'ul',
      'li',
      'table',
      'thead',
      'tr',
      'th',
      'td',
    ],
    ALLOWED_ATTR: [
      'title',
      'class',
      'href',
      'style',
      'target',
      'rel',
      'cellpadding',
      'cellspacing',
      'role',
      'align',
    ],
  },
});
app.use(router);

if (GTM_ID) {
  app.use(VueGtm, {
    id: GTM_ID,
    vueRouter: router,
  });
}
app.component('FaIcon', FaIcon);
const globalOptions = {
  modules: {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
  },
  theme: 'snow',
};
QuillEditor.props.globalOptions.default = () => globalOptions;
app.component('QuillEditor', QuillEditor);
app.mount('#app');

const socket = io(WS_URL, {
  query: {
    source: 'askbetty',
    current_page: router.currentRoute.value.fullPath,
    build,
  },
  withCredentials: true,
});

saveSocket(socket);

const configStore = useConfigStore();
const userStore = useUserStore();
const uiStore = useUiStore();

socket.on('askbetty.reload_config', () => {
  configStore.loadConfig();
});
socket.on('askbetty.reload_session', () => {
  userStore.loadSession();
});
socket.on('askbetty.reload_user', () => {
  userStore.initUser({ forceReload: true });
});
socket.on('askbetty.reload_window', () => {
  location.reload(true);
});
socket.io.on('reconnect', async () => {
  configStore.loadConfig();
  userStore.loadSession();
  socket.emit('current_page', router.currentRoute.value.fullPath);
  if (await checkBuildVersion()) {
    uiStore.showNewVersionAvailable = true;
  }
});

window.addEventListener('error', function (event) {
  window.apm?.captureError(event);
});

const mediaMatcherMobile = window.matchMedia('(max-width: 767px)');
const mediaMatcherTablet = window.matchMedia(
  '(max-width: 1023px) and (min-width: 768px)'
);

const handleMobileMediaChange = function (event) {
  uiStore.setMobile(event.matches);
};

const handleTabletMediaChange = function (event) {
  uiStore.setTablet(event.matches);
};

mediaMatcherMobile.addEventListener('change', handleMobileMediaChange);
mediaMatcherTablet.addEventListener('change', handleTabletMediaChange);

handleMobileMediaChange(mediaMatcherMobile);
handleTabletMediaChange(mediaMatcherTablet);
