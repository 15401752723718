<template>
  <v-dialog
    v-model="showFeedbackDialog"
    :width="uiStore.mobile ? '100%' : '70%'"
    @click:outside="handleClose()"
  >
    <div class="flex-inline rounded-xl bg-white p-8">
      <div class="pb-8">
        <h1 class="text-2xl font-bold">
          🚀 Hey {{ userStore.user.known_as || userStore.user.full_name }}!
          Let’s Elevate Our App Together!
        </h1>
      </div>
      <div>
        <h2 class="mb-2 font-semibold">
          Your insights are the secret ingredient to our app’s success! Help us
          make it even better!
        </h2>
        <v-form ref="form" v-model="valid">
          <v-textarea
            v-model.trim="feedback"
            :color="vgMedTurq"
            class="text-base font-semibold"
            rounded="lg"
            rows="15"
            row-height="15"
            no-resize
            variant="outlined"
            :rules="requiredRules"
            aria-label="Feedback"
            placeholder="Ideas big or small, clever insights or just a hi-five, share what’s on your mind!"
          />
        </v-form>
      </div>
      <div
        class="flex-inline flex justify-center gap-3 text-sm tracking-wider md:justify-end"
      >
        <button
          class="h-10 self-start rounded-full px-6 uppercase tracking-wider text-gray-600"
          :loading="saving"
          @click="handleClose()"
        >
          Cancel
        </button>
        <button
          class="bg-vgmedturq h-10 self-start rounded-full px-8 font-semibold uppercase tracking-wider text-white md:px-16"
          :loading="saving"
          @click="submit()"
        >
          Shoot it over!
        </button>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import tailwind from 'tailwind.config';
import { mapStores, storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useUiStore } from '@/stores/ui';

import { saveFeedback } from '@/services/feedbackService';
export default {
  name: 'FeedbackDialog',
  data() {
    const uiStore = useUiStore();
    const { showFeedbackDialog } = storeToRefs(uiStore);

    return {
      uiStore,
      showFeedbackDialog,
      error: null,
      feedback: null,
      requiredRules: [(v) => !!v || 'Required'],
      saving: false,
      valid: false,
      vgMedTurq: tailwind.theme.extend.colors.vgmedturq[500],
    };
  },
  computed: {
    ...mapStores(useUserStore),
  },
  watch: {
    error(newValue) {
      if (newValue?.length > 0) {
        this.$root.$snackbar.error(newValue);
      }
    },
  },

  methods: {
    async submit() {
      if (!(await this.$refs.form.validate()).valid) {
        this.error = 'Please enter feedback before trying to submit';

        return;
      }

      try {
        this.saving = true;

        const payload = {
          type: 'askbetty_client_feedback',
          display_name:
            this.userStore.user.known_as || this.userStore.user.full_name,
          message: this.feedback,
          actor_uuid: this.userStore.user.uuid,
          subject_uuid: null,
          extra: null,
        };

        await saveFeedback(payload);
        this.$root.$snackbar.message('Thank you for your feedback!');
        this.feedback = '';
        this.handleClose();
      } catch (error) {
        this.error =
          'Unable to save feedback. Please refresh the page and try again';
      }
    },
    handleClose() {
      this.error = '';
      this.saving = false;
      this.feedback = '';
      this.showFeedbackDialog = false;
    },
  },
};
</script>
