<template>
  <NotificationBadge
    :dot="!!task.unread"
    class="ring-vgmedturq my-4 grid w-full flex-none grid-cols-12 gap-4 rounded-lg border border-solid border-slate-200 bg-white p-8 text-left shadow ring-offset-1 hover:border-white hover:ring-1"
    role="row"
  >
    <div
      class="col-span-12 flex flex-row items-center gap-2 place-self-start md:col-span-6"
      role="cell"
    >
      <div
        class="w-24 rounded-full text-center text-sm font-semibold uppercase text-white"
        :class="computedTaskDetails.taskStatusBgColor"
      >
        {{ computedTaskDetails.taskStatus }}
      </div>
      {{ task.client_name }}
    </div>
    <div class="col-span-12 md:col-span-4">
      <div>
        <v-avatar size="42">
          <img
            :src="
              task.ppa_avatar_id
                ? `${API_URL}/file/avatar/${task.ppa_avatar_id}`
                : '/img/img-quote_avatar.png'
            "
            alt="avatar"
          />
        </v-avatar>
        <span class="ml-1 whitespace-nowrap">{{ task.ppa_known_as }}</span>
      </div>
    </div>
    <div
      v-if="task.relationship_type === 'assigned'"
      class="col-span-12 row-span-2 md:col-span-2"
    >
      <div
        class="flex flex-row items-start gap-2 text-sm uppercase text-slate-500 md:justify-end"
      >
        <fa-icon icon="fa-regular fa-timer" class="text-base" />
        Time Logged
      </div>
      <div
        v-if="task.status !== 'active'"
        class="min-w-[4rem] whitespace-nowrap md:text-right"
      >
        {{
          task.time_logged_minutes
            ? formatDurationShort(
                task.time_logged_minutes + task.ongoing_time_entry
              )
            : '&nbsp;'
        }}
      </div>
      <button
        v-else-if="task.ongoing_time_entry !== null"
        class="bg-vgmedturq mt-4 rounded-full px-4 py-1 text-sm font-semibold uppercase text-white md:w-full md:px-1"
        @click="handleStopTimerClick(task)"
      >
        <fa-icon icon="fa-regular fa-stop" class="mr-1" />
        Stop<span class="whitespace-nowrap normal-case"
          >{{
            task.time_logged_minutes
              ? ': ' +
                formatDurationShort(
                  task.time_logged_minutes + task.ongoing_time_entry
                )
              : ''
          }}
        </span>
      </button>
      <button
        v-else
        class="text-vgmedturq border-vgmedturq mt-4 rounded-full border border-solid px-4 py-1 text-sm font-semibold uppercase md:w-full md:px-1"
        @click="handleStartTimerClick(task)"
      >
        <fa-icon icon="fa-regular fa-play" class="mr-1" />
        Start<span class="whitespace-nowrap normal-case"
          >{{
            task.time_logged_minutes
              ? ': ' + formatDurationShort(task.time_logged_minutes)
              : ''
          }}
        </span>
      </button>
    </div>
    <div class="col-span-full md:col-span-10">
      <h1 class="mb-2 text-sm font-semibold uppercase text-slate-600">
        Task Title
      </h1>
      <router-link
        :to="`/tasks/${task.uuid}`"
        class="text-vgnavy text-base font-semibold"
        >{{ task.title }}</router-link
      >
    </div>
    <div class="col-span-full mt-2 flex gap-4 text-slate-600 md:col-span-10">
      <fa-icon icon="fa-regular fa-list-tree" class="text-lg" />
      {{ computedTaskDetails.completedSubtasks?.length ?? 0 }} /
      {{ task.subtasks?.length ?? 0 }}
      <fa-icon icon="fa-regular fa-link" class="text-lg" />
      {{ task.attachmentCount ?? 0 }}
    </div>
    <div class="col-span-full mt-2 md:col-span-2">
      <NotificationBadge :count="task.unread_count">
        <button
          class="text-vgmedturq border-vgmedturq max-h-10 w-full rounded-md border border-solid p-2 text-sm font-semibold uppercase"
          @click.stop="$router.push(`/tasks/${task.uuid}`)"
        >
          Open Task
        </button>
      </NotificationBadge>
    </div>
  </NotificationBadge>
</template>

<script setup>
import { computed, inject } from 'vue';
import { API_URL } from '@/config';
import { formatDurationShort } from '@/services/formattingService';
import { computeTaskDetails } from '@/services/taskService';
import { startTimer, stopTimer } from '@/services/timeEntryService';
import NotificationBadge from '@/components/NotificationBadge.vue';

const snackbar = inject('snackbar');

const props = defineProps({
  task: {
    type: Object,
    default() {
      return {};
    },
  },
});

const computedTaskDetails = computed(() => {
  return computeTaskDetails(props.task, false);
});

const handleStartTimerClick = async (task) => {
  try {
    await startTimer(task);
  } catch (error) {
    snackbar.error(error?.data?.message || 'Unable to start timer');
  }
};
const handleStopTimerClick = async (task) => {
  try {
    await stopTimer(task);
    snackbar.message('Timer has been stopped and time entry recorded');
  } catch (error) {
    snackbar.error(error?.data?.message || 'Unable to stop timer');
  }
};
</script>
