import {
  getJSON,
  postJSON,
  putJSON,
  deleteAction,
} from '@/services/httpService';

export const getFeedback = (filters) => {
  return getJSON(`/askbetty/v2/feedback/?${filters.join('&')}`);
};

export const saveFeedback = (feedback) => {
  return postJSON(`/askbetty/v2/feedback/`, feedback);
};

export const updateFeedback = (feedback) => {
  return putJSON(`/askbetty/v2/feedback/${feedback.uuid}`, feedback);
};

export const deleteFeedback = (feedbackUuid) => {
  return deleteAction(`/askbetty/v2/feedback/${feedbackUuid}`);
};
