<template>
  <v-snackbar v-model="show" location="top" :color="color" :timeout="timeout">
    <span class="text">{{ text }}</span>
    <template v-if="timeout === -1" #actions>
      <v-btn variant="text" @click="show = false"> Close </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'Snackbar',
  data: () => ({
    show: false,
    text: '',
    color: 'info',
    timeout: undefined,
  }),
  methods: {
    message(text, options = {}) {
      this.show = true;
      this.text = text;
      this.color = '#abcc37';
      this.timeout = options.persist ? -1 : options.timeout;
    },
    error(text, options = {}) {
      this.show = true;
      this.text = text;
      this.color = 'error';
      this.timeout = options.persist ? -1 : options.timeout;
    },
  },
};
</script>
