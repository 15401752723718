import currentBuild from '@/build.json'; // src/build.json
let lastChecked = null;
const loadedTime = Date.now();
const RECHECK_DELAY_IN_MS = 60 * 1000;
const FIRST_CHECK_DELAY_IN_MS = 5 * 60 * 1000;

export const build = currentBuild.build;
window.vg_build = currentBuild;

export const checkBuildVersion = async (autoReload) => {
  if (!currentBuild.build) {
    return;
  }
  if (
    autoReload &&
    lastChecked &&
    lastChecked + RECHECK_DELAY_IN_MS > Date.now()
  ) {
    return;
  }
  if (autoReload && loadedTime + FIRST_CHECK_DELAY_IN_MS > Date.now()) {
    return;
  }
  try {
    const result = await (await fetch('/build.json')).json(); // public/build.json
    if (result.build && result.build !== currentBuild.build) {
      if (autoReload) {
        window.location.reload(true);
      }
      return true;
    }
    lastChecked = Date.now();
  } catch (error) {
    //
  }
  return false;
};
