<template>
  <div class="flex h-full shrink flex-col rounded-tl-3xl bg-white">
    <button
      v-if="mode === 'full'"
      class="absolute right-2 top-2"
      @click.prevent.stop="$emit('close')"
    >
      <fa-icon icon="fa-light fa-xmark-large" />
    </button>
    <div
      class="flex items-center justify-between border-b-2 border-slate-100 p-4"
    >
      <div class="flex items-center">
        <v-avatar size="62" class="border-vgteal border-2 border-solid">
          <img
            :src="
              userStore.client?.ppa?.avatar_id
                ? `${API_URL}/file/avatar/${userStore.client.ppa.avatar_id}`
                : '/img/img-quote_avatar.png'
            "
          />
        </v-avatar>
        <div class="pl-2">
          <div class="text-base font-semibold">
            {{
              userStore.client?.ppa?.known_as ||
              userStore.client?.ppa?.display_name
            }}
          </div>
          <div
            class="text-xs uppercase leading-tight tracking-wider text-slate-500 xl:whitespace-nowrap"
          >
            Productivity Assistant
          </div>
        </div>
      </div>

      <div
        v-if="ppaCalendlyLink"
        class="border-vgmedturq text-vgmedturq ml-3 grid max-w-[8rem] grid-flow-col items-center gap-2 text-xs font-semibold uppercase lg:max-h-[2rem] lg:border-l-2 lg:pl-4"
      >
        <fa-icon
          icon="fa-regular fa-calendar-plus"
          class="mb-1 ml-2 text-2xl"
        />
        <a
          :href="ppaCalendlyLink"
          target="_blank"
          class="whitespace-break-spaces"
          >Schedule Meeting</a
        >
      </div>
    </div>
    <!-- <div class="pb-4">
      <v-text-field
        placeholder="Search chat..."
        :color="vgMedTurq"
        variant="outlined"
        hide-details
        density="compact"
        class="text-sm"
        rounded="lg"
      >
        <template #append-inner>
          <fa-icon
            icon="fa-regular fa-magnifying-glass"
            class="-mt-1 pr-2 text-lg text-slate-800"
          />
        </template>
      </v-text-field>
    </div> -->
    <div ref="chatmessages" class="flex-1 overflow-y-auto p-4">
      <div
        v-if="clientChatStore.showOlderButton"
        class="mb-2 flex-none text-center"
      >
        <button
          class="bg-vgmedturq rounded-lg px-6 py-2 text-sm font-semibold uppercase text-white"
          @click="clientChatStore.loadOlderMessages"
        >
          Show older messages
        </button>
      </div>
      <ChatMessage
        v-for="message in clientChatStore.messages"
        :key="message.uuid"
        :message="message"
        :user-uuid="userStore.user.uuid"
        class="[overflow-anchor:none]"
      />
      <div class="h-[1px] [overflow-anchor:auto]"></div>
    </div>

    <hr class="py-2" />
    <div class="flex flex-none gap-2 pb-2">
      <v-textarea
        ref="chatInput"
        v-model="clientChatStore.newMessage"
        rows="1"
        row-height="10"
        auto-grow
        :color="vgMedTurq"
        placeholder="Message"
        variant="outlined"
        class="limit-height text-sm"
        rounded="xl"
        density="compact"
        hide-details
        :disabled="sendingMessage"
        @keypress.enter.exact="handleEnter"
        @keypress.ctrl.enter.prevent="addNewLine"
        @keypress.shift.enter.prevent="addNewLine"
      />
      <button
        class="bg-vgmedturq h-10 w-10 rounded-full uppercase text-white"
        :disabled="sendingMessage"
        @click="handleSendMessage"
      >
        <fa-icon icon="fa-regular fa-paper-plane" class="p-1 text-[1.1rem]" />
      </button>
    </div>
  </div>
</template>
<script>
import { nextTick } from 'vue';
import { API_URL, MARK_READ_TIMEOUT_LENGTH } from '@/config';
import tailwind from 'tailwind.config';
import { markRead } from '@/services/chatService';
import { mapStores } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useClientChatStore } from '@/stores/clientChat';
import { useUiStore } from '@/stores/ui';
import ChatMessage from '@/components/ChatMessage.vue';

export default {
  name: 'ClientChat',
  components: {
    ChatMessage,
  },
  props: {
    mode: {
      type: String,
      default: '',
    },
    client: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  emits: ['close'],
  data() {
    return {
      API_URL,
      markReadTimeout: null,
      intervalId: null,
      flashing: false,
      originalTitle: '',
      sendingMessage: false,
      vgMedTurq: tailwind.theme.extend.colors.vgmedturq[500],
    };
  },
  computed: {
    ...mapStores(useUserStore, useClientChatStore, useUiStore),
    ppaCalendlyLink() {
      if (this.userStore.client?.ppa?.calendly_link) {
        return `${this.userStore.client.ppa.calendly_link}?name=${this.userStore.user.known_as}&email=${this.userStore.user.email}`;
      }
      return null;
    },
  },
  watch: {
    'client.unread_count'(newVal) {
      if (newVal > 0) {
        this.flashing = true;
      }
    },
    'clientChatStore.messages': {
      deep: true,
      handler() {
        nextTick(() => {
          if (this.$refs.chatmessages) {
            this.$refs.chatmessages.scrollTop = this.$refs.chatmessages.scrollHeight;
          }
        });
      },
    },
  },
  created() {
    this.clientChatStore.initMessages().catch(() => {
      this.$root.$snackbar.error('An error occurred while loading messages');
    });

    if (!this.originalTitle) {
      this.originalTitle = document.title;
    }

    window.addEventListener('blur', this.handleWindowBlur);
    window.addEventListener('focus', this.resetState);
    this.resetState();
  },
  beforeUnmount() {
    window.removeEventListener('blur', this.handleWindowBlur);
    window.removeEventListener('focus', this.resetState);
  },
  methods: {
    handleEnter(event) {
      if (!this.uiStore.tabletOrSmaller) {
        this.handleSendMessage();
        event.preventDefault();
      }
    },
    async handleSendMessage() {
      if (!/\S/g.test(this.clientChatStore.newMessage)) {
        this.clientChatStore.newMessage = '';

        return;
      }

      this.sendingMessage = true;

      try {
        await this.clientChatStore.sendMessage();
      } catch (error) {
        return this.$root.$snackbar.error(
          error?.data?.message ??
            `An error occurred when sending your message. Please try again later.`
        );
      } finally {
        this.sendingMessage = false;
        nextTick(() => {
          const inputElement = this.$refs.chatInput?.$el?.querySelector(
            'textarea'
          );

          if (inputElement) {
            inputElement.focus();
          }
        });
      }
    },
    async handleMarkRead(last_read_uuid, unread) {
      if (!this.clientChatStore.messages?.length) {
        return;
      }
      if (this.markReadTimeout) {
        clearTimeout(this.markReadTimeout);
      }

      try {
        this.clientChatStore.last_read_uuid =
          typeof last_read_uuid === 'undefined'
            ? this.clientChatStore.messages[
                this.clientChatStore.messages.length - 1
              ].uuid
            : last_read_uuid;

        await markRead(this.userStore.user.uuid, {
          message_uuid: this.clientChatStore.last_read_uuid,
          unread,
        });
      } catch (error) {
        return this.$root.$snackbar.error(
          error?.data?.message ??
            `An error occurred when marking messages as read. Please try again later.`
        );
      }
    },
    addNewLine(event) {
      if (
        typeof event.srcElement.selectionEnd !== 'undefined' &&
        event.srcElement.selectionEnd === event.srcElement.selectionStart
      ) {
        const cursor = event.srcElement.selectionStart;
        this.clientChatStore.newMessage = `${this.clientChatStore.newMessage.slice(
          0,
          cursor
        )}\n${this.clientChatStore.newMessage.slice(cursor)}`;
        nextTick(() => {
          if (event.srcElement.setSelectionRange) {
            event.srcElement.focus();
            event.srcElement.setSelectionRange(cursor + 1, cursor + 1);
          } else if (event.srcElement.createTextRange) {
            let range = event.srcElement.createTextRange();
            range.collapse(true);
            range.moveEnd('character', cursor + 1);
            range.moveStart('character', cursor + 1);
            range.select();
          }
        });
      } else {
        this.clientChatStore.newMessage =
          this.clientChatStore.newMessage + '\n';
      }
    },
    handleWindowBlur() {
      this.intervalId = setInterval(this.flashTitle, 1500);
    },
    flashTitle() {
      if (this.flashing) {
        document.title =
          document.title === this.originalTitle
            ? 'New Message!'
            : this.originalTitle;
      }
    },
    resetState() {
      document.title = this.originalTitle;
      this.flashing = false;

      clearInterval(this.intervalId);

      if (this.markReadTimeout) {
        clearTimeout(this.markReadTimeout);
      }

      this.markReadTimeout = setTimeout(
        () => this.handleMarkRead(),
        MARK_READ_TIMEOUT_LENGTH
      );
    },
  },
};
</script>
