import { getJSON, postJSON } from '@/services/httpService';

export const getClientMessages = (uuid, filters) =>
  getJSON(
    `/askbetty/v2/chat/client/${uuid}/messages?${filters?.join('&') ?? ''}`
  );

export const getTaskMessages = (uuid, filters) =>
  getJSON(
    `/askbetty/v2/chat/task/${uuid}/messages?${filters?.join('&') ?? ''}`
  );

export const getLastReadUuid = (uuid) =>
  getJSON(`/askbetty/v2/chat/${uuid}/last_read`);

export const getClientChatParticipants = (uuid) =>
  getJSON(`/askbetty/v2/chat/client/${uuid}/participants`);

export const getTaskChatParticipants = (uuid) =>
  getJSON(`/askbetty/v2/chat/task/${uuid}/participants`);

export const markRead = (uuid, body) =>
  postJSON(`/askbetty/v2/chat/${uuid}/read`, body);

export const saveClientMessage = (uuid, body) =>
  postJSON(`/askbetty/v2/chat/client/${uuid}/message`, body);

export const saveTaskMessage = (uuid, body) =>
  postJSON(`/askbetty/v2/chat/task/${uuid}/message`, body);
