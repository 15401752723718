<template>
  <div
    v-if="uiStore.showNewVersionAvailable"
    class="border-vgorange fixed bottom-4 left-4 flex flex-col gap-2 rounded-md border border-solid bg-white p-4 shadow-lg"
  >
    <p>A new version is available.</p>
    <button
      class="bg-vgmedturq h-10 self-end rounded-lg px-6 text-white"
      @click="handleReload"
    >
      Reload
    </button>
  </div>
</template>
<script setup>
import { useUiStore } from '@/stores/ui';

const uiStore = useUiStore();

function handleReload() {
  window.location.reload(true);
}
</script>
