import {
  getJSON,
  putJSON,
  postJSON,
  deleteAction,
} from '@/services/httpService';

export const getTimeEntriesForTask = (task_uuid) => {
  return getJSON(`/askbetty/v2/time/${task_uuid}`);
};

export const saveTimeEntry = (task, timeEntry) =>
  timeEntry.uuid
    ? putJSON(`/askbetty/v2/time/${task.uuid}/${timeEntry.uuid}`, timeEntry)
    : postJSON(`/askbetty/v2/time/${task.uuid}`, timeEntry);

export const deleteTimeEntry = (task, entry) =>
  deleteAction(`/askbetty/v2/time/${task.uuid}/${entry.uuid}`);

export const startTimer = (task) =>
  postJSON(`/askbetty/v2/time/${task.uuid}/start`);

export const stopTimer = (task) =>
  postJSON(`/askbetty/v2/time/${task.uuid}/stop`);
