<template>
  <div v-if="ppaClientStore.selectedClient">
    <div class="flex h-[4rem] flex-row">
      <div class="grow">
        <router-link
          v-if="!uiStore.tabletOrSmaller"
          :to="`/clients/${ppaClientStore.selectedClient.uuid}`"
          class="text-vgnavy flex px-4 pt-4 uppercase"
        >
          <fa-icon
            icon="fa-regular fa-chevron-left"
            class="bg-vgnavy h-6 w-6 self-center rounded-full px-1 text-center text-base text-white"
          />
          <span class="ml-2 self-center text-base">Back to clients</span>
        </router-link>
        <span class="ml-12 leading-3">
          Workspace:
          {{
            ppaClientStore.selectedClient.company ||
            ppaClientStore.selectedClient.full_name
          }}
        </span>
      </div>
      <div class="p-4">
        <span class="text-sm uppercase"> Account Balance </span>
        <span class="text-vgmedturq ml-2 font-semibold">
          {{
            formatDurationShort(ppaClientStore.selectedClient.minutes_balance)
          }}
        </span>
      </div>
    </div>
    <div class="flex h-full">
      <div
        class="h-[calc(100vh-8rem)] flex-1 overflow-y-auto bg-[#FBFBFB] p-4 lg:h-[calc(100vh-4rem)]"
      >
        <div
          ref="tablist"
          class="ml-4 flex gap-2 overflow-x-auto py-4 pr-4 text-sm font-semibold"
        >
          <button
            class="shrink-0 grow rounded-md border border-solid border-slate-300 px-6 py-2 uppercase"
            :class="
              tab === 'new'
                ? 'bg-vgmedturq text-white'
                : 'bg-white hover:bg-slate-200 text-slate-500'
            "
            data-tab="new"
            @click="tab = 'new'"
          >
            My Draft Tasks ({{ ppaClientStore.newTasks.length }})
          </button>
          <NotificationBadge
            :count="ppaClientStore.activeTasksNotificationCount"
            class="shrink-0 grow"
          >
            <button
              class="w-full rounded-md border border-solid border-slate-300 px-6 py-2 uppercase"
              :class="
                tab === 'active'
                  ? 'bg-vgmedturq text-white'
                  : 'bg-white hover:bg-slate-200 text-slate-500'
              "
              data-tab="active"
              @click="tab = 'active'"
            >
              Handed Off ({{ ppaClientStore.activeTasks.length }})
            </button>
          </NotificationBadge>
          <NotificationBadge
            :count="ppaClientStore.reviewTasksNotificationCount"
            class="shrink-0 grow"
          >
            <button
              class="w-full rounded-md border border-solid border-slate-300 px-6 py-2 uppercase"
              :class="
                tab === 'review'
                  ? 'bg-vgmedturq text-white'
                  : 'bg-white hover:bg-slate-200 text-slate-500'
              "
              data-tab="review"
              @click="tab = 'review'"
            >
              Review Ready ({{ ppaClientStore.reviewTasks.length }})
            </button>
          </NotificationBadge>
          <NotificationBadge
            :count="ppaClientStore.completeTasksNotificationCount"
            class="shrink-0 grow"
          >
            <button
              class="w-full rounded-md border border-solid border-slate-300 px-6 py-2 uppercase"
              :class="
                tab === 'complete'
                  ? 'bg-vgmedturq text-white'
                  : 'bg-white hover:bg-slate-200 text-slate-500'
              "
              data-tab="complete"
              @click="tab = 'complete'"
            >
              Completed ({{ ppaClientStore.completeTasks.length }})
            </button>
          </NotificationBadge>
        </div>
        <div class="mx-4 rounded-lg bg-white">
          <div
            v-if="tab === 'new'"
            class="bg-vgmedturq-100 flex gap-4 rounded-t-lg px-4 pt-4"
          >
            <v-text-field
              v-model.trim="newTaskTitle"
              variant="outlined"
              density="compact"
              :color="vgMedTurq"
              rounded="lg"
              bg-color="#fff"
              placeholder="Give your task a title..."
              maxlength="200"
              counter="200"
              @keyup.enter="handleCreateTask"
            />
            <button
              class="bg-vgmedturq h-10 self-start rounded-lg px-6 text-sm uppercase text-white"
              :loading="ppaClientStore.selectedClient.newTaskLoading"
              @click="handleCreateTask"
            >
              Add
            </button>
          </div>
          <div class="p-4">
            <div
              v-if="tab === 'new' && ppaClientStore.selectedTasks.length === 0"
              class="p-8 text-center text-slate-500"
            >
              Hey! Add a task and let’s start crushing your goals.
            </div>
            <div
              v-else-if="ppaClientStore.selectedTasks.length === 0"
              class="p-8 text-center text-slate-500"
            >
              {{ noTasksMessage }}
            </div>
            <div
              v-else
              class="mt-8 grid w-full flex-none grid-cols-12 px-4"
              role="row"
            >
              <div class="col-span-12 sm:col-span-7" role="columnheader">
                <h3 class="mr-4 inline font-semibold">
                  Tasks ({{ ppaClientStore.selectedTasks.length }})
                </h3>
              </div>
            </div>
            <ClientTaskCard
              v-for="task in ppaClientStore.selectedTasks"
              :key="task.uuid"
              :task="task"
              hand-off-to="me"
              :client="ppaClientStore.selectedClient"
            />
          </div>
        </div>
      </div>
      <ChatWrapper type="ppaClient" :client="ppaClientStore.selectedClient" />
    </div>
  </div>
</template>
<script>
import { nextTick } from 'vue';
import { mapStores } from 'pinia';
import { usePpaClientStore } from '@/stores/ppaClient';
import { useUiStore } from '@/stores/ui';
import { formatDurationShort } from '@/services/formattingService';
import ChatWrapper from '@/components/ChatWrapper.vue';
import ClientTaskCard from '@/components/ClientTaskCard.vue';
import NotificationBadge from '@/components/NotificationBadge.vue';
import tailwind from 'tailwind.config';
import { noTasksMessage } from '@/config';
import { useRoute } from 'vue-router';

export default {
  name: 'PpaTasks',
  components: {
    ChatWrapper,
    ClientTaskCard,
    NotificationBadge,
  },
  data() {
    const route = useRoute();

    return {
      route,
      newTaskSaving: false,
      vgMedTurq: tailwind.theme.extend.colors.vgmedturq[500],
    };
  },
  computed: {
    ...mapStores(usePpaClientStore, useUiStore),
    tab: {
      get() {
        return this.ppaClientStore.selectedClient?.tab;
      },
      set(value) {
        this.ppaClientStore.selectedClient.tab = value;
      },
    },
    newTaskTitle: {
      get() {
        return this.ppaClientStore.selectedClient?.newTaskTitle;
      },
      set(value) {
        this.ppaClientStore.selectedClient.newTaskTitle = value;
      },
    },
    noTasksMessage() {
      return noTasksMessage[this.tab] ?? noTasksMessage.default;
    },
  },
  watch: {
    'route.params.user_uuid': {
      handler: function (value) {
        this.ppaClientStore.selectClient(value);
        this.ppaClientStore.loadTasks(value);
      },
      immediate: true,
    },
    tab: {
      immediate: true,
      handler() {
        nextTick(() => {
          this.$refs.tablist
            ?.querySelector('[data-tab="' + this.tab + '"]')
            .scrollIntoView({ block: 'nearest', inline: 'center' });
        });
      },
    },
  },
  methods: {
    formatDurationShort,
    async handleCreateTask() {
      if (!this.newTaskTitle || this.newTaskSaving) {
        return;
      }
      try {
        this.newTaskSaving = true;
        await this.ppaClientStore.createTask();
      } catch (e) {
        this.$root.$snackbar.error(
          'An error occurred while creating a new task'
        );
      }
      this.newTaskSaving = false;
    },
  },
};
</script>
