<template>
  <div class="bg-[#FBFBFB] p-4">
    <button
      class="text-vgmedturq mb-8 flex items-center gap-2 md:ml-4"
      @click="close"
    >
      <fa-icon
        icon="fa-regular fa-arrow-left"
        class="bg-vgmedturq h-8 w-8 rounded-full pt-1 text-center text-lg text-white"
      />
      Back to Main Billing
    </button>
    <div class="rounded-xl bg-white p-2 md:m-4 md:p-8">
      <h1 class="flex-1 text-xl font-semibold">
        <fa-icon icon="fa-regular fa-history" /> Billing History
      </h1>
      <h2 class="mb-2 mt-8 text-lg font-semibold">Transactions:</h2>
      <hr />
      <div v-if="loading" class="text-center">Loading...</div>
      <div v-else-if="error">{{ error }}</div>
      <div v-else>
        <div
          v-for="transaction in transactions"
          :key="transaction.uuid"
          class="my-4 grid w-full grid-cols-6 gap-2 md:grid-cols-12 md:gap-4"
        >
          <template v-if="transaction.minutes < 0">
            <div class="col-span-6 flex flex-col">
              <div
                class="bg-vgnavy inline self-start rounded-full px-2 text-center text-sm font-semibold uppercase text-white"
              >
                Complete
              </div>
              {{ transaction.title || transaction.notes }}
            </div>
            <div v-if="!uiStore.mobile" class="col-span-2">&nbsp;</div>
            <div
              class="col-span-6 flex w-full flex-col items-center justify-between md:col-span-2 md:flex-row"
            >
              <div
                class="col-span-2 mt-2 flex w-full flex-col justify-end md:items-center md:justify-start"
              >
                <div
                  class="text-vgmedturq mt-1 flex min-w-[6rem] justify-end text-xl font-semibold md:justify-start"
                >
                  - {{ formatDurationShort(-transaction.minutes) }}
                </div>
              </div>
              <div
                v-if="transaction.task_uuid"
                class="col-span-4 mt-2 flex w-full flex-col md:col-span-2 md:mt-0 md:items-center"
              >
                <router-link
                  :to="`/tasks/${transaction.task_uuid}`"
                  class="bg-vgmedturq rounded p-2 text-center text-white"
                >
                  Details
                </router-link>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="col-span-4 flex flex-col md:col-span-8">
              <div>{{ transaction.notes }}</div>
              <div class="text-xs text-slate-700">
                {{ formatShortDateTime(transaction.created_date) }}
              </div>
            </div>
            <div
              class="text-vgmedturq col-span-2 w-full min-w-[6rem] flex-col text-right text-xl font-semibold md:items-center md:text-start"
            >
              + {{ formatDurationShort(transaction.minutes) }}
            </div>
            <div class="col-span-4 md:col-span-2">&nbsp;</div>
          </template>
          <hr class="col-span-full mt-4" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useUiStore } from '@/stores/ui';
import { canGoBack } from '@/services/httpService';
import { getTransactions } from '@/services/transactionService';
import {
  formatShortDateTime,
  formatDurationShort,
} from '@/services/formattingService';
import { useRouter } from 'vue-router';

export default {
  name: 'ClientBillingHistory',
  data() {
    const router = useRouter();
    return {
      router,
      loading: false,
      error: null,
      transactions: [],
    };
  },
  computed: {
    ...mapStores(useUserStore, useUiStore),
  },
  async mounted() {
    try {
      this.loading = true;
      this.transactions = await getTransactions();
    } catch (error) {
      this.error = 'Unable to load transactions.';
    } finally {
      this.loading = false;
    }
  },
  methods: {
    formatShortDateTime,
    formatDurationShort,
    close() {
      if (canGoBack()) {
        this.router.go(-1);
      } else {
        this.router.push(`/billing`);
      }
    },
  },
};
</script>
