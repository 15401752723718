const environmentConstants =
  import.meta.env.VITE_NODE_ENV === 'production'
    ? {
        NODE_ENV: import.meta.env.VITE_NODE_ENV,
        API_URL: 'https://api.thevirtualgurus.com',
        WS_URL: 'wss://api.thevirtualgurus.com',
        ASKBETTY_WEBSITE_URL: 'https://ondemand.thevirtualgurus.com',
        ASKBETTY_APP_URL: 'https://app.ondemand.thevirtualgurus.com',
        STRIPE_API_KEY: 'pk_live_XE34WVCNyS0kBZX5CuLlNCTh',
        SHORT_ENV_PREFIX: '',
        GTM_ID: 'GTM-PF7HLMF',
      }
    : import.meta.env.VITE_NODE_ENV === 'qa'
    ? {
        NODE_ENV: import.meta.env.VITE_NODE_ENV,
        API_URL: 'https://qa-api.thevirtualgurus.com',
        WS_URL: 'wss://qa-api.thevirtualgurus.com',
        ASKBETTY_WEBSITE_URL: 'https://ondemand.thevirtualgurus.com',
        ASKBETTY_APP_URL: 'https://qa-app.ondemand.thevirtualgurus.com',
        STRIPE_API_KEY: 'pk_test_ac9n1ONiu7Ij8AiAWHx0MUPl',
        SHORT_ENV_PREFIX: 'QA',
      }
    : import.meta.env.VITE_NODE_ENV === 'build'
    ? {
        NODE_ENV: import.meta.env.VITE_NODE_ENV,
        API_URL: 'https://build-api.thevirtualgurus.com',
        WS_URL: 'wss://build-api.thevirtualgurus.com',
        ASKBETTY_WEBSITE_URL: 'https://ondemand.thevirtualgurus.com',
        ASKBETTY_APP_URL: 'https://build-app.ondemand.thevirtualgurus.com',
        STRIPE_API_KEY: 'pk_test_ac9n1ONiu7Ij8AiAWHx0MUPl',
        SHORT_ENV_PREFIX: 'BUILD',
      }
    : {
        NODE_ENV: import.meta.env.VITE_NODE_ENV,
        API_URL: 'http://dev-api.thevirtualgurus.com',
        WS_URL: 'ws://dev-api.thevirtualgurus.com',
        ASKBETTY_WEBSITE_URL: 'https://ondemand.thevirtualgurus.com',
        ASKBETTY_APP_URL: 'http://dev-app.ondemand.thevirtualgurus.com',
        STRIPE_API_KEY: 'pk_test_ac9n1ONiu7Ij8AiAWHx0MUPl',
        SHORT_ENV_PREFIX: 'DEV',
      };

export const {
  NODE_ENV,
  API_URL,
  WS_URL,
  ASKBETTY_URL,
  ASKBETTY_WEBSITE_URL,
  ASKBETTY_APP_URL,
  STRIPE_API_KEY,
  SHORT_ENV_PREFIX,
  GTM_ID,
  noTasksMessage,
  MESSAGE_PAGINATION_LIMIT,
  MARK_READ_TIMEOUT_LENGTH,
} = {
  ...environmentConstants,
  noTasksMessage: {
    new: `Hey! Add a task and let's start crushing your goals.`,
    active: `Hey! Looks like you don't have any assigned tasks, yet!`,
    review: `Hey! Looks like you don't have any tasks ready for review, yet!`,
    completed: `Hey! Looks like you don't have any completed tasks, yet!`,
    default: `Hey! Looks like you don't have any tasks, yet!`,
  },
  MESSAGE_PAGINATION_LIMIT: 30,
  MARK_READ_TIMEOUT_LENGTH: 1000,
};
