<template>
  <header
    v-if="userStore.isOnDemandUser"
    class="sticky flex h-16 w-full max-w-full justify-center border-b-2 border-solid border-slate-200 bg-white p-2"
  >
    <button
      v-if="
        route.meta.showClose &&
        userStore.user.permissions.includes('askbetty_client')
      "
      class="flex shrink items-center"
      @click="closeTask"
    >
      <fa-icon
        icon="fa-regular fa-arrow-left"
        class="bg-vgmedturq mt-1 h-6 w-6 rounded-full px-1 pt-0.5 text-center text-sm text-white"
      />
      <span class="text-vgmedturq pl-2 text-left text-sm uppercase">Back</span>
    </button>
    <button
      v-else-if="
        route.meta.showBack &&
        (userStore.user.permissions.includes('askbetty_client') ||
          route.meta.alwaysShowBack)
      "
      class="flex h-8"
      @click="backToTask"
    >
      <fa-icon
        icon="fa-regular fa-chevron-left"
        class="bg-vgmedturq h-8 w-8 rounded-full px-1 pt-1 text-center text-lg text-white"
        style="line-height: 1.5rem"
      />
      <span class="text-vgmedturq self-center pl-2 text-left text-sm uppercase"
        >Back to {{ route.meta.showBack }}</span
      >
    </button>

    <div class="flex-1 p-2 text-center">
      <div v-if="route.meta.headerTitle" class="font-semibold">
        {{ route.meta.headerTitle }}
      </div>
      <div
        v-else-if="
          !route.meta.showClose &&
          userStore.user?.permissions.includes('askbetty_client')
        "
        class="flex items-center gap-4 text-left"
      >
        <router-link
          :to="userStore.homeLink"
          :aria-current="$route.path === userStore.homeLink"
          ><img
            src="/img/VG-OnDemand-logo-horizontal.svg"
            width="128"
            height="60"
        /></router-link>
        <h1 class="w-[14rem] truncate text-lg font-semibold md:w-full">
          Welcome, {{ userStore.user.known_as || userStore.user.full_name }}!
        </h1>
      </div>
    </div>
    <fa-icon
      icon="fa-light fa-bars"
      class="mx-4 text-3xl"
      @click="uiStore.setNavDrawerOpen(true)"
    />
  </header>
</template>

<script>
import { mapStores } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useUiStore } from '@/stores/ui';
import { canGoBack } from '@/services/httpService';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'AppHeader',
  data() {
    const router = useRouter();
    const route = useRoute();
    return { router, route };
  },
  computed: {
    ...mapStores(useUserStore, useUiStore),
  },
  methods: {
    closeTask() {
      if (canGoBack()) {
        this.router.go(-1);
      } else {
        this.router.push(
          this.route.meta.showBack === 'Clients' ? '/clients' : `/tasks`
        );
      }
    },
    backToTask() {
      if (canGoBack()) {
        this.router.go(-1);
      } else {
        this.router.push(
          this.route.meta.showBack === 'Clients'
            ? '/clients'
            : `/tasks/${this.route.params.task_uuid}`
        );
      }
    },
  },
};
</script>
