<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    persistent
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <div class="rounded-2xl bg-white p-8">
      <div
        v-if="!userStore.user?.permissions.includes('askbetty_client')"
        class="flex flex-col items-center gap-4 p-4 text-center"
      >
        <p class="text-vgnavy pb-2 text-xl font-semibold">
          Credit Card Required
        </p>
        <p class="text-vgnavy text-base">
          The client does not have a credit card on file, and must provide one
          before tasks can be started. Please ask the user to go to
          <span class="whitespace-nowrap">{{ ASKBETTY_APP_URL }}/billing</span>
          to enter their credit card information.
        </p>
        <button
          class="bg-vgmedturq mt-4 rounded-full p-2 px-6 font-semibold uppercase text-white disabled:opacity-50"
          @click="sendCreditCardReminderEmail"
        >
          <span v-if="creditCardReminderLoading">Sending...</span>
          <span v-else-if="creditCardReminderSent">Reminder Sent!</span>
          <span v-else>Send Reminder Email</span>
        </button>
        <button
          class="bg-vgmedturq mt-4 rounded-full p-2 px-6 font-semibold uppercase text-white disabled:opacity-50"
          @click="cancel"
        >
          Ok
        </button>
      </div>
      <div
        v-else-if="!userStore.user?.is_primary_contact"
        class="flex flex-col items-center gap-4 p-4 text-center"
      >
        <p class="text-vgnavy pb-2 text-xl font-semibold">
          Credit Card Required
        </p>
        <p class="text-vgnavy text-base">
          Your company either does not have a valid credit card or billing
          address on file. Please contact your administrator at:
          <a
            class="text-vgocean ml-auto block p-3 text-center text-sm font-semibold uppercase"
            :href="`mailto:${primaryUserEmail}`"
          >
            {{ primaryUserEmail }}
          </a>
        </p>
        <button
          class="bg-vgmedturq rounded-full p-2 px-6 font-semibold uppercase text-white disabled:opacity-50"
          @click="cancel"
        >
          Ok
        </button>
      </div>
      <v-form v-else @submit.prevent="ok">
        <div
          v-if="stage === 'start'"
          class="flex flex-col items-center gap-4 p-4 text-center"
        >
          <p class="text-vgnavy pb-2 text-xl font-semibold">
            Ready, Set, Get Sh*t Done!
          </p>
          <p class="text-vgnavy text-base font-semibold">
            We're excited to get started on your task, lets get your payment
            method on file and we'll be off to the races!
          </p>
          <img src="/img/CC-Image.svg" width="256" height="212" class="py-4" />
          <p class="text-vgorange font-semibold">Billing and what to expect!</p>
          <p class="text-vgorange text-base font-semibold">
            We will not charge your account until your task is complete and
            you've had a chance to review and approve the work!
          </p>
          <button
            class="bg-vgmedturq mt-4 min-w-[80%] rounded-full p-2 px-6 font-semibold uppercase text-white disabled:opacity-50"
            type="submit"
          >
            Add Payment Method
          </button>
          <button class="uppercase text-slate-500" @click="cancel">
            Cancel
          </button>
        </div>
        <div
          v-else-if="stage === 'card'"
          class="text-vgnavy flex flex-col gap-2 p-4"
        >
          <p class="pb-2 text-center text-xl font-semibold">Payment Info</p>
          <p class="text-base font-semibold">
            Please enter your credit card info below
            <fa-icon class="text-vgorange" icon="fa-regular fa-asterisk mr-2" />
          </p>
          <StripeElements
            v-if="stripeLoaded"
            ref="stripeElms"
            v-slot="{ elements }"
            class="w-full"
            :stripe-key="STRIPE_API_KEY"
          >
            <StripeElement
              ref="stripeCard"
              class="stripe-card clear-both box-border block border bg-white px-2.5 py-3 shadow-lg"
              type="card"
              :elements="elements"
              @change="complete = $event.complete"
              @focus="stripeElementHasFocus = true"
              @blur="stripeElementHasFocus = false"
            />
          </StripeElements>
          <div class="text-vgnavy mt-4 flex w-full flex-col items-center">
            <p class="mb-2 w-full text-start font-semibold">
              Billing Location
              <fa-icon
                class="text-vgorange"
                icon="fa-regular fa-asterisk mr-2"
              />
            </p>
            <div class="col-span-2 w-full md:col-span-1">
              <div
                class="flex"
                @click="
                  addressError = false;
                  addressEdit = true;
                "
              >
                <FullAddress
                  :edit="addressEdit"
                  :show-buttons="false"
                  :required="true"
                  :address="fullAddress"
                  :error="addressError"
                  @save="($event) => updateLocation($event)"
                  @hide="addressError = false"
                />
              </div>
            </div>
          </div>
          <button
            :aria-disabled="!formValid"
            class="bg-vgmedturq mt-4 min-w-[80%] rounded-full p-2 px-6 font-semibold uppercase text-white disabled:opacity-50"
            :class="{ 'opacity-50': !formValid }"
            type="submit"
          >
            <v-progress-circular
              v-if="creditCardLoading"
              :size="25"
              indeterminate
            ></v-progress-circular>
            <span v-else>Submit Payment Method</span>
          </button>
          <button class="uppercase text-slate-500" @click.prevent="cancel">
            Cancel
          </button>
        </div>
        <div
          v-else-if="stage === 'success'"
          class="text-vgnavy flex flex-col items-center gap-4 p-4 text-center"
        >
          <p class="text-2xl font-semibold">Success!</p>
          <p class="text-base font-semibold">
            Your payment method has been successfully added to your account. You
            can always add another card or update your info in your
            <span class="text-vgmedturq">“Profile”</span>
          </p>
          <div class="relative mt-8">
            <img
              src="/img/CC-Image.svg"
              width="256"
              height="212"
              class="py-4"
            />
            <div
              class="absolute -top-6 left-[90px] -mb-8 rounded-full bg-white p-2"
            >
              <fa-icon
                icon="fa-regular fa-check"
                class="text-vgtertiarydarkblue border-vgtertiarydarkblue h-16 w-16 rounded-full border-4 border-solid text-4xl font-bold"
                style="line-height: 1.6"
              />
            </div>
          </div>
          <button
            class="bg-vgmedturq mt-4 rounded-full p-2 px-8 font-semibold uppercase text-white disabled:opacity-50"
            type="submit"
          >
            Close
          </button>
        </div>

        <div
          v-else-if="stage === 'error'"
          class="flex flex-col items-center gap-4 p-4 text-center"
        >
          <p class="text-vgorange text-2xl font-semibold">Oops!</p>
          <p class="text-vgnavy text-base">
            It looks like we couldn't authenticate your card. You can try the
            same card again or enter a different card.
          </p>
          <div class="relative mt-8">
            <img
              src="/img/CC-Image.svg"
              width="256"
              height="212"
              class="py-4"
            />
            <div
              class="absolute -top-6 left-[90px] -mb-8 rounded-full bg-white p-2"
            >
              <fa-icon
                icon="fa-regular fa-question"
                class="text-vgorange border-vgorange h-16 w-16 rounded-full border-4 border-solid text-4xl font-bold"
                style="line-height: 1.6"
              />
            </div>
          </div>
          <button
            class="bg-vgmedturq mt-4 rounded-full p-2 px-8 font-semibold uppercase text-white disabled:opacity-50"
            type="submit"
          >
            Try Again
          </button>
          <button class="uppercase text-slate-500" @click="cancel">
            Cancel
          </button>
        </div>
      </v-form>
    </div>
  </v-dialog>
</template>

<script>
import {
  saveClientCreditCard,
  sendCreditCardReminderEmail,
  saveClient,
} from '@/services/clientService';

import { STRIPE_API_KEY, ASKBETTY_APP_URL } from '@/config';
import { StripeElements, StripeElement } from 'vue-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useUserStore } from '@/stores/user';
import FullAddress from '@/components/FullAddress.vue';

const defaultOptions = {
  width: 600,
  zIndex: 200,
};

export default {
  name: 'AddCreditCardDialog',
  components: { StripeElements, StripeElement, FullAddress },
  data: () => {
    const userStore = useUserStore();
    const client = userStore.getClient;
    return {
      client,
      userStore,
      STRIPE_API_KEY,
      ASKBETTY_APP_URL,
      dialog: false,
      resolve: null,
      reject: null,
      task: null,
      stage: 'start',
      options: defaultOptions,
      complete: false,
      creditCardLoading: false,
      locationCardLoading: false,
      creditCardReminderLoading: false,
      creditCardReminderSent: false,
      stripeLoaded: false,
      addressEdit: false,
      addressError: false,
      valid: false,
      errorMessage: '',
    };
  },
  computed: {
    fullAddress() {
      return {
        address_line_1: this.client?.address_line_1,
        address_line_2: this.client?.address_line_2,
        city: this.client?.city,
        state_prov: this.client?.state_prov,
        country: this.client?.country,
        zipcode: this.client?.zipcode,
      };
    },
    formValid() {
      return this.valid && this.complete && !this.addressError;
    },
    primaryUserEmail() {
      return this.userStore.client?.contacts?.find(
        (contact) => contact.is_primary_user
      ).business_email;
    },
  },
  created() {
    if (!this.userStore.client?.client?.city) {
      this.addressEdit = true;
    } else {
      this.valid = true;
    }
  },
  methods: {
    open(task) {
      this.task = task;
      this.creditCardReminderSent = false;
      this.dialog = true;
      this.stage = 'start';
      if (
        !this.stripeLoaded &&
        this.userStore.user?.permissions.includes('askbetty_client')
      ) {
        loadStripe(STRIPE_API_KEY).then(() => {
          this.stripeLoaded = true;
        });
      }
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    ok() {
      if (this.stage === 'start') {
        this.stage = 'card';
        return;
      }
      if (this.stage === 'card') {
        if (!this.valid) {
          return this.$root.$snackbar.error(this.errorMessage);
        }
        if (!this.complete) {
          return this.$root.$snackbar.error(
            'Please verify your payment details and try again'
          );
        }

        return this.handleSaveClientCreditCard();
      }
      if (this.stage === 'error') {
        this.stage = 'card';
        return;
      }
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
    validateCreditCard() {
      const groupComponent = this.$refs.stripeElms;
      const cardComponent = this.$refs.stripeCard;
      // Get stripe element
      const cardElement = cardComponent.stripeElement;

      // Access instance methods, e.g. createToken()
      return groupComponent.instance.createToken(cardElement).then((data) => {
        return {
          token: data.token.id,
          stripe_card_id: data.token.card.id,
          brand: data.token.card.brand,
          last_four: data.token.card.last4,
          expiry_month: data.token.card.exp_month,
          expiry_year: data.token.card.exp_year,
        };
      });
    },
    async handleSaveClientCreditCard() {
      this.creditCardLoading = true;

      try {
        await saveClient({ ...this.userStore.client.client });
        await this.userStore.loadSession();

        const newClientCreditCard = await this.validateCreditCard();
        await saveClientCreditCard(
          this.userStore.user?.client_uuid,
          newClientCreditCard
        );
        this.stage = 'success';
      } catch (error) {
        this.stage = 'error';
      } finally {
        this.creditCardLoading = false;
      }
    },
    updateLocation(data) {
      if (data.valid === false) {
        this.errorMessage =
          data.error || 'Please provide a complete address and try again';
        this.valid = false;

        return;
      }

      this.valid = true;

      try {
        this.userStore.client.client = {
          ...this.userStore.client.client,
          address_line_1: data.address.address_line_1,
          address_line_2: data.address.address_line_2,
          city: data.address.city,
          state_prov: data.address.state_prov,
          country: data.address.country,
          zipcode: data.address.zipcode,
          timezone: data.timezone,
          timezone_offset: data.timezone_offset,
        };

        this.stage = 'card';
      } catch (error) {
        this.stage = 'error';
      } finally {
        this.addressError = false;
      }
    },
    sendCreditCardReminderEmail() {
      if (this.creditCardReminderSent || this.creditCardReminderLoading) {
        return;
      }
      try {
        this.creditCardReminderLoading = true;
        sendCreditCardReminderEmail(this.task.user_uuid);
      } catch (error) {
        return this.$root.$snackbar.error(
          error?.data?.message ?? 'Unable to send credit card reminder email'
        );
      } finally {
        this.creditCardReminderLoading = false;
      }
      this.creditCardReminderSent = true;
    },
  },
};
</script>
<style scoped>
* :deep(.stripe-card) {
  @apply mt-0 shadow-none  rounded-lg border-slate-400;
}
* :deep(.stripe-card:hover) {
  @apply border-slate-700;
}
</style>
