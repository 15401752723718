<template>
  <div class="flex flex-col bg-[#FBFBFB] p-4">
    <div
      v-if="!uiStore.mobile"
      ref="tablist"
      class="m-4 flex gap-2 overflow-x-auto text-sm font-semibold"
    >
      <router-link to="/profile">
        <button
          class="grow rounded-lg border border-solid border-slate-300 px-6 py-2 uppercase tracking-wide lg:max-w-[8rem]"
          :class="
            $route.path === '/profile'
              ? 'bg-vgmedturq text-white'
              : 'bg-white hover:bg-slate-200 text-slate-500'
          "
        >
          Profile
        </button>
      </router-link>
      <router-link
        v-if="
          user?.permissions.includes('askbetty_client') &&
          user?.is_primary_contact
        "
        to="/billing"
      >
        <button
          class="grow rounded-lg border border-solid border-slate-300 px-6 py-2 uppercase tracking-wide lg:max-w-[8rem]"
          :class="
            $route.path === '/billing'
              ? 'bg-vgmedturq text-white'
              : 'bg-white hover:bg-slate-200 text-slate-500'
          "
        >
          Billing
        </button>
      </router-link>
    </div>
    <v-form
      v-model="profile.valid"
      class="m-4 flex flex-col place-items-center rounded-xl bg-white p-8 text-sm font-semibold lg:place-items-start"
    >
      <h1 class="mb-6 text-xl font-semibold">Your Profile</h1>
      <div class="flex flex-col gap-6 lg:flex-row lg:gap-12">
        <div class="mx-auto">
          <span class="relative flex max-w-fit flex-col">
            <v-avatar size="100" style="border: 2px solid #18988b">
              <v-progress-circular
                v-if="avatar.loading"
                indeterminate
                :color="vgMedTurq"
                :size="100"
                :width="6"
              ></v-progress-circular>
              <img
                v-show="!avatar.loading"
                :alt="`${user.full_name} avatar`"
                :src="avatar.link"
                @load="avatar.loading = false"
              />
            </v-avatar>
            <button
              type="button"
              class="absolute bottom-0 right-0 rounded-full bg-white p-1 underline"
              @click="showAvatarCropper = true"
            >
              <fa-icon
                role="button"
                aria-label="Edit avatar"
                icon="fa-regular fa-pencil"
                class="ml-1 text-slate-700"
              />
            </button>
          </span>
          <avatar-cropper
            ref="avatarCropper"
            v-model="showAvatarCropper"
            :labels="{ submit: 'OK', cancel: 'Cancel' }"
            @submit="handleUpload"
          />
        </div>
        <div class="text-base md:w-3/5 lg:w-2/5 lg:p-4 xl:w-1/2">
          <div>
            <div v-if="profile.fullName.edit" class="mb-8" aria-live="polite">
              <v-text-field
                v-model="profile.fullName.new"
                :color="vgMedTurq"
                variant="outlined"
                density="compact"
                class="text-sm font-normal"
                rounded="xl"
                bg-color="#fff"
                placeholder="Full Name"
                :rules="rules.requiredRules"
                required
                @keyup.esc.prevent="profile.fullName.edit = false"
                @keypress.enter.prevent="updateName"
              ></v-text-field>
              <div class="flex justify-end text-xs">
                <button
                  class="text-vgmedturq border-vgmedturq ml-2 h-10 rounded-full border-[1px] border-solid px-8 font-bold uppercase tracking-wider"
                  :loading="profile.loading"
                  @click="profile.fullName.edit = false"
                >
                  Cancel
                </button>
                <button
                  class="bg-vgmedturq ml-4 h-10 rounded-full px-8 font-semibold uppercase tracking-wider text-white"
                  :loading="profile.loading"
                  @click.prevent="updateName()"
                >
                  Update
                </button>
              </div>
            </div>
            <div
              v-else
              class="flex cursor-pointer"
              @click="
                resetState();
                profile.fullName.edit = true;
              "
            >
              <span class="w-[16rem] cursor-pointer truncate">{{
                user.full_name
              }}</span>
              <fa-icon
                icon="fa-regular fa-pencil"
                class="ml-4 text-slate-700"
                aria-label="Edit name"
                @click="
                  resetState();
                  profile.fullName.edit = true;
                "
              />
            </div>
          </div>
          <div>
            <div class="my-4 flex gap-4">
              <label for="ab-client-password">Password:</label>
              <span v-if="!profile.password.edit"> ********</span>
            </div>
            <button
              v-if="!profile.password.edit"
              class="bg-vgmedturq h-10 w-full rounded-full px-8 text-xs uppercase tracking-wider text-white"
              :loading="profile.loading"
              @click="
                resetState();
                profile.password.edit = true;
              "
            >
              Change Password
            </button>
            <div
              v-if="profile.password.edit"
              id="ab-client-password"
              class="mt-4 flex gap-6"
              :class="{ 'flex-col ': profile.password.edit }"
            >
              <div v-if="profile.password.edit" class="max-w-sm">
                <v-text-field
                  v-model="profile.password.old"
                  :color="vgMedTurq"
                  variant="outlined"
                  density="compact"
                  class="text-sm font-normal"
                  rounded="xl"
                  bg-color="#fff"
                  placeholder="Old Password"
                  required
                  type="password"
                />
                <v-text-field
                  v-model="profile.password.new"
                  :color="vgMedTurq"
                  variant="outlined"
                  density="compact"
                  class="text-sm font-normal"
                  rounded="xl"
                  bg-color="#fff"
                  placeholder="New Password"
                  :rules="rules.passwordRules"
                  required
                  type="password"
                />
                <v-text-field
                  v-model="profile.password.confirm"
                  :color="vgMedTurq"
                  variant="outlined"
                  density="compact"
                  class="text-sm font-normal"
                  rounded="xl"
                  bg-color="#fff"
                  placeholder="Confirm Password"
                  :rules="rules.confirmPasswordRules"
                  required
                  type="password"
                />
                <div class="flex justify-end gap-3 text-xs tracking-wider">
                  <button
                    class="border-vgmedturq text-vgmedturq h-10 rounded-full border border-solid px-6 uppercase tracking-wider"
                    :loading="profile.loading"
                    :disabled="profile.loading"
                    @click.prevent="resetState()"
                  >
                    Cancel
                  </button>
                  <button
                    class="bg-vgmedturq h-10 rounded-full px-6 uppercase tracking-wider text-white"
                    :loading="profile.loading"
                    :disabled="profile.loading"
                    @click.prevent="updatePassword()"
                  >
                    Save Password
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex flex-col lg:border-l-2 lg:px-8"
          :class="{
            'max-h-[10rem]': profile.password.edit && !user.phone_number,
            'max-h-[14rem]': profile.password.edit && user.phone_number,
          }"
        >
          <h2 class="mb-6 border-t-2 pt-4 text-lg md:border-t-0">
            Contact Info
          </h2>
          <div class="text-vgstone-700 flex flex-col gap-2">
            <div class="flex items-center gap-4">
              <fa-icon
                icon="fa-regular fa-envelope"
                class="text-vgmedturq text-lg"
              />
              {{ user.email }}
            </div>
            <div v-if="user.phone_number" class="flex items-center gap-4">
              <fa-icon
                icon="fa-regular fa-phone-plus"
                class="text-vgmedturq text-lg"
              />
              {{ user.phone_number }}
            </div>
            <div class="flex items-start gap-4">
              <fa-icon
                icon="fa-regular fa-location-plus"
                class="text-vgmedturq text-lg"
              />

              <div class="w-full" @click="editLocation()">
                <FullAddress
                  :edit="profile.address.edit"
                  :required="true"
                  :address="fullAddress"
                  :error="profile.address.error"
                  @save="($event) => updateLocation($event)"
                  @hide="resetState()"
                />
              </div>
            </div>
            <div v-if="user.timezone" class="flex items-center gap-4">
              <fa-icon
                icon="fa-regular fa-globe"
                class="text-vgmedturq text-lg"
              />
              {{ user.timezone }}
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="mt-6">
        <h2 class="mb-4 text-lg">About</h2>
        <div class="flex items-start">
          <v-textarea
            v-model="profile.about.new"
            variant="outlined"
            auto-grow
            no-resize
            rows="4"
            class="p-4"
            rounded="lg"
            :color="vgmedturq"
          >
          </v-textarea>
          <fa-icon
            icon="fa-regular fa-pencil"
            class="ml-4 text-slate-700"
            @click="profile.about.edit = true"
          />
        </div>
      </div>
      <div
        class="flex-inline flex justify-end gap-3 text-xs tracking-wider text-white"
      >
        <button
          class="border-vgmedturq text-vgmedturq h-10 rounded-full border border-solid px-6 uppercase tracking-wider"
          :loading="profile.loading"
          @click="handleClose()"
        >
          Cancel
        </button>
        <button
          class="bg-vgmedturq h-10 rounded-full px-6 uppercase tracking-wider"
          :loading="profile.loading"
        >
          Save Changes
        </button>
      </div> -->
    </v-form>
  </div>
</template>

<script>
import { reactive, ref, inject, watch, computed } from 'vue';
import { storeToRefs } from 'pinia';
import AvatarCropper from 'vue-avatar-cropper';
import FullAddress from '@/components/FullAddress.vue';
import { uploadAvatar } from '@/services/fileService';
import { API_URL } from '@/config';
import { useUserStore } from '@/stores/user';
import tailwind from 'tailwind.config';
import { validatePassword } from '@/services/validationService';
import { changePassword } from '@/services/clientSessionService';
import { saveClient } from '@/services/clientService';
import { saveUser } from '@/services/userService';
import { useUiStore } from '@/stores/ui';

export default {
  components: { AvatarCropper, FullAddress },
  setup() {
    const vgMedTurq = tailwind.theme.extend.colors.vgmedturq[500];
    const snackbar = inject('snackbar');
    const avatar = reactive({
      loading: false,
      link: null,
      id: null,
    });
    const profile = reactive({
      password: { new: '', old: '', confirm: '', edit: false },
      fullName: { new: '', edit: false },
      loading: false,
      address: { edit: false, error: false },
      valid: false,
    });
    const rules = {
      passwordRules: [(v) => !!v || 'Required', validatePassword],
      confirmPasswordRules: [
        (v) => !!v || 'Required',
        (v) => v === profile.password.new || 'Confirm password must match',
      ],
      requiredRules: [(v) => !!v || 'Required'],
    };
    const userStore = useUserStore();
    const uiStore = useUiStore();
    const { user } = storeToRefs(userStore);
    const client = userStore.getClient;

    const fullAddress = computed(() => {
      return {
        address_line_1: client.address_line_1,
        address_line_2: client.address_line_2,
        city: client.city,
        state_prov: client.state_prov,
        country: client.country,
        zipcode: client.zipcode,
      };
    });
    const showAvatarCropper = ref(false);
    const avatarCropper = ref(null);

    watch(
      () => user,
      (newValue) => {
        if (newValue) {
          profile.fullName.new =
            newValue.value.full_name ?? newValue.value.known_as;
          avatar.id = newValue.value.avatar_id;
        }
      },
      { immediate: true, deep: true }
    );
    watch(
      () => avatar.id,
      (newValue) => {
        avatar.link = newValue
          ? `${API_URL}/file/avatar/${newValue}`
          : '/img/img-quote_avatar.png';
      },
      { immediate: true, deep: true }
    );

    async function updatePassword() {
      profile.loading = true;
      if (!profile.valid) {
        return;
      }
      try {
        await changePassword(
          user.value.email,
          profile.password.old,
          profile.password.new
        );
        snackbar.message('Password successfully changed.');
        resetState();
      } catch (error) {
        return snackbar.error(
          error?.data?.message ??
            'Unable to change your password. Please try again later.'
        );
      } finally {
        profile.loading = false;
      }
    }

    function handleUpload() {
      avatarCropper.value.cropper.getCroppedCanvas().toBlob(async (blob) => {
        avatar.loading = true;

        try {
          const res = await uploadAvatar(blob, user.value.uuid);

          await save(
            {
              uuid: user.value.uuid,
              avatar_id: res.avatar_id,
            },
            'user'
          );
          avatar.id = res?.avatar_id;
        } catch (error) {
          avatar.id = user.value.avatar_id;
          snackbar.error(
            error?.data?.message ??
              'Unable to upload avatar. Please try again later.'
          );
        } finally {
          avatar.loading = false;
        }
      });
    }

    async function updateName() {
      if (user.value.full_name === profile.fullName.new) {
        resetState();
        return;
      }

      if (
        await save(
          {
            uuid: user.value.uuid,
            full_name: profile.fullName.new,
            known_as: profile.fullName.new.split(' ')[0],
          },
          'user'
        )
      ) {
        user.value.full_name = profile.fullName.new;
      }
    }

    function resetState() {
      profile.fullName.edit = false;
      profile.password.edit = false;
      profile.address.error = false;
      profile.password.new = '';
      profile.password.confirm = '';
      profile.password.old = '';
      profile.loading = false;
    }

    async function updateLocation(data) {
      if (data.valid === false) {
        snackbar.error(
          data.error || 'Please provide a complete address and try again'
        );

        return;
      }

      const payload = {
        uuid: client.uuid,
        address_line_1: data.address.address_line_1,
        address_line_2: data.address.address_line_2,
        city: data.address.city,
        state_prov: data.address.state_prov,
        country: data.address.country,
        zipcode: data.address.zipcode,
        timezone: data.timezone,
        timezone_offset: data.timezone_offset,
      };

      await save(payload, 'client');
    }

    async function save(payload, type) {
      let success = false;

      profile.loading = true;

      try {
        if (type === 'client') {
          await saveClient(payload);
        } else {
          await saveUser(payload);
          await userStore.loadSession();
        }

        success = true;
        snackbar.message('Success! Your changes have been saved!');
      } catch (error) {
        snackbar.error(
          error?.data?.message ?? 'An error occurred. Please try again later'
        );
      } finally {
        resetState();
      }

      return success;
    }
    function editLocation() {
      if (user.is_primary_contact) {
        this.resetState();
        profile.address.edit = true;
      }
    }

    return {
      avatar,
      fullAddress,
      profile,
      handleUpload,
      vgMedTurq,
      resetState,
      save,
      client,
      user,
      rules,
      updatePassword,
      updateLocation,
      updateName,
      showAvatarCropper,
      avatarCropper,
      uiStore,
      editLocation,
    };
  },
};
</script>
