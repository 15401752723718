export const isValidEmail = (email) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email.toLowerCase().trim()
  );
};

export const isValidPhone = (phone) => {
  return /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/.test(phone);
};

export const validatePassword = (password) => {
  const requirements = [
    !/[A-Z]/.test(password) && 'uppercase letter',
    !/[a-z]/.test(password) && 'lowercase letter',
    !/\d/.test(password) && 'number',
    !/[^A-Za-z\d]/.test(password) && 'special character',
  ].filter(Boolean);

  if (password?.length < 8) {
    return `Password must be at least 8 characters${
      requirements.length > 0
        ? ` and contain at least one: ${requirements.join(', ')}`
        : ''
    }.`;
  }
  return (
    requirements.length === 0 ||
    `Password must contain at least one: ${requirements.join(', ')}.`
  );
};
