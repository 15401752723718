import {
  getJSON,
  putJSON,
  postJSON,
  deleteAction,
} from '@/services/httpService';

export const getClients = () => getJSON(`/askbetty/v2/client/`);

export const getClient = (id) => getJSON(`/askbetty/v2/client/${id}`);

export const sendCreditCardReminderEmail = (clientUuid) =>
  postJSON(`/askbetty/v2/client/${clientUuid}/credit_card_reminder_email`);

export const getClientSelfById = () => getJSON(`/client/self`);

export const getClientCreditCards = (clientUuid, source) =>
  getJSON(`/client/${clientUuid}/credit_card/${source}`);

export const saveClientCreditCard = (clientUuid, creditCard) =>
  postJSON(`/client/${clientUuid}/credit_card`, creditCard);

export const updateDefaultClientCreditCard = (clientUuid, creditCard) =>
  putJSON(`/client/${clientUuid}/credit_card`, creditCard);

export const deleteClientCreditCard = (clientUuid, creditCardUuid) =>
  deleteAction(`/client/${clientUuid}/credit_card/${creditCardUuid}`);

export const getClientConfig = () => getJSON(`/client/config`);

export const saveClient = (client) => {
  putJSON(`/client/${client.uuid}`, {
    ...client,
    auditLogEntries: undefined,
  });
};
