<template>
  <div id="ClientCreditCards">
    <v-alert
      v-if="cardError"
      type="error"
      icon="mdi-alert"
      class="mt-3 w-full"
      closable
      close-text="Close Alert"
    >
      {{ cardError }}
    </v-alert>
    <p
      v-if="!loadingCreditCards && clientCreditCards.length === 0"
      class="text-slate-700"
    >
      You have no billing information on file. Please add a card.
    </p>
    <div v-if="loadingCreditCards">
      <div class="text-center">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>
    <div
      v-for="creditCard in clientCreditCards"
      :key="creditCard.uuid"
      class="flex max-w-2xl flex-col py-2 md:py-6"
    >
      <div
        class="flex items-center justify-between rounded-xl border border-slate-100 p-4 shadow-md md:grid md:grid-cols-3"
      >
        <div class="text-vgnavy flex">
          <fa-icon class="mt-2" :icon="creditCardIcon(creditCard.brand)" />
          <p class="min-w-fit pl-3 text-base font-semibold">
            **** **** **** {{ creditCard.last_four }}
          </p>
        </div>

        <div class="grid grid-flow-row justify-items-center">
          <label
            v-if="!uiStore.mobile"
            class="text-sm font-normal uppercase text-slate-700"
            >Expires</label
          >
          <p
            v-if="
              moment().year() > creditCard.expiry_year ||
              (moment().year() === creditCard.expiry_year &&
                moment().format('M') > creditCard.expiry_month)
            "
            class="text-vgorange text-sm font-semibold"
          >
            <fa-icon
              icon="fa-light fa-triangle-exclamation"
              class="pr-2"
            />expired
          </p>
          <p
            v-else
            title="Credit card expiry date"
            class="text-vgnavy upercase m-0 self-end text-base font-semibold"
          >
            {{ creditCard.expiry_month }}/{{ creditCard.expiry_year }}
          </p>
        </div>

        <div
          v-if="
            !(
              moment().year() > creditCard.expiry_year ||
              (moment().year() === creditCard.expiry_year &&
                moment().format('M') > creditCard.expiry_month)
            )
          "
          id="menu-container"
          class="flex justify-end md:mx-4"
        >
          <v-menu attach="#menu-container" location="bottom">
            <template #activator="{ props }">
              <button
                v-if="getOptions(creditCard)?.length > 0"
                v-bind="props"
                class="focus:ring-vgmedturq rounded p-2 focus:ring-2"
                aria-label="Credit card menu"
                title="Credit card menu"
              >
                <fa-icon icon="fa-regular fa-ellipsis" />
              </button>
              <div v-else class="text-vgnavy mx-2 text-sm">
                <fa-icon
                  icon="fa-regular fa-check"
                  class="text-vgmedturq cursor-pointer font-bold"
                  title="Default payment method "
                />
              </div>
            </template>
            <div
              class="text-vgmedturq-600 flex flex-col rounded-lg border border-slate-100 shadow-sm shadow-gray-600"
            >
              <button
                v-for="(item, i) in getOptions(creditCard)"
                :key="i"
                :aria-label="item.title"
                class="hover:bg-vgmedturq-100 focus:bg-vgmedturq-100 focus:ring-vgmedturq bg-white px-4 py-2 first:rounded-t-lg last:rounded-b-lg focus:ring-2"
                @click="item.action(creditCard)"
              >
                {{ item.title }}
              </button>
            </div>
          </v-menu>
        </div>
      </div>
    </div>
    <div v-if="showAddCreditCard" class="my-4 flex flex-col">
      <label class="pb-4 text-base font-semibold">
        Add a card
        <fa-icon class="text-vgorange" icon="fa-regular fa-asterisk" />
      </label>
      <StripeElements
        v-if="stripeLoaded"
        ref="stripeElms"
        v-slot="{ elements }"
        :stripe-key="STRIPE_API_KEY"
      >
        <StripeElement
          ref="stripeCard"
          class="stripe-card clear-both box-border block w-full max-w-lg rounded-sm border bg-white px-2.5 py-3 shadow-lg"
          type="card"
          :elements="elements"
          :class="complete"
          @change="complete = $event.complete"
          @focus="stripeElementHasFocus = true"
          @blur="stripeElementHasFocus = false"
        />
      </StripeElements>
      <slot v-if="!existingClientCreditCards" name="address"></slot>
      <div class="mt-6 flex flex-row-reverse gap-4">
        <button
          :aria-disabled="!formValid"
          :loading="creditCardLoading"
          class="bg-vgmedturq rounded-full p-2 px-6 font-semibold text-white disabled:opacity-50"
          :class="{ 'opacity-50': !formValid }"
          @click="handleSaveClientCreditCard"
        >
          {{ existingClientCreditCards ? 'Add' : 'Submit Payment Method' }}
        </button>
        <button
          class="uppercase text-slate-500"
          @click="handleCloseClientCreditCard"
        >
          Cancel
        </button>
      </div>
    </div>
    <button
      v-else
      class="bg-vgmedturq mt-4 rounded-full p-2 px-6 font-semibold text-white disabled:opacity-50"
      @click="
        showAddCreditCard = true;
        cardError = '';
      "
    >
      Add Payment Method
    </button>
  </div>
</template>
<script>
import { STRIPE_API_KEY } from '@/config';
import { StripeElements, StripeElement } from 'vue-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
  getClientCreditCards,
  saveClientCreditCard,
  updateDefaultClientCreditCard,
  deleteClientCreditCard,
  saveClient,
} from '@/services/clientService';
import { getUser } from '@/services/clientSessionService';
import { mapStores } from 'pinia';
import { useUiStore } from '@/stores/ui';
import { useUserStore } from '@/stores/user';
import moment from 'moment';

export default {
  name: 'ClientCreditCards',
  components: { StripeElements, StripeElement },
  props: {
    authorizePayment: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
  },
  emits: ['selectedCreditCard'],
  data() {
    const userStore = useUserStore();

    return {
      userStore,
      STRIPE_API_KEY,
      cardError: '',
      payment: 'stripe',
      discountedMonthlyPriceCents: null,
      stripeElementHasFocus: false,
      showAddCreditCard: false,
      complete: false,
      creditCardLoading: false,
      loadingCreditCards: false,
      stripeLoaded: false,
      clientCreditCards: [],
      newClientCreditCard: {},
      askBettyUser: {},
      creditCardIcons: {
        Visa: 'fa-2xl fab fa-cc-visa',
        MasterCard: 'fa-2xl fab fa-brands fa-cc-mastercard',
        default: 'fa-2xl fa fa-credit-card',
        PayPal: 'fa-2xl fa-brands fa-cc-paypal',
        Discover: 'fa-2xl fa-brands fa-cc-discover',
        'American Express': 'fa-2xl fa-brands fa-cc-amex',
        'Diners Club': 'fa-2xl fa-brands fa-cc-diners-club',
      },
      optionItems: [
        {
          title: 'Make Default',
          value: 'default',
          action: (val) => this.handleUpdateDefaultClientCreditCard(val),
        },
        {
          title: 'Delete',
          value: 'delete',
          action: (val) => this.handleDeleteClientCreditCard(val),
        },
      ],
    };
  },
  computed: {
    ...mapStores(useUiStore),
    defaultClientCreditCard() {
      return this.clientCreditCards?.find(
        (creditCard) => creditCard.default_credit_card
      );
    },
    existingClientCreditCards() {
      return this.clientCreditCards?.length > 0;
    },
    creditCardIcon() {
      return (type) => {
        return this.creditCardIcons[type] || this.creditCardIcons.default;
      };
    },
    formValid() {
      return !this.disabled && this.complete;
    },
  },
  created() {
    this.init();
    loadStripe(STRIPE_API_KEY).then(() => {
      this.stripeLoaded = true;
    });
  },
  methods: {
    moment,
    async init() {
      this.askBettyUser = getUser();
      this.loadingCreditCards = true;

      const creditCardsResult = await getClientCreditCards(
        this.askBettyUser?.client_uuid,
        'askbetty2'
      ).catch(() => {
        this.$root.$snackbar.error(
          'An Error occurred, please try again later.'
        );

        return [];
      });
      this.loadingCreditCards = false;

      this.clientCreditCards = creditCardsResult;

      if (
        this.askBettyUser?.city &&
        this.clientCreditCards.length > 0 &&
        !this.clientCreditCards.some(
          (creditCard) => creditCard.default_credit_card
        )
      ) {
        await this.handleUpdateDefaultClientCreditCard(
          this.clientCreditCards[0]
        );
      }
    },
    validateCreditCard() {
      this.cardError = '';
      const groupComponent = this.$refs.stripeElms;
      const cardComponent = this.$refs.stripeCard;
      // Get stripe element
      const cardElement = cardComponent.stripeElement;

      // Access instance methods, e.g. createToken()
      return groupComponent.instance
        .createToken(cardElement)
        .then((data) => {
          this.newClientCreditCard = {
            token: data.token.id,
            stripe_card_id: data.token.card.id,
            brand: data.token.card.brand,
            last_four: data.token.card.last4,
            expiry_month: data.token.card.exp_month,
            expiry_year: data.token.card.exp_year,
          };
        })
        .catch(() => {
          this.creditCardLoading = false;
          this.cardError =
            'Unable to process your request. Please verify your details and try again.';
        });
    },
    async handleSaveClientCreditCard() {
      if (!this.formValid) {
        return this.$root.$snackbar.error(
          this.error ||
            'Please verify your payment/billing details and try again'
        );
      }
      this.creditCardLoading = true;

      try {
        await this.validateCreditCard();
        await saveClient({ ...this.userStore.client.client });
        await saveClientCreditCard(
          this.askBettyUser?.client_uuid,
          this.newClientCreditCard
        );

        await this.init();

        this.newClientCreditCard = {};
      } catch (error) {
        this.cardError =
          error?.data?.message ??
          'Unable to save your changes - please refresh and try again';
      } finally {
        this.creditCardLoading = false;
      }

      this.handleCloseClientCreditCard();
    },
    async handleUpdateDefaultClientCreditCard(card) {
      this.creditCardLoading = true;

      try {
        await updateDefaultClientCreditCard(this.askBettyUser?.client_uuid, {
          uuid: card.uuid,
          source: 'askbetty2',
        });

        this.clientCreditCards.forEach((creditCard) => {
          if (creditCard.uuid === card.uuid) {
            creditCard.default_credit_card = card.uuid;
            this.$root.$snackbar.message(`Default credit card updated.`);
          } else {
            creditCard.default_credit_card = null;
          }
        });
      } catch (error) {
        this.cardError =
          error?.data?.message ??
          'Unable to update default credit card - please try again later.';
      } finally {
        this.creditCardLoading = false;
      }
    },
    async handleDeleteClientCreditCard(creditCard) {
      if (this.defaultClientCreditCard.uuid === creditCard.uuid) {
        this.cardError =
          'Unable to delete default credit card - please select another default card first.';

        return;
      }
      const dialog = await this.$root.$confirm.open(
        `Delete credit card?`,
        `To confirm, please enter the card's expiry date <b>${creditCard.expiry_month}/${creditCard.expiry_year}</b> exactly as shown here and click <b>Delete</b>.`,
        {
          okText: 'Delete',
          cancelText: 'Cancel',
          requiredEntry: `${creditCard.expiry_month}/${creditCard.expiry_year}`,
        }
      );

      if (dialog) {
        try {
          await deleteClientCreditCard(
            this.askBettyUser?.client_uuid,
            creditCard.uuid
          );
          this.clientCreditCards = this.clientCreditCards.filter(
            (card) => card.uuid !== creditCard.uuid
          );
        } catch (error) {
          this.cardError =
            error?.data?.message ??
            'Unable to delete credit card - please try again later.';
        }
      }
    },
    handleCloseClientCreditCard() {
      this.showAddCreditCard = false;
    },
    useSelectedCreditCard(creditCard) {
      this.cardError = '';
      this.selectedCreditCard = creditCard;
      this.$emit('selectedCreditCard', creditCard);
    },
    getOptions(creditCard) {
      return this.optionItems.filter(
        () =>
          creditCard?.default_credit_card !==
          this.defaultClientCreditCard?.default_credit_card
      );
    },
  },
};
</script>
