<template>
  <v-dialog
    v-model="showTemplatesDialog"
    :width="uiStore.mobile ? '100%' : '70%'"
    @click:outside="handleClose()"
  >
    <div class="h-full overflow-y-auto rounded-xl bg-white">
      <div class="flex flex-col justify-center">
        <div class="relative flex flex-col border-b-[1px] p-8">
          <div class="flex flex-row">
            <fa-icon
              class="text-vgmedturq mr-2 h-10 w-10 text-xl lg:h-14 lg:w-14 lg:text-2xl"
              icon="fa-solid fa-wand-magic-sparkles"
            />
            <div>
              <h1 class="text-vgmedturq text-xl font-bold">Task Templates</h1>
              <h2 class="mb-4 font-semibold lg:text-lg">
                Check out some inspiring ways others are using VG OnDemand.
                Let’s help you get started crushing your goals!
              </h2>
            </div>
          </div>
          <div class="mx-auto p-4">
            <button
              aria-label="Close templates"
              class="absolute right-4 top-4"
              @click.prevent.stop="handleClose()"
            >
              <fa-icon icon="fa-light fa-xmark-large text-xl" />
            </button>
          </div>
        </div>
        <div class="border-b-[1px] p-8 py-4">
          <div
            class="mb-2 flex flex-col place-content-center gap-4 font-semibold md:flex-row lg:items-center"
          >
            <span class="flex shrink font-bold lg:my-2">Explore:</span>
            <span
              ><button
                v-for="(cat, idx) in Object.keys(templates)"
                :key="idx"
                :class="
                  selectedType === cat
                    ? 'bg-vgmedturq  text-white'
                    : 'bg-white text-vgmedturq border-vgmedturq border-2 border-solid'
                "
                class="mb-2 mr-2 h-8 self-start rounded-lg px-4 text-sm font-semibold uppercase tracking-wider lg:my-2 lg:h-10 lg:px-16"
                @click="selectedType = cat"
              >
                {{ templates[cat].label }}
              </button></span
            >
          </div>
        </div>
      </div>
      <div>
        <v-expansion-panels variant="accordion">
          <v-expansion-panel
            v-for="(type, id) in templates[selectedType]?.types"
            :key="id"
          >
            <v-expansion-panel-title
              ><template #actions>
                <v-icon :color="vgMedTurq"> $expand </v-icon>
              </template>
              <div
                class="mx-auto flex max-w-2xl flex-auto text-base font-bold lg:text-lg"
              >
                <img
                  :src="`/img/templates/${type.icon}.svg`"
                  class="mr-2 h-10 w-10 lg:h-14 lg:w-14"
                  alt="VG OnDemand logo"
                />
                <div class="text-vgmedturq">{{ type.type }}</div>
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div class="">
                <button
                  v-for="(task, key) in type.tasks"
                  :key="key"
                  :disabled="newTaskSaving"
                  class="bg-vgmedturq mx-auto my-2 flex w-full justify-center rounded-full px-8 py-2 text-sm text-white lg:w-2/3 lg:text-base"
                  @click="handleCreateTask(task)"
                >
                  {{ task }}
                </button>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </v-dialog>
</template>
<script setup>
import { useUiStore } from '@/stores/ui';
import { useClientTaskStore } from '@/stores/clientTask';
import { usePpaClientStore } from '@/stores/ppaClient';
import tailwind from 'tailwind.config';
import { inject, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';

const uiStore = useUiStore();
const { showTemplatesDialog } = storeToRefs(uiStore);
const clientTaskStore = useClientTaskStore();
const ppaClientStore = usePpaClientStore();

const selectedType = ref('work');
const newTaskSaving = ref(false);

const vgMedTurq = tailwind.theme.extend.colors.vgmedturq[500];

const snackbar = inject('snackbar');
const router = useRouter();
const route = useRoute();

async function handleCreateTask(value) {
  try {
    newTaskSaving.value = true;

    let task;

    if (route.name === 'ppaClientWorkspace' || route.name === 'taskDetails') {
      ppaClientStore.selectedClient.newTaskTitle = value;
      task = await ppaClientStore.createTask();
    } else {
      clientTaskStore.newTaskTitle = value;
      task = await clientTaskStore.createTask();
    }

    handleClose();

    if (router.currentRoute.value.params.task_uuid) {
      router.replace('/tasks/' + task.uuid);
    } else {
      router.push('/tasks/' + task.uuid);
    }
  } catch (e) {
    snackbar.error('An error occurred while creating a new task');
  } finally {
    showTemplatesDialog.value = false;
    newTaskSaving.value = false;
  }
}
function handleClose() {
  showTemplatesDialog.value = false;
}

const templates = {
  work: {
    label: 'Work Tasks',
    types: [
      {
        type: 'Ramp up my Social Media Engagement',
        icon: 'social',
        tasks: [
          'Help me create a social media campaign',
          'Create a report for my social media channels',
          'Interact with my followers and handle their comments',
        ],
      },
      {
        type: 'Help me Stay on Top of My Finances',
        icon: 'finances',
        tasks: [
          'Help me with invoices & finance organization',
          'Track my expenses and keep them categorized',
          'Prepare some simple financial reports',
          'Update my customer database',
        ],
      },
      {
        type: 'Help Me Nail My Presentations',
        icon: 'presentations',
        tasks: [
          'Help me format my upcoming presentation',
          'Proofread and copy edit my presentation',
          'Set up some presentation templates for me',
        ],
      },
      {
        type: 'Help me Tame My Email Inbox',
        icon: 'email',
        tasks: [
          'Sort out my emails and keep everything organized',
          'Draft, send, and follow up on important messages',
          'Let me know about any urgent messages that need my attention',
        ],
      },
      {
        type: 'Manage My Calendar Without the Chaos',
        icon: 'calendar',
        tasks: [
          'Help schedule an appointment (doctor, salon, spa, etc.)',
          "Set reminders so I don't forget about deadlines or meetings",
          'Look over my calendar and free up some slots for appointments',
        ],
      },
    ],
  },

  personal: {
    label: 'Personal Tasks',
    types: [
      {
        type: 'Help Organize My Money',
        icon: 'finances',
        tasks: [
          'Help me find a better phone plan',
          'Help me identify and navigate government grants',
          'Compare and recommend insurance plans (health, car, travel)',
        ],
      },
      {
        type: 'Help Organize My Travels',
        icon: 'travels',
        tasks: [
          'Help me book my flights, hotels, and rental cars',
          'Confirm all my reservations and send me the details',
          'Create my travel itineraries and arrangements',
        ],
      },
      {
        type: 'Help Make Special Occasions Really Special',
        icon: 'occasions',
        tasks: [
          'Find some great birthday gift ideas',
          'Help me find some creative anniversary gifts and surprises',
          'Coordinate delivery of flowers, gifts, or surprises for loved ones',
        ],
      },
      {
        type: 'Help Me Plan My Personal Life',
        icon: 'personal',
        tasks: [
          'Book dinner reservations for me',
          'Help me schedule my appointments (doctor, salon, spa, etc.)',
          'Find and book personal services like housekeeping or lawn care',
        ],
      },
    ],
  },
};
</script>
<style scoped>
* :deep(.v-expansion-panel-title__overlay) {
  @apply shadow-none opacity-0 hidden;
}
</style>
