<template>
  <div class="flex w-full flex-row items-start">
    <label
      class="flex flex-row items-start transition duration-200 ease-in-out"
      :class="[showLabel ? 'gap-4' : '', { 'w-full': !editLocation }]"
    >
      <div
        v-if="showLabel"
        class="py-1"
        :class="{ 'flex gap-2 ': editLocation }"
      >
        <span class="text-left font-bold">Address:</span>
        <fa-icon
          v-if="required && editLocation"
          class="text-vgorange"
          icon="fa-regular fa-asterisk mr-2"
        />
      </div>
      <span
        v-if="!editLocation & !showAddressSlot"
        class="flex w-full items-start justify-between rounded-lg py-1 hover:cursor-pointer"
        :class="[showLabel ? 'bg-vgstone-50 px-2' : '']"
      >
        <span class="whitespace-pre-line text-base font-normal"
          >{{ fullAddressLabel }}
        </span>
        <Transition name="ease-in-out">
          <fa-icon
            v-if="!disabled"
            class="visible text-slate-700"
            icon="fa-regular fa-pencil"
            aria-label="Edit location"
            @click.stop="showEditLocation"
          />
        </Transition>
      </span>
      <slot v-if="!editLocation && showAddressSlot" name="label"></slot>
    </label>
    <div
      v-show="editLocation"
      :class="`mt-1 min-w-full w-[100%]' ${!showLabel ? 'ml-0' : 'ml-2'}`"
    >
      <v-expand-transition>
        <v-form
          ref="form"
          class="mb-4"
          validate-on="input"
          @submit.prevent="update"
        >
          <v-text-field
            :id="autocompleteFieldId"
            v-model.trim="fullAddress.address_line_1"
            placeholder="Address line 1"
            class="max-w-[30rem] text-sm"
            rounded="lg"
            :color="vgMedTurq"
            variant="outlined"
            density="compact"
            name="address_line_1"
            :rules="locationRequired"
            @blur="addressChanged()"
          />
          <v-text-field
            v-model.trim="fullAddress.address_line_2"
            placeholder="Address line 2"
            class="max-w-[30rem] text-sm"
            rounded="lg"
            :color="vgMedTurq"
            variant="outlined"
            density="compact"
            name="address_line_2"
            @blur="addressChanged()"
          />
          <div :class="{ 'flex gap-2': !showButtons }">
            <v-text-field
              v-model.trim="fullAddress.city"
              class="max-w-[14rem] text-sm"
              rounded="lg"
              placeholder="City"
              name="city"
              variant="outlined"
              density="compact"
              :color="vgMedTurq"
              :rules="locationRequired"
              @blur="addressChanged()"
            />
            <v-text-field
              :id="`${autocompleteFieldId}-province`"
              v-model.trim="fullAddress.state_prov"
              class="max-w-[10rem] text-sm"
              rounded="lg"
              name="state"
              placeholder="State/Province"
              variant="outlined"
              density="compact"
              :color="vgMedTurq"
              :rules="locationRequired"
              @keydown="edited.state_prov = true"
              @blur="addressChanged()"
            />
          </div>
          <div :class="{ 'flex gap-2': !showButtons }">
            <v-text-field
              v-model.trim="fullAddress.zipcode"
              name="zip"
              class="max-w-[8rem] text-sm"
              rounded="lg"
              placeholder="Postal code"
              variant="outlined"
              density="compact"
              :rules="locationRequired"
              :color="vgMedTurq"
              @blur="addressChanged()"
            />
            <v-text-field
              :id="`${autocompleteFieldId}-country`"
              v-model.trim="fullAddress.country"
              name="country"
              class="max-w-[8rem] text-sm"
              rounded="lg"
              placeholder="Country"
              variant="outlined"
              density="compact"
              :color="vgMedTurq"
              :rules="locationRequired"
              @keydown="edited.country = true"
              @blur="addressChanged()"
            />
          </div>
          <div
            v-if="showButtons"
            class="flex flex-col justify-end gap-4 text-xs md:flex-row"
          >
            <button
              class="border-vgmedturq text-vgmedturq h-8 rounded-full border border-solid px-6 uppercase tracking-wider"
              :disabled="loading"
              @click.prevent="hideLocation"
            >
              Cancel
            </button>
            <button
              class="bg-vgmedturq h-8 rounded-full px-6 uppercase tracking-wider text-white"
              :loading="loading"
              type="submit"
            >
              Update
            </button>
          </div>
        </v-form>
      </v-expand-transition>
    </div>
  </div>
</template>
<script>
import { useUserStore } from '@/stores/user';
import { nextTick } from 'vue';
import tailwind from 'tailwind.config';
import gmapsInit from '@/utils/gmaps';
import { getTimezone } from '@/services/userService';

export default {
  name: 'FullAddress',
  props: {
    address: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    showAddressSlot: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
    autocompleteFieldId: {
      type: String,
      default: 'autocomplete-field',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    restrictions: { type: Array, default: () => [] },
  },
  emits: ['dirty', 'hide', 'save'],
  data() {
    const userStore = useUserStore();

    return {
      userStore,
      autocomplete: { address: null, province: null, country: null },
      editLocation: false,
      edited: { state_prov: false, country: false },
      fullAddress: {},
      fullAddressLabel: '',
      data: {},
      dirty: false,
      googleReady: false,
      requiredRules: [(v) => !!v || 'Required'],
      vgMedTurq: tailwind.theme.extend.colors.vgmedturq.DEFAULT,
    };
  },
  computed: {
    locationRequired() {
      return this.required ? this.requiredRules : [];
    },
  },
  watch: {
    address(newValue, oldValue) {
      if (oldValue !== newValue) {
        nextTick(() => {
          this.fullAddress = JSON.parse(JSON.stringify(newValue));
        });
      }
    },
    edit: {
      immediate: true,
      handler(newVal) {
        this.editLocation = newVal;
      },
    },
    fullAddress: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.dirty = false;
          Object.keys(newVal).forEach((key) => {
            if (!this.dirty) {
              this.dirty =
                (this.address[key] || this.fullAddress[key]) &&
                this.address[key] !== this.fullAddress[key]
                  ? true
                  : false;
            }
          });

          this.fullAddressLabel = this.formatAddress(newVal);
        }
      },
    },
    dirty: {
      handler(newVal) {
        if (newVal === true) {
          this.$emit('dirty');
          this.dirty = false;
        }
      },
    },
    error(newValue) {
      if (newValue === true) {
        this.$root.$snackbar.error(
          'An error occurred, please try again later.'
        );
      }
    },
  },
  async mounted() {
    await gmapsInit().then(() => {
      this.googleReady = true;
    });
    const addressField = document.querySelector(`#${this.autocompleteFieldId}`);
    const provinceField = document.querySelector(
      `#${this.autocompleteFieldId}-province`
    );
    const countryField = document.querySelector(
      `#${this.autocompleteFieldId}-country`
    );
    const google = window.google;

    this.autocomplete.address = new google.maps.places.Autocomplete(
      addressField,
      {
        componentRestrictions: { country: this.restrictions },
        fields: ['address_components', 'geometry'],
        types: ['address'],
      }
    );
    this.autocomplete.province = new google.maps.places.Autocomplete(
      provinceField,
      {
        componentRestrictions: { country: this.restrictions },
        types: ['administrative_area_level_1'],
      }
    );
    this.autocomplete.country = new google.maps.places.Autocomplete(
      countryField,
      {
        componentRestrictions: { country: this.restrictions },
        fields: ['address_components'],
        types: ['country'],
      }
    );

    this.autocomplete.address.addListener('place_changed', async () => {
      await this.fillInAddress();
    });
    this.autocomplete.province.addListener('place_changed', async () => {
      await this.fillInProvince();
    });
    this.autocomplete.country.addListener('place_changed', () => {
      this.fillInCountry();
      this.edited.country = false;
    });
    this.fullAddress = JSON.parse(JSON.stringify(this.address));
  },
  methods: {
    showEditLocation() {
      if (!this.disabled) this.editLocation = true;
    },
    hideLocation() {
      this.dirty = false;
      this.editLocation = false;
      this.fullAddress = JSON.parse(JSON.stringify(this.address));
      this.resetAutocompleteStatus();

      this.$emit('hide');
    },
    resetAutocompleteStatus() {
      this.edited.state_prov = false;
      this.edited.country = false;
    },
    async fillInAddress() {
      // Get the place details from the autocomplete object.
      const place = this.autocomplete.address.getPlace();

      if (!place.address_components) {
        return;
      }

      let address = '';
      let postcode = '';

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http://goo.gle/3l5i5Mr
      for (const component of place.address_components) {
        // @ts-ignore remove once typings fixed
        const componentType = component.types[0];

        switch (componentType) {
          case 'subpremise': {
            address = `${component.long_name} ${address}`;
            break;
          }
          case 'street_number': {
            address += `${component.long_name} ${address}`;
            break;
          }
          case 'route': {
            address += component.short_name;
            break;
          }
          case 'postal_code': {
            postcode = `${component.long_name}${postcode}`;
            break;
          }
          case 'postal_code_suffix': {
            postcode = `${postcode}-${component.long_name}`;
            break;
          }
          case 'locality': {
            this.fullAddress.city = component.long_name;
            break;
          }
          case 'administrative_area_level_1': {
            this.fullAddress.state_prov = component.long_name;
            break;
          }
          case 'country': {
            this.fullAddress.country = component.long_name;
            break;
          }
        }
      }

      this.fullAddress.address_line_1 = address;
      this.fullAddress.zipcode = postcode;
      await this.getTimezone(place);
      this.resetAutocompleteStatus();
      await this.addressChanged();
    },
    async fillInCountry() {
      const place = this.autocomplete.country.getPlace();

      for (const component of place.address_components) {
        if (component.types.includes('country')) {
          this.fullAddress.country = component.long_name;
        }
      }

      this.edited.country = false;
      await this.addressChanged();
    },
    async fillInProvince() {
      const place = this.autocomplete.province.getPlace();

      for (const component of place.address_components) {
        if (component.types.includes('administrative_area_level_1')) {
          this.fullAddress.state_prov = component.long_name;
        } else if (component.types.includes('country')) {
          this.fullAddress.country = component.long_name;
        }
      }

      this.resetAutocompleteStatus();
      await this.getTimezone(place);
      await this.addressChanged();
    },
    async addressChanged() {
      if (!this.showButtons && this.$refs.form) {
        await this.update();
      }
    },
    async getTimezone(place) {
      await getTimezone(
        place.geometry.location.lat(),
        place.geometry.location.lng()
      )
        .then((result) => {
          this.data['timezone'] = result.timezone;
          this.data['timezone_offset'] = result.timezone_offset;
          this.data['latitude'] = place.geometry.location.lat();
          this.data['longitude'] = place.geometry.location.lng();
        })
        .catch(() => {
          return this.$root.$snackbar.error(
            'Could not retrieve timezone and timezone offset'
          );
        });
    },
    formatAddress(address) {
      const addressLabel =
        (address.address_line_1 ? address.address_line_1 + '\n' : '') +
        (address.address_line_2 ? address.address_line_2 + '\n' : '') +
        (address.city ? address.city + ', ' : '') +
        (address.state_prov ? address.state_prov + ', ' : ' ') +
        (address.zipcode ? address.zipcode + '\n' : ' ') +
        (address.country ? address.country : '');

      return addressLabel?.trim().length > 0 ? addressLabel.trim() : '-';
    },
    async update() {
      this.data.error = null;
      this.data.valid = (await this.$refs.form.validate()).valid;

      if (!this.data.valid) {
        this.$emit('save', this.data);
        return;
      }

      if (
        this.fullAddressLabel === this.formatAddress(this.address) &&
        this.data.valid
      ) {
        // If address hasn't changed from original value
        this.editLocation = false;
      } else if (
        (this.edited.state_prov && this.fullAddress.state_prov.length > 0) ||
        (this.edited.country && this.fullAddress.country.length > 0)
      ) {
        // If country and province hasn't been selected from autocomplete
        this.data.valid = false;
        this.data.error =
          'Please select state/province and/or country from autocomplete options';
      } else if (this.dirty === true) {
        this.data.valid = false;
      }

      this.data['address'] = this.fullAddress;
      this.$emit('save', this.data);

      if (this.showButtons && this.data.valid) {
        this.editLocation = false;
      }
    },
  },
};
</script>
