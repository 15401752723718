<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    persistent
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-form @submit.prevent="ok">
      <v-card>
        <v-card-title
          ><h3>{{ title }}</h3></v-card-title
        >
        <v-card-text
          v-show="!!message"
          v-dompurify-html="message"
          class="pa-4"
        ></v-card-text>
        <div v-if="!!options.requiredEntry" class="px-4 pb-0 pt-4">
          <v-text-field
            v-model="entry"
            variant="outlined"
            maxlength="50"
            required
          ></v-text-field>
        </div>
        <div class="flex justify-end p-4">
          <button type="button" class="mr-4" @click="cancel">
            {{ options.cancelText }}
          </button>
          <button
            class="bg-vgmedturq rounded-lg p-2 px-6 text-white disabled:opacity-50"
            :disabled="entry !== options.requiredEntry"
            type="submit"
          >
            {{ options.okText }}
          </button>
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
const defaultOptions = {
  color: 'red',
  width: 500,
  zIndex: 200,
  requiredEntry: null,
  showButtons: true,
  okText: 'OK',
  cancelText: 'Cancel',
};

export default {
  name: 'ConfirmDialog',
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    message: null,
    entry: null,
    options: defaultOptions,
  }),
  methods: {
    open(title, message, options) {
      this.title = title;
      this.message = message;
      this.entry = null;
      this.options = Object.assign({ ...defaultOptions }, options);
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    ok() {
      if (this.entry !== this.options.requiredEntry) {
        return;
      }
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
