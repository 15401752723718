<template>
  <v-alert
    v-model="dialog"
    class="absolute inset-x-0 top-0 h-0 overflow-visible p-0"
  >
    <div
      class="border-vgmedturq mt-[-1px] flex items-center gap-4 rounded-b-lg border border-solid bg-white p-4 shadow-lg"
    >
      <p v-show="!!title" class="text-vgnavy text-xl font-semibold">
        {{ title }}
      </p>
      <p class="font-semibold">
        {{ message }}
      </p>
    </div>
  </v-alert>
</template>

<script setup>
import { ref } from 'vue';

let dialog = ref(false);
let message = ref();
let title = ref();

let timeout;
let defaultOptions = { timeout: 5000 };
let options = { ...defaultOptions };

function open(newTitle, newMessage, newOptions) {
  dialog.value = true;
  title.value = newTitle;
  message.value = newMessage;
  options = Object.assign({}, defaultOptions, newOptions);

  if (timeout) {
    clearTimeout(timeout);
  }

  timeout = setTimeout(() => {
    dialog.value = false;
  }, options.timeout);
}

defineExpose({ open });
</script>
<style scoped>
* :deep(.v-alert__content) {
  @apply w-fit;
  @apply min-w-[50%];
  @apply max-w-[80%];
  @apply mx-auto;
  @apply overflow-visible;
}
</style>
