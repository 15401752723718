<template>
  <div
    class="min-h-screen"
    style="
      background-image: url('/img/login/early_access_bg.png');
      background-repeat: no-repeat;
      background-size: cover;
    "
  >
    <header class="flex items-center gap-4 border border-b-slate-300 p-2">
      <a href="/"
        ><img src="/img/VG-OnDemand-logo-horizontal.svg" class="max-h-14 p-2"
      /></a>
    </header>
    <div class="grid grid-cols-1 gap-8 px-20 pt-16 lg:grid-cols-2">
      <div style="max-width: 600px; margin: 0 auto" class="tracking-wide">
        <h1 class="mb-8 text-3xl font-bold">
          Welcome to the
          <span
            class="border-vgorange box-border inline-block border-b-4 pb-0 leading-5"
            >VG OnDemand Queue!</span
          >
        </h1>
        <p class="mb-4">
          Thanks for your interest! We've currently reached our user capacity,
          but here's the good news:
        </p>
        <div class="my-6 ml-6">
          <ul class="list-disc">
            <li>Your free hour is reserved and waiting.</li>
            <li>You're on our Early Access waitlist.</li>
            <li>We'll notify you ASAP when we're ready for you to dive in.</li>
          </ul>
        </div>
        <p class="my-6">
          Stay tuned for your exclusive invite and updates from VG OnDemand.
        </p>
        <div>
          <span class="font-semibold">Note:</span> Keep an eye on your inbox!
          <div class="mt-6">Cheers, The VG OnDemand Team</div>
        </div>
      </div>
      <div class="mx-auto flex min-h-[400px] max-w-xs lg:mx-0">
        <img
          src="/img/login/early_access_hero-img.png"
          aria-hidden="true"
          class="max-h-[25rem]"
        />
      </div>
    </div>
  </div>
</template>
