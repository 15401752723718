import { getJSON, postJSON } from '@/services/httpService';

export const getById = (id) => {
  return getJSON(`/virtualassistants/${id}`);
};

export const getVASelfById = () => {
  return getJSON(`/virtualassistants/self`);
};

export const getVAConfig = () => getJSON(`/virtualassistants/config`);

export const getFullVAById = (id) =>
  getJSON(`/virtualassistants/${id}?full=true`);

export const onboard = (id) => {
  return postJSON(`/virtualassistants/${id}/onboard`);
};
