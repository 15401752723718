<template>
  <div v-if="uiStore.uiReady" data-app>
    <AppHeader v-if="uiStore.tabletOrSmaller" />
    <NavDrawer v-if="userStore.isOnDemandUser && !route.meta.hideSidebar" />
    <div
      :class="`bg-vgsilver-100 flex flex-col ${
        userStore.isOnDemandUser && !route.meta.hideSidebar ? 'lg:pl-32' : ''
      } ${
        uiStore.navDrawerOpen || uiStore.chatOverlayOpen
          ? 'h-0 overflow-hidden'
          : ''
      }`"
    >
      <main class="flex-auto">
        <router-view v-if="userStore.user || !route.meta.requiresAuth" />
      </main>
    </div>
    <NewVersionPopup />
  </div>
  <div v-else>
    <v-skeleton-loader
      class="mx-auto mt-8 max-w-[80%]"
      type="article"
    ></v-skeleton-loader>
  </div>
  <InfoDialog ref="infoDialog"></InfoDialog>
  <ConfirmDialog ref="confirm"></ConfirmDialog>
  <Snackbar ref="snackbar"></Snackbar>
</template>
<script>
import AppHeader from '@/components/AppHeader.vue';
import NavDrawer from '@/components/NavDrawer.vue';
import InfoDialog from '@/dialogs/InfoDialog.vue';
import ConfirmDialog from '@/dialogs/ConfirmDialog.vue';
import Snackbar from '@/components/Snackbar.vue';
import NewVersionPopup from '@/components/NewVersionPopup.vue';
import { provide } from 'vue';
import { mapStores } from 'pinia';
import { useUiStore } from '@/stores/ui';
import { useUserStore } from '@/stores/user';
import { getSessionError } from '@/services/clientSessionService';
import { useRoute } from 'vue-router';

// Tailwindcss classes that must be kept by purgecss (do not delete):
// v-ripple__animation v-btn__overlay v-snack__content rounded-3xl v-alert__icon v-icon ql-snow
// text-slate-700 bg-slate-700 text-vgmedturq bg-vgteal text-vgorange bg-vgorange text-vgnavy bg-vgnavy text-[#E13661] bg-[#E13661]

export default {
  name: 'App',
  components: {
    AppHeader,
    NavDrawer,
    InfoDialog,
    ConfirmDialog,
    Snackbar,
    NewVersionPopup,
  },
  data() {
    const route = useRoute();
    return { route };
  },
  computed: {
    ...mapStores(useUiStore, useUserStore),
  },
  mounted() {
    this.$root.$infoDialog = this.$refs.infoDialog;
    this.$root.$confirm = this.$refs.confirm;
    this.$root.$snackbar = this.$refs.snackbar;
    provide('snackbar', this.$refs.snackbar);
    provide('confirm', this.$refs.confirm);

    const sessionError = getSessionError();

    if (sessionError) {
      this.$refs.snackbar.error(sessionError, { persist: true });
    }
  },
};
</script>
