import {
  getJSON,
  putJSON,
  postJSON,
  deleteAction,
} from '@/services/httpService';

export const getTasks = (filters = []) => {
  return getJSON(`/askbetty/v2/task?pagination.limit=100&${filters.join('&')}`);
};

export const getTask = (id) => {
  return getJSON(`/askbetty/v2/task/${id}`);
};

export const saveTask = (task) =>
  task.uuid
    ? putJSON(`/askbetty/v2/task/${task.uuid}`, task)
    : postJSON(`/askbetty/v2/task`, task);

export const deleteTask = (uuid) => deleteAction(`/askbetty/v2/task/${uuid}`);

export const markTaskRead = (uuid) =>
  postJSON(`/askbetty/v2/task/${uuid}/read`);

export const computeTaskDetails = (task, isPPA, isClient) => {
  const completedSubtasks = task.subtasks?.filter(
    (subtask) => subtask.status === 'complete'
  );
  const invitedBettys = task.bettys?.filter(
    (betty) => betty.relationship_type === 'invited'
  );
  const assignedBetty = task.bettys?.find(
    (betty) => betty.relationship_type === 'assigned'
  );
  let taskStatus = task.status;
  if (isPPA) {
    if (task.status === 'new') {
      taskStatus = 'draft';
    } else if (task.bettys?.length === 0) {
      taskStatus = 'unassigned';
    } else if (!assignedBetty) {
      taskStatus = 'invites sent';
    } else if (task.status === 'active') {
      taskStatus = 'in progress';
    } else if (task.status === 'ppa to client review') {
      taskStatus = 'pending review';
    } else if (task.status === 'ppa to betty review') {
      taskStatus = 'change request';
    }
  } else if (isClient) {
    if (task.status === 'new') {
      taskStatus = 'draft';
    } else if (
      task.status === 'active' ||
      task.status === 'ppa to client review' ||
      task.status === 'ppa to betty review'
    ) {
      taskStatus = 'in progress';
    } else if (task.status === 'client review') {
      taskStatus = 'review ready';
    } else if (task.status === 'complete') {
      taskStatus = 'task done';
    }
  } else if (task.relationship_type === 'invited') {
    taskStatus = 'new invite';
  } else if (task.status === 'active') {
    taskStatus = 'in progress';
  } else if (task.status === 'ppa to client review') {
    taskStatus = 'pending review';
  } else if (task.status === 'ppa to betty review') {
    taskStatus = 'pending review';
  }

  let taskStatusBgColor = 'bg-slate-700';
  if (taskStatus === 'in progress') {
    taskStatusBgColor = 'bg-vgteal';
  } else if (taskStatus === 'blocked' || taskStatus === 'change request') {
    taskStatusBgColor = 'bg-[#E13661]';
  } else if (taskStatus === 'invites sent' || taskStatus === 'pending review') {
    taskStatusBgColor = 'bg-vgorange';
  } else if (taskStatus === 'complete') {
    taskStatusBgColor = 'bg-vgnavy';
  }
  let taskStatusTextColor = taskStatusBgColor.replace('bg-', 'text-');

  let taskStatusSummary = '';

  if (taskStatus === 'in progress' && !isPPA) {
    taskStatusSummary = 'You accepted this task!';
  } else if (taskStatus === 'pending review' && !isPPA) {
    taskStatusSummary = 'You submitted this task for review';
  } else if (taskStatus === 'change request') {
    taskStatusSummary = `Client has requested changes to this task`;
  } else if (taskStatus === 'new invite') {
    taskStatusSummary = `You have a new invite from ${task.ppa_known_as}`;
  }

  return {
    completedSubtasks,
    invitedBettys,
    assignedBetty,
    taskStatus,
    taskStatusBgColor,
    taskStatusTextColor,
    taskStatusSummary,
  };
};

export const getHandOffDate = (task_uuid) => {
  return getJSON(`/askbetty/v2/task/${task_uuid}/handoff`);
};
