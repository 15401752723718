import { getJSON, postJSON } from '@/services/httpService';

export const getTransactions = () => getJSON(`/askbetty/v2/transaction/`);

export const getTransaction = (id) => getJSON(`/askbetty/v2/transaction/${id}`);

export const getPrice = (minutes) =>
  getJSON(`/askbetty/v2/transaction/price?minutes=${minutes}`);
export const getPricesDescription = () =>
  getJSON(`/askbetty/v2/transaction/prices`);

export const createPurchase = (transaction) =>
  postJSON(`/askbetty/v2/transaction/purchase`, transaction);
