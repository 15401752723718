<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    persistent
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <div class="rounded-2xl bg-white p-8">
      <div
        v-if="!user?.permissions.includes('askbetty_client')"
        class="flex flex-col items-center gap-4 p-4 text-center"
      >
        <p class="text-vgnavy pb-2 text-xl font-semibold">Minutes Required</p>
        <p class="text-vgnavy text-base">
          The client does not have enough minutes to start a new task. Please
          ask the user to go to
          <span class="whitespace-nowrap">{{ ASKBETTY_APP_URL }}/billing</span>
          to add more minutes.
        </p>
        <button
          class="bg-vgmedturq mt-4 rounded-full p-2 px-6 font-semibold uppercase text-white disabled:opacity-50"
          @click="cancel"
        >
          Ok
        </button>
      </div>
      <v-form v-else @submit.prevent="ok">
        <div
          v-if="stage === 'start'"
          class="flex flex-col items-center gap-4 p-4 text-center"
        >
          <p class="text-vgnavy pb-2 text-2xl font-semibold">Add time</p>
          <p class="text-vgnavy text-base">
            Need more time? No problem! Continue to choose how much time you
            would like to add to your account:
          </p>

          <div class="flex flex-col items-center">
            <p>Current account balance</p>
            <div class="text-vgmedturq mt-1 text-xl font-semibold">
              {{
                formatDurationShort(userStore.client?.client?.minutes_balance)
              }}
            </div>
          </div>

          <div class="flex flex-col gap-4">
            <button
              class="bg-vgmedturq mt-4 min-w-[80%] rounded-full p-2 px-16 text-base font-semibold uppercase text-white disabled:opacity-50"
              type="submit"
            >
              <v-progress-circular
                v-if="loading"
                :size="25"
                indeterminate
              ></v-progress-circular>
              <span v-else>Continue</span>
            </button>
            <button class="uppercase text-slate-500" @click="cancel">
              Cancel
            </button>
          </div>
          <p class="mt-2 text-center text-sm">
            You can always find your balance in your
            <a class="text-vgorange" href="/billing">billing history</a>
          </p>
        </div>
        <div
          v-else-if="stage === 'purchase'"
          class="mx-auto flex flex-col items-center gap-4 p-4"
        >
          <p class="text-vgnavy pb-2 text-2xl font-semibold">Purchase Hours</p>
          <div class="flex w-full flex-col items-center sm:flex-row sm:gap-4">
            <div class="w-1/3">
              <label>
                <p class="sr-only">Enter hours</p>
                <div class="flex items-start justify-end gap-2">
                  <v-text-field
                    v-model="hours"
                    :color="vgMedTurq"
                    rounded
                    density="compact"
                    type="number"
                    variant="outlined"
                    class="w-[6rem]"
                    :rules="requiredRules"
                  />
                  <div class="mt-2 text-base">
                    {{ hours <= 1 ? 'hour' : 'hours' }}
                  </div>
                </div>
              </label>
            </div>
            <div
              class="flex w-2/3 flex-col items-start border-solid border-slate-300 sm:border-l sm:pl-4"
            >
              <p>Total due:</p>
              <div class="text-vgmedturq mt-1 text-xl font-semibold">
                <v-progress-circular
                  v-if="priceLoading"
                  :size="25"
                  indeterminate
                ></v-progress-circular>
                <div v-else class="flex flex-col items-start gap-2">
                  <span>
                    {{
                      formatCurrency(
                        price?.cents_amount_with_tax / 100,
                        userStore.user?.currency
                      )
                    }}
                  </span>
                  <div class="text-base font-normal text-black">
                    Unit cost:
                    {{
                      formatCurrency(
                        price?.cents_unit_cost / 100,
                        userStore.user?.currency
                      )
                    }}/hr
                    <v-tooltip location="top">
                      <template #activator="{ props }">
                        <fa-icon
                          icon="fa-regular fa-question-circle"
                          class="text-slate-500"
                          v-bind="props"
                        />
                      </template>
                      <div class="py-2">
                        <div class="grid grid-cols-2 gap-1">
                          <div class="mr-10 font-semibold">Hours</div>
                          <div class="font-semibold">Price</div>
                        </div>
                        <div
                          v-for="(h, i) in prices"
                          :key="i"
                          class="grid grid-cols-2 gap-1"
                        >
                          <div>{{ h.condition }}</div>
                          <div>${{ h.price.toFixed(2) }}/hr</div>
                        </div>
                      </div>
                    </v-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-4">
            <button
              :disabled="!hours || hours < 1"
              class="bg-vgmedturq mt-4 min-w-[80%] rounded-full p-2 px-6 text-base font-semibold uppercase text-white disabled:opacity-50"
              type="submit"
            >
              <v-progress-circular
                v-if="loading"
                :size="25"
                indeterminate
              ></v-progress-circular>
              <span v-else>Confirm & process payment</span>
            </button>
            <button class="uppercase text-slate-500" @click.prevent="cancel">
              Cancel
            </button>
          </div>
        </div>
        <div
          v-else-if="stage === 'success'"
          class="flex flex-col items-center gap-4 p-4 text-center"
        >
          <p class="text-vgnavy text-2xl font-semibold">Success!</p>
          <p class="text-base">
            Time has been successfully added to your balance.
          </p>

          <fa-icon
            icon="fa-regular fa-check"
            class="text-vgtertiarydarkblue border-vgtertiarydarkblue h-16 w-16 rounded-full border-4 border-solid text-4xl font-bold"
            style="line-height: 1.6"
          />
          <button
            class="bg-vgmedturq mt-4 rounded-full p-2 px-8 font-semibold uppercase text-white disabled:opacity-50"
            type="submit"
          >
            Close
          </button>
        </div>

        <div
          v-else-if="stage === 'error'"
          class="flex flex-col items-center gap-4 p-4 text-center"
        >
          <p class="text-vgorange text-2xl font-semibold">Oops!</p>
          <p class="text-base">
            It looks like we couldn't process your payment at this time.
          </p>
          <button
            class="bg-vgmedturq mt-4 rounded-full p-2 px-8 font-semibold uppercase text-white disabled:opacity-50"
            type="submit"
          >
            Try Again
          </button>
          <button class="uppercase text-slate-500" @click="cancel">
            Cancel
          </button>
        </div>
      </v-form>
    </div>
  </v-dialog>
</template>

<script>
import {
  createPurchase,
  getPrice,
  getPricesDescription,
} from '@/services/transactionService';
import {
  formatCurrency,
  formatDurationShort,
} from '@/services/formattingService';
import { ASKBETTY_APP_URL } from '@/config';
import { mapStores } from 'pinia';
import { useUserStore } from '@/stores/user';
import tailwind from 'tailwind.config';
const defaultOptions = {
  width: 500,
  zIndex: 200,
};

export default {
  name: 'PurchaseMinutesDialog',
  data: () => ({
    ASKBETTY_APP_URL,
    dialog: false,
    resolve: null,
    reject: null,
    user: null,
    stage: 'start',
    hours: 0,
    price: null,
    options: defaultOptions,
    complete: false,
    loading: false,
    priceLoading: false,
    prices: null,
    requiredRules: [(v) => (!!v && v >= 1) || 'Must be at least 1 hour'],
    vgMedTurq: tailwind.theme.extend.colors.vgmedturq[500],
  }),
  computed: {
    ...mapStores(useUserStore),
  },
  watch: {
    async hours() {
      this.priceLoading = true;
      try {
        this.price = null;
        this.price = await getPrice(this.hours * 60);
      } catch (error) {
        return this.$root.$snackbar.error('Error getting price');
      } finally {
        this.priceLoading = false;
      }
    },
  },
  methods: {
    formatCurrency,
    formatDurationShort,
    async open(user) {
      this.user = user;
      this.dialog = true;
      this.stage = 'start';
      this.hours = 1;
      await getPricesDescription().then((prices) => {
        this.prices = prices;
      });
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    ok() {
      if (this.stage === 'start') {
        this.stage = 'purchase';
        return;
      }
      if (this.stage === 'purchase') {
        return this.handlePurchase();
      }
      if (this.stage === 'error') {
        this.stage = 'purchase';
        return;
      }
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
    async handlePurchase() {
      this.loading = true;

      try {
        await createPurchase({
          minutes: this.hours * 60,
          cents_amount_with_tax: this.price.cents_amount_with_tax,
        });
        this.stage = 'success';
      } catch (error) {
        this.stage = 'error';
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
