<template>
  <div>
    <ClientTasks
      v-if="userStore.user.permissions.includes('askbetty_client')"
    />
    <PpaTasks v-else-if="userStore.user.permissions.includes('askbetty_ppa')" />
    <BettyTasks
      v-else-if="userStore.user.permissions.includes('askbetty_va')"
    />
  </div>
</template>
<script>
import BettyTasks from '@/components/BettyTasks.vue';
import ClientTasks from '@/components/ClientTasks.vue';
import PpaTasks from '@/components/PpaTasks.vue';
import { mapStores } from 'pinia';
import { useUserStore } from '@/stores/user';

export default {
  name: 'TasksWrapper',
  components: {
    BettyTasks,
    ClientTasks,
    PpaTasks,
  },
  computed: {
    ...mapStores(useUserStore),
  },
};
</script>
