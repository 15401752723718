import { getSocket } from '@/services/clientSessionService';
import { getTasks, saveTask } from '@/services/taskService';
import { defineStore } from 'pinia';

const welcomeMessages = [
  {
    title: 'Welcome',
    subtitle: 'We’ve got your back! Add your tasks and watch the magic happen.',
  },
  {
    title: 'Hey there',
    subtitle:
      'Ready to lighten your load? Just add a task and let us handle the rest!',
  },
  {
    title: 'Hello',
    subtitle: 'Ready to juggle less? Toss us your tasks and enjoy the show!',
  },
  {
    title: 'Hi there',
    subtitle: 'Task overload? Let us take the wheel - just add and relax!',
  },
];
const message =
  welcomeMessages[Math.floor(Math.random() * welcomeMessages.length)];

export const useClientTaskStore = defineStore('clientTask', {
  state: () => ({
    tasks: [],
    rowCount: 0,
    tab: 'new',
    newTaskTitle: '',
    newTaskLoading: false,
    welcomeTitle: message.title,
    welcomeSubtitle: message.subtitle,
  }),
  getters: {
    allTasks() {
      return this.tasks;
    },
    newTasks() {
      return this.tasks.filter((task) => task.status === 'new');
    },
    activeTasks() {
      return this.tasks.filter(
        (task) =>
          task.status === 'active' ||
          task.status === 'ppa to client review' ||
          task.status === 'ppa to betty review'
      );
    },
    activeTasksNotificationCount() {
      return this.activeTasks.reduce(
        (accumulator, task) => accumulator + (task.unread ? 1 : 0),
        0
      );
    },
    completeTasks() {
      return this.tasks.filter((task) => task.status === 'complete');
    },
    completeTasksNotificationCount() {
      return this.completeTasks.reduce(
        (accumulator, task) => accumulator + (task.unread ? 1 : 0),
        0
      );
    },
    reviewTasks() {
      return this.tasks.filter((task) => task.status === 'client review');
    },
    reviewTasksNotificationCount() {
      return this.reviewTasks.reduce(
        (accumulator, task) => accumulator + (task.unread ? 1 : 0),
        0
      );
    },
    selectedTasks() {
      switch (this.tab) {
        case 'new':
          return this.newTasks;
        case 'active':
          return this.activeTasks;
        case 'complete':
          return this.completeTasks;
        case 'review':
          return this.reviewTasks;
        default:
          return [];
      }
    },
    organizedTasks() {
      return {
        new: this.newTasks,
        active: this.activeTasks,
        review: this.reviewTasks,
        complete: this.completeTasks,
      };
    },
  },
  actions: {
    async loadTasks() {
      const taskUpdatedListener = await (async () => {
        const result = await getTasks().catch(() => ({
          tasks: [],
          rowCount: 0,
        }));
        this.tasks = result.tasks;
      });
      if (
        !getSocket()
          .listeners('askbetty.task_updated')
          ?.some((f) => f.name === 'taskUpdatedListener')
      ) {
        getSocket().on('askbetty.task_updated', taskUpdatedListener);
      }

      return taskUpdatedListener();
    },
    async createTask() {
      this.newTaskLoading = true;
      let result = null;
      try {
        result = await saveTask({
          title: this.newTaskTitle,
        });
        this.tasks.unshift(result);
        this.rowCount++;
      } finally {
        this.newTaskLoading = false;
      }

      this.newTaskTitle = '';
      return result;
    },
    async updateTask(task) {
      const result = await saveTask(task);
      const index = this.tasks.findIndex((task) => task.uuid === result.uuid);
      if (index > -1) {
        this.tasks[index] = result;
        this.tasks = [...this.tasks];
      } else {
        this.tasks.push(result);
      }
    },
  },
});
