import {
  hasSession,
  saveSession,
  loadSession,
  endSession,
  getSocket,
} from '@/services/clientSessionService';
import { getVASelfById } from '@/services/virtualAssistantsService';
import { getClientSelfById, getClientConfig } from '@/services/clientService';
import {
  getUserConfig,
  getPreferences,
  setPreferences,
} from '@/services/userService';
import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
  state: () => ({
    virtualassistant: undefined,
    clientConfig: undefined,
    client: undefined,
    userConfig: undefined,
    user: undefined,
    preferences: undefined,
  }),
  getters: {
    homeLink() {
      return this.user?.permissions.includes('askbetty_ppa')
        ? '/clients'
        : '/tasks';
    },
    getClient() {
      return this.client?.client;
    },
    isOnDemandUser() {
      return (
        this.user?.permissions.includes('askbetty_client') ||
        this.user?.permissions.includes('askbetty_va') ||
        this.user?.permissions.includes('askbetty_ppa')
      );
    },
  },
  actions: {
    async initUser(payload) {
      if (hasSession()) {
        const userClientUpdatedListener = async () => {
          if (
            this.user.permissions.includes('client') ||
            this.user.permissions.includes('askbetty_client')
          ) {
            this.client = await getClientSelfById().catch(() => {});
          }
        };
        if (
          !getSocket()
            .listeners('askbetty.client_updated')
            ?.some((f) => f.name === 'userClientUpdatedListener')
        ) {
          getSocket().on('askbetty.client_updated', userClientUpdatedListener);
        }
        if (
          (!this.virtualassistant || payload?.forceReload) &&
          this.user.permissions.includes('virtualassistant')
        ) {
          const vaResult = await getVASelfById().catch(() => {});

          this.virtualassistant = vaResult;
        }
        if (
          (!this.client || payload?.forceReload) &&
          (this.user.permissions.includes('client') ||
            this.user.permissions.includes('askbetty_client'))
        ) {
          this.client = await getClientSelfById().catch(() => {});
        }
        if (!this.userConfig || payload?.forceReload) {
          this.userConfig = await getUserConfig().catch(() => {});
        }
        if (!this.preferences || payload?.forceReload) {
          this.preferences = await getPreferences().catch(() => {});
        }
      }
    },
    async setPreferences(payload) {
      this.preferences = await setPreferences(payload).then(
        (response) => response.data
      );
    },
    async loadSession() {
      const session = await loadSession();
      this.user = session.user;
    },

    saveSession(payload) {
      const session = saveSession(payload.token, payload.user);
      this.user = session.user;
    },
    async endSession() {
      await endSession();
      this.user = undefined;
    },
    async initClientConfig() {
      if (!this.clientConfig) {
        this.clientConfig = await getClientConfig().catch(() => ({}));
      }
    },
  },
});
