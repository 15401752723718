<template>
  <NotificationBadge
    :dot="!!task.unread"
    class="ring-vgmedturq my-4 grid w-full flex-none cursor-pointer grid-flow-row rounded-lg border border-solid border-slate-200 bg-white p-4 text-left shadow ring-offset-1 hover:border-white hover:ring-1 xl:grid-cols-12"
    role="row"
    @click="$router.push(`/tasks/${task.uuid}`)"
  >
    <div
      class="order-1 col-span-11 flex flex-col gap-2 place-self-start md:col-span-6"
      role="cell"
    >
      <div
        class="w-24 rounded-full text-center text-sm font-semibold uppercase text-white"
        :class="computedTaskDetails.taskStatusBgColor"
      >
        {{ computedTaskDetails.taskStatus }}
      </div>
      <router-link :to="`/tasks/${task.uuid}`" class="text-sm font-semibold"
        >Task: {{ task.title }}</router-link
      >
      <div class="flex gap-4 text-slate-600">
        <fa-icon icon="fa-regular fa-list-tree" class="text-lg" />
        {{ computedTaskDetails.completedSubtasks?.length ?? 0 }} /
        {{ task.subtasks?.length ?? 0 }}
        <fa-icon icon="fa-regular fa-link" class="text-lg" />
        {{ task.attachmentCount ?? 0 }}
      </div>
    </div>
    <router-link
      v-if="task.status === 'new'"
      class="text-vgmedturq order-3 col-span-5 place-self-start self-center py-4 text-sm font-semibold uppercase md:order-2"
      role="cell"
      :to="`/tasks/${task.uuid}`"
    >
      <fa-icon icon="fa-regular fa-plus" class="text-base" />
      Add Task Details
    </router-link>
    <button
      v-else-if="computedTaskDetails.taskStatus === 'unassigned'"
      class="text-vgmedturq order-3 col-span-5 flex flex-row items-center place-self-start self-center rounded-full py-4 text-start text-sm font-semibold uppercase"
      @click.stop="$router.push(`/tasks/${task.uuid}/invite`)"
    >
      <fa-icon icon="fa-regular fa-user-plus" class="mr-2 text-base" />
      <div>Invite Assistant(s) to task</div>
    </button>
    <div
      v-else-if="computedTaskDetails.taskStatus === 'invites sent'"
      class="order-3 col-span-5 flex flex-col gap-2 place-self-start self-center text-sm md:order-2"
    >
      <div class="text-vgorange">awaiting invite response</div>
      <div class="text-slate-600">
        ({{ computedTaskDetails.invitedBettys.length }}) invite{{
          computedTaskDetails.invitedBettys.length > 1 ? 's' : ''
        }}
        sent
      </div>
    </div>
    <div
      v-else-if="computedTaskDetails.assignedBetty"
      class="order-3 col-span-5 flex flex-col items-start justify-between gap-2 self-center text-base md:order-2 lg:flex-row lg:items-center lg:pl-0"
    >
      <div class="flex items-center">
        <v-avatar size="42">
          <img
            :src="
              computedTaskDetails.assignedBetty.avatar_id
                ? `${API_URL}/file/avatar/${computedTaskDetails.assignedBetty.avatar_id}`
                : '/img/img-quote_avatar.png'
            "
            alt="avatar"
          />
        </v-avatar>
        <div class="whitespace-nowrap pl-2 text-sm">
          {{
            computedTaskDetails.assignedBetty.display_name ||
            computedTaskDetails.assignedBetty.known_as
          }}
        </div>
      </div>
      <NotificationBadge :count="task.unread_count">
        <button
          class="text-vgmedturq border-vgmedturq rounded-md border border-solid p-1 text-sm font-semibold uppercase"
          @click.stop="$router.push(`/tasks/${task.uuid}/collaborate`)"
        >
          Chat with Assistant
        </button>
      </NotificationBadge>
      <div
        class="flex min-w-[4rem] justify-end whitespace-nowrap text-sm font-semibold"
      >
        {{
          task.time_logged_minutes
            ? formatDurationShort(
                task.time_logged_minutes + task.ongoing_time_entry
              )
            : '&nbsp;'
        }}
      </div>
    </div>
    <div
      v-if="task.status === 'new'"
      class="order-2 col-span-1 place-self-end self-start text-slate-500 md:order-3"
      role="cell"
    >
      <fa-icon icon="fa-regular fa-ellipsis" class="text-lg" />
    </div>
  </NotificationBadge>
</template>

<script setup>
import { API_URL } from '@/config';
import { computed } from 'vue';
import { computeTaskDetails } from '@/services/taskService';
import { formatDurationShort } from '@/services/formattingService';
import NotificationBadge from '@/components/NotificationBadge.vue';

const props = defineProps({
  task: {
    type: Object,
    default() {
      return {};
    },
  },
  client: {
    type: Object,
    default() {
      return {};
    },
  },
});

const computedTaskDetails = computed(() => {
  return computeTaskDetails(props.task, true);
});
</script>
