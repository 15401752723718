<template>
  <div class="flex h-full shrink flex-col rounded-tl-3xl bg-white">
    <button
      v-if="mode === 'full'"
      class="absolute right-2 top-2"
      @click.prevent.stop="$emit('close')"
    >
      <fa-icon icon="fa-light fa-xmark-large" />
    </button>
    <div class="flex items-center border-b-2 border-slate-100 p-4">
      <v-avatar size="42">
        <img
          :src="
            ppaClientStore.selectedClient?.avatar_id
              ? `${API_URL}/file/avatar/${ppaClientStore.selectedClient.avatar_id}`
              : '/img/img-quote_avatar.png'
          "
        />
      </v-avatar>
      <div class="pl-2">
        <div
          class="text-sm uppercase leading-tight tracking-wider text-slate-500"
        >
          Client
        </div>
        <div class="text-base font-semibold">
          {{
            ppaClientStore.selectedClient?.known_as ||
            ppaClientStore.selectedClient?.full_name
          }}
        </div>
      </div>
    </div>
    <!-- <div class="pb-4">
      <v-text-field
        placeholder="Search chat..."
        :color="vgMedTurq"
        variant="outlined"
        hide-details
      >
        <template #append-inner>
          <fa-icon
            icon="fa-regular fa-magnifying-glass"
            class="-mt-1 pr-2 text-lg text-slate-800"
          />
        </template>
      </v-text-field>
    </div> -->
    <div ref="chatmessages" class="flex-1 overflow-y-auto p-4">
      <div v-if="showOlderButton" class="mb-2 flex-none text-center">
        <button
          class="bg-vgmedturq rounded-lg px-6 py-2 text-sm font-semibold uppercase text-white"
          @click="ppaClientStore.loadOlderMessages"
        >
          Show older messages
        </button>
      </div>
      <ChatMessage
        v-for="message in messages"
        :key="message.uuid"
        :message="message"
        :user-uuid="userStore.user.uuid"
      />
    </div>
    <hr class="py-2" />
    <div class="flex flex-none gap-2 pb-2">
      <v-textarea
        ref="chatInput"
        v-model="newMessage"
        rows="1"
        row-height="10"
        auto-grow
        :color="vgMedTurq"
        placeholder="Message"
        variant="outlined"
        class="limit-height text-sm"
        rounded="xl"
        density="compact"
        :disabled="sendingMessage"
        hide-details
        @keypress.enter.exact="handleEnter"
        @keypress.ctrl.enter.prevent="addNewLine"
        @keypress.shift.enter.prevent="addNewLine"
      />
      <button
        :disabled="sendingMessage"
        class="bg-vgmedturq h-10 w-10 rounded-full uppercase text-white"
        @click="handleSendMessage"
      >
        <fa-icon icon="fa-regular fa-paper-plane" class="p-1 text-[1.1rem]" />
      </button>
    </div>
  </div>
</template>
<script>
import { nextTick } from 'vue';
import { API_URL, MARK_READ_TIMEOUT_LENGTH } from '@/config';
import { mapStores } from 'pinia';
import { useUserStore } from '@/stores/user';
import { usePpaClientStore } from '@/stores/ppaClient';
import { useUiStore } from '@/stores/ui';
import { getSocket } from '@/services/clientSessionService';
import { getTask } from '@/services/taskService';
import { markRead } from '@/services/chatService';
import ChatMessage from '@/components/ChatMessage.vue';
import tailwind from 'tailwind.config';
import { useRoute } from 'vue-router';

export default {
  name: 'PpaClientChat',
  components: {
    ChatMessage,
  },
  props: {
    mode: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  data() {
    const route = useRoute();

    return {
      route,
      API_URL,
      markReadTimeout: null,
      intervalId: null,
      originalTitle: '',
      flashing: false,
      sendingMessage: false,
      vgMedTurq: tailwind.theme.extend.colors.vgmedturq[500],
    };
  },
  computed: {
    ...mapStores(useUserStore, usePpaClientStore, useUiStore),
    messages() {
      return this.ppaClientStore.selectedClient?.messages || [];
    },
    showOlderButton() {
      return this.ppaClientStore.selectedClient?.showOlderButton || false;
    },
    newMessage: {
      get() {
        return this.ppaClientStore.selectedClient?.newMessage || '';
      },
      set(value) {
        this.ppaClientStore.selectedClient.newMessage = value;
      },
    },
  },
  watch: {
    messages: {
      deep: true,
      handler(newVal, oldVal) {
        if (
          newVal?.length > oldVal?.length &&
          oldVal?.length > 0 &&
          this.flashing !== null
        ) {
          this.flashing = true;
        }

        nextTick(() => {
          this.$refs.chatmessages.scrollTop = this.$refs.chatmessages.scrollHeight;
        });
      },
    },
  },
  async created() {
    if (!this.ppaClientStore.selectedClient) {
      const task = await getTask(this.route.params.task_uuid);
      await this.ppaClientStore.selectClient(task.user_uuid);
    }
    await this.ppaClientStore.initMessages().catch(() => {
      this.$root.$snackbar.error('An error occurred while loading messages');
    });
    getSocket().on('askbetty.chat_updated', this.clientChatUpdatedListener);

    if (!this.originalTitle) {
      this.originalTitle = document.title;
    }

    window.addEventListener('blur', this.handleWindowBlur);
    window.addEventListener('focus', this.resetState);
    this.resetState();
  },
  beforeUnmount() {
    getSocket().off('askbetty.chat_updated', this.clientChatUpdatedListener);
    window.removeEventListener('blur', this.handleWindowBlur);
    window.removeEventListener('focus', this.resetState);
  },
  methods: {
    handleEnter(event) {
      if (!this.uiStore.tabletOrSmaller) {
        this.handleSendMessage();
        event.preventDefault();
      }
    },
    async handleSendMessage() {
      if (!/\S/g.test(this.newMessage)) {
        this.newMessage = '';

        return;
      }

      this.sendingMessage = true;

      try {
        await this.ppaClientStore.sendMessage();
      } catch (error) {
        return this.$root.$snackbar.error(
          error?.data?.message ??
            `An error occurred when sending your message. Please try again later.`
        );
      } finally {
        this.sendingMessage = false;
        nextTick(() => {
          const inputElement = this.$refs.chatInput?.$el?.querySelector(
            'textarea'
          );

          if (inputElement) {
            inputElement.focus();
          }
        });
      }

      this.flashing = null;
    },
    clientChatUpdatedListener(payload) {
      if (payload.entity_uuid === this.ppaClientStore.selectedClient?.uuid) {
        this.ppaClientStore.loadNewMessages();
      }
    },
    async handleMarkRead(last_read_uuid, unread) {
      if (!this.messages?.length || !this.ppaClientStore.selectedClient) {
        return;
      }
      if (this.markReadTimeout) {
        clearTimeout(this.markReadTimeout);
      }

      try {
        this.ppaClientStore.selectedClient.last_read_uuid =
          typeof last_read_uuid === 'undefined'
            ? this.messages[this.messages.length - 1].uuid
            : last_read_uuid;

        await markRead(this.ppaClientStore.selectedClientUuid, {
          message_uuid: this.ppaClientStore.selectedClient.last_read_uuid,
          unread,
        });
      } catch (error) {
        return this.$root.$snackbar.error(
          error?.data?.message ??
            `An error occurred when marking messages as read. Please try again later.`
        );
      }
    },
    addNewLine(event) {
      if (
        typeof event.srcElement.selectionEnd !== 'undefined' &&
        event.srcElement.selectionEnd === event.srcElement.selectionStart
      ) {
        const cursor = event.srcElement.selectionStart;
        this.newMessage = `${this.newMessage.slice(
          0,
          cursor
        )}\n${this.newMessage.slice(cursor)}`;
        nextTick(() => {
          if (event.srcElement.setSelectionRange) {
            event.srcElement.focus();
            event.srcElement.setSelectionRange(cursor + 1, cursor + 1);
          } else if (event.srcElement.createTextRange) {
            let range = event.srcElement.createTextRange();
            range.collapse(true);
            range.moveEnd('character', cursor + 1);
            range.moveStart('character', cursor + 1);
            range.select();
          }
        });
      } else {
        this.newMessage = this.newMessage + '\n';
      }
    },
    handleWindowBlur() {
      this.intervalId = setInterval(this.flashTitle, 1500);
    },
    flashTitle() {
      if (this.flashing) {
        document.title =
          document.title === this.originalTitle
            ? 'New Message!'
            : this.originalTitle;
      }
    },
    resetState() {
      this.flashing = false;
      document.title = this.originalTitle;

      clearInterval(this.intervalId);

      if (this.markReadTimeout) {
        clearTimeout(this.markReadTimeout);
      }

      this.markReadTimeout = setTimeout(
        () => this.handleMarkRead(),
        MARK_READ_TIMEOUT_LENGTH
      );
    },
  },
};
</script>
