<template>
  <button
    class="w-full border border-solid bg-white p-4 text-left"
    :class="
      client.uuid === ppaClientStore.selectedClient?.uuid
        ? 'border-vgmedturq border-2 border-r-8'
        : 'border-slate-300 pr-6'
    "
    @click="
      if (client.uuid !== $route.params.user_uuid) {
        $router.push('/clients/' + client.uuid);
      }
    "
  >
    <div class="flex items-center gap-4 text-base">
      <div class="m-1 flex flex-auto gap-2 rounded-lg p-2">
        <v-avatar size="42" class="">
          <img
            :src="
              client.avatar_id
                ? `${API_URL}/file/avatar/${client.avatar_id}`
                : '/img/img-quote_avatar.png'
            "
          />
        </v-avatar>
        <div class="flex flex-1 flex-col font-semibold">
          <div
            v-if="client.last_online === 'now'"
            class="text-vgmedturq uppercase"
          >
            Online
          </div>
          <div v-else class="uppercase text-slate-500">Offline</div>
          <div>
            {{ client.full_name || client.company }}
          </div>
        </div>
      </div>
      <fa-icon
        icon="fa-regular fa-briefcase-blank"
        class="text-xl text-slate-500"
      />
      <NotificationBadge
        :count="
          client.unread_count +
          (client.tasks?.reduce(
            (accumulator, task) =>
              accumulator + task.unread_count + (task.unread ? 1 : 0),
            0
          ) || 0)
        "
      >
        <fa-icon icon="fa-regular fa-message" class="text-xl text-slate-500" />
      </NotificationBadge>
    </div>
  </button>
</template>

<script setup>
import { API_URL } from '@/config';
import NotificationBadge from '@/components/NotificationBadge.vue';
import { usePpaClientStore } from '@/stores/ppaClient';
const ppaClientStore = usePpaClientStore();

defineProps({
  client: {
    type: Object,
    default() {
      return {};
    },
  },
});
</script>
