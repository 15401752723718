const STORAGE_KEY = 'ab.tabSync.';

const tabId = performance.now() + ':' + ((Math.random() * 1000000000) | 0);

const callOnce = (lockname, fn, timeout = 3000) => {
  if (!lockname) throw 'lockname cannot be empty';

  if (!window.localStorage) {
    fn();
    return;
  }

  const localStorageKey = STORAGE_KEY + lockname;

  localStorage.setItem(localStorageKey, tabId);
  setTimeout(function () {
    if (localStorage.getItem(localStorageKey) === tabId) fn();
  }, 250);

  setTimeout(function () {
    localStorage.removeItem(localStorageKey);
  }, timeout);
};

const createNotification = (title, body, link) => {
  let basePath = `${location.protocol}//${location.host}`;
  let image = `${basePath}/img/VG-OnDemand-logo-vertical.svg`;

  const notification = new Notification(title, {
    image,
    body,
  });

  if (link) {
    notification.onclick = (event) => {
      event.preventDefault();
      window.open(`${basePath}${link}`, '_blank');
    };
  }
};

export const sendNotification = (title, body, link) =>
  callOnce('sendNotification', () => {
    if (Notification.permission === 'granted') {
      createNotification(title, body, link);
    } else {
      Notification.requestPermission().then(function (permission) {
        if (permission === 'granted') {
          createNotification(title, body, link);
        }
      });
    }
  });

export const playDing = () =>
  callOnce('playDing', () => new Audio('/audio/ding.mp3').play());

export const playReturning = () =>
  callOnce('playReturning', () => new Audio('/audio/returning.mp3').play());
