import { getSocket } from '@/services/clientSessionService';
import {
  getClientMessages,
  saveClientMessage,
  getLastReadUuid,
} from '@/services/chatService';
import { MESSAGE_PAGINATION_LIMIT } from '@/config';
import { defineStore } from 'pinia';
import { useUserStore } from './user';

export const useClientChatStore = defineStore('clientChat', {
  state: () => ({
    messages: [],
    newMessage: '',
    showOlderButton: false,
    last_read_uuid: null,
  }),
  actions: {
    async initMessages() {
      const clientChatUpdatedListener = () => {
        this.loadNewMessages();
      };
      if (
        !getSocket()
          .listeners('askbetty.chat_updated')
          ?.some((f) => f.name === 'clientChatUpdatedListener')
      ) {
        getSocket().on('askbetty.chat_updated', clientChatUpdatedListener);
      }

      const userStore = useUserStore();
      const [messages, last_read_uuid] = await Promise.all([
        getClientMessages(userStore.user.uuid),
        getLastReadUuid(userStore.user.uuid),
      ]);
      this.messages = messages;
      this.last_read_uuid = last_read_uuid;

      if (messages.length < MESSAGE_PAGINATION_LIMIT) {
        this.showOlderButton = false;
      } else {
        this.showOlderButton = true;
      }
    },
    async loadOlderMessages() {
      const oldestMessageUuid = this.messages[0]?.uuid;

      if (!oldestMessageUuid) {
        return;
      }

      const userStore = useUserStore();
      const messages = await getClientMessages(userStore.user.uuid, [
        `before=${oldestMessageUuid}`,
      ]);
      messages.reverse().forEach((newMessage) => {
        if (
          !this.messages.some((message) => message.uuid === newMessage.uuid)
        ) {
          this.messages.unshift(newMessage);
        }
      });
      if (messages.length < MESSAGE_PAGINATION_LIMIT) {
        this.showOlderButton = false;
      }
    },
    async loadNewMessages() {
      const userStore = useUserStore();
      let filters = [];

      if (this.messages.length > 0) {
        filters.push(`since=${this.messages[this.messages.length - 1]?.uuid}`);
      }

      const messages = await getClientMessages(userStore.user.uuid, filters);

      messages.forEach((newMessage) => {
        if (
          !this.messages.some((message) => message.uuid === newMessage.uuid)
        ) {
          this.messages.push(newMessage);
        }
      });
      if (messages.length < MESSAGE_PAGINATION_LIMIT) {
        this.showOlderButton = false;
      }
    },
    async sendMessage() {
      if (!this.newMessage) {
        return;
      }

      const userStore = useUserStore();
      await saveClientMessage(userStore.user.uuid, {
        message: this.newMessage,
        source: 'main',
      });
      this.newMessage = '';
    },
  },
});
