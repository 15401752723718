<template>
  <div
    v-if="taskChatStore.selectedTask"
    class="flex h-full shrink flex-col rounded-tl-3xl bg-white"
  >
    <button
      v-if="mode === 'full'"
      class="absolute right-2 top-2"
      @click.prevent.stop="$emit('close')"
    >
      <fa-icon icon="fa-light fa-xmark-large" />
    </button>
    <div class="flex items-center border-b-2 border-slate-100 p-4">
      <div class="flex shrink flex-row-reverse p-4 pb-0">
        <v-avatar
          v-for="user in [...otherParticipants, userStore.user]"
          :key="user.uuid"
          size="42"
          class="-ml-4"
        >
          <img
            :src="
              user.avatar_id
                ? `${API_URL}/file/avatar/${user.avatar_id}`
                : '/img/img-quote_avatar.png'
            "
          />
        </v-avatar>
      </div>
      <div class="pl-2">
        <div
          class="text-sm uppercase leading-tight tracking-wider text-slate-500"
        >
          Assistant Chat
        </div>
        <div class="text-base font-semibold">
          You +
          {{
            otherParticipants
              .map(
                (participant) =>
                  participant.known_as || participant.display_name
              )
              .join(',')
          }}
        </div>
      </div>
    </div>
    <!-- <div class="pb-4">
      <v-text-field
        placeholder="Search chat..."
        :color="vgMedTurq"
        variant="outlined"
        hide-details
      >
        <template #append-inner>
          <fa-icon
            icon="fa-regular fa-magnifying-glass"
            class="-mt-1 pr-2 text-lg text-slate-800"
          />
        </template>
      </v-text-field>
    </div> -->
    <div ref="chatmessages" class="flex-1 overflow-y-auto">
      <div
        v-if="taskChatStore.selectedTask.showOlderButton"
        class="mb-2 flex-none text-center"
      >
        <button
          class="bg-vgmedturq rounded-lg px-6 py-2 text-sm font-semibold uppercase text-white"
          @click="taskChatStore.loadOlderMessages(task.uuid)"
        >
          Show older messages
        </button>
      </div>
      <ChatMessage
        v-for="message in taskChatStore.messages"
        :key="message.uuid"
        :message="message"
        :user-uuid="userStore.user.uuid"
      />
    </div>
    <hr class="py-2" />
    <div class="flex flex-none gap-2 pb-2">
      <v-textarea
        ref="chatInput"
        v-model="newMessage"
        rows="1"
        row-height="10"
        auto-grow
        :color="vgMedTurq"
        placeholder="Message"
        variant="outlined"
        class="limit-height text-sm"
        rounded="xl"
        density="compact"
        hide-details
        :disabled="sendingMessage"
        @keypress.enter.exact="handleEnter"
        @keypress.ctrl.enter.prevent="addNewLine"
        @keypress.shift.enter.prevent="addNewLine"
      />
      <button
        class="bg-vgmedturq h-10 w-10 rounded-full uppercase text-white"
        :disabled="sendingMessage"
        @click="handleSendMessage"
      >
        <fa-icon icon="fa-regular fa-paper-plane" class="p-1 text-[1.1rem]" />
      </button>
    </div>
  </div>
</template>
<script>
import { nextTick } from 'vue';
import { API_URL, MARK_READ_TIMEOUT_LENGTH } from '@/config';
import tailwind from 'tailwind.config';
import { markRead } from '@/services/chatService';
import { mapStores } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useTaskChatStore } from '@/stores/taskChat';
import { useUiStore } from '@/stores/ui';
import ChatMessage from '@/components/ChatMessage.vue';

export default {
  name: 'TaskChat',
  components: {
    ChatMessage,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  data() {
    return {
      API_URL,
      markReadTimeout: null,
      intervalId: null,
      originalTitle: '',
      flashing: false,
      messageCount: null,
      sendingMessage: false,
      vgMedTurq: tailwind.theme.extend.colors.vgmedturq[500],
    };
  },
  computed: {
    ...mapStores(useUserStore, useTaskChatStore, useUiStore),
    newMessage: {
      get() {
        return this.taskChatStore.selectedTask?.newMessage;
      },
      set(value) {
        this.taskChatStore.selectedTask.newMessage = value;
      },
    },
    otherParticipants() {
      return this.taskChatStore.selectedTask.participants.filter(
        (participant) => participant.uuid !== this.userStore.user.uuid
      );
    },
  },
  watch: {
    'taskChatStore.messages': {
      deep: true,
      handler(newVal) {
        if (this.messageCount < newVal?.length) {
          this.messageCount = newVal.length;
          this.flashing = true;
        }

        nextTick(() => {
          if (this.$refs.chatmessages)
            this.$refs.chatmessages.scrollTop = this.$refs.chatmessages.scrollHeight;
        });
      },
    },
  },
  created() {
    this.taskChatStore.initMessages(this.task.uuid).catch(() => {
      this.$root.$snackbar.error('An error occurred while loading messages');
    });

    if (!this.originalTitle) {
      this.originalTitle = document.title;
    }

    window.addEventListener('blur', this.handleWindowBlur);
    window.addEventListener('focus', this.resetState);
    this.resetState();
  },
  beforeUnmount() {
    window.removeEventListener('blur', this.handleWindowBlur);
    window.removeEventListener('focus', this.resetState);
  },
  methods: {
    handleEnter(event) {
      if (!this.uiStore.tabletOrSmaller) {
        this.handleSendMessage();
        event.preventDefault();
      }
    },
    async handleSendMessage() {
      if (!/\S/g.test(this.newMessage)) {
        this.newMessage = '';

        return;
      }

      this.sendingMessage = true;

      try {
        await this.taskChatStore.sendMessage();
      } catch (error) {
        return this.$root.$snackbar.error(
          error?.data?.message ??
            `An error occurred when sending your message. Please try again later.`
        );
      } finally {
        this.sendingMessage = false;
        nextTick(() => {
          const inputElement = this.$refs.chatInput?.$el?.querySelector(
            'textarea'
          );

          if (inputElement) {
            inputElement.focus();
          }
        });
      }
    },
    async handleMarkRead(last_read_uuid, unread) {
      if (!this.taskChatStore.messages?.length) {
        return;
      }
      if (this.markReadTimeout) {
        clearTimeout(this.markReadTimeout);
      }

      try {
        this.taskChatStore.last_read_uuid =
          typeof last_read_uuid === 'undefined'
            ? this.taskChatStore.messages[
                this.taskChatStore.messages.length - 1
              ].uuid
            : last_read_uuid;

        await markRead(this.task.uuid, {
          message_uuid: this.taskChatStore.last_read_uuid,
          unread,
          task_uuid: this.task.uuid,
        });
      } catch (error) {
        return this.$root.$snackbar.error(
          error?.data?.message ??
            `An error occurred when marking messages as read. Please try again later.`
        );
      }
    },
    addNewLine(event) {
      if (
        typeof event.srcElement.selectionEnd !== 'undefined' &&
        event.srcElement.selectionEnd === event.srcElement.selectionStart
      ) {
        const cursor = event.srcElement.selectionStart;
        this.newMessage = `${this.newMessage.slice(
          0,
          cursor
        )}\n${this.newMessage.slice(cursor)}`;
        nextTick(() => {
          if (event.srcElement.setSelectionRange) {
            event.srcElement.focus();
            event.srcElement.setSelectionRange(cursor + 1, cursor + 1);
          } else if (event.srcElement.createTextRange) {
            let range = event.srcElement.createTextRange();
            range.collapse(true);
            range.moveEnd('character', cursor + 1);
            range.moveStart('character', cursor + 1);
            range.select();
          }
        });
      } else {
        this.newMessage = this.newMessage + '\n';
      }
    },
    handleWindowBlur() {
      this.intervalId = setInterval(this.flashTitle, 1500);
    },
    flashTitle() {
      if (this.flashing === true) {
        if (this.task.unread_count > 0) {
          document.title =
            document.title === this.originalTitle
              ? 'New Message!'
              : this.originalTitle;
        }
      }
    },
    resetState() {
      this.flashing = false;
      document.title = this.originalTitle;

      clearInterval(this.intervalId);

      if (this.markReadTimeout) {
        clearTimeout(this.markReadTimeout);
      }

      this.markReadTimeout = setTimeout(
        () => this.handleMarkRead(),
        MARK_READ_TIMEOUT_LENGTH
      );
    },
  },
};
</script>
