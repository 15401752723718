<template>
  <div class="flex h-full max-h-screen min-h-screen gap-4 sm:px-4">
    <div class="flex w-96 grow-0 flex-col pt-8">
      <div class="pb-2">Clients ({{ ppaClientStore.clientCount }})</div>
      <button
        v-if="Object.values(ppaClientStore.clients).length > 0"
        class="mb-2 flex items-center justify-end gap-2"
        title="Toggle online only clients"
        @click="showOnline = !showOnline"
      >
        <span class="mb-1">{{ showOnline ? 'Online' : 'All' }}</span>
        <fa-icon
          class="text-2xl"
          :icon="
            showOnline
              ? 'fa-duotone fa-toggle-large-on'
              : 'fa-duotone fa-toggle-large-off'
          "
          :style="
            showOnline
              ? `--fa-primary-color:${vgMedTurq}; --fa-secondary-color: ${vgStone}`
              : `--fa-primary-color:${vgStone}; --fa-secondary-color: ${vgStone}`
          "
        />
      </button>
      <div class="flex flex-col gap-1 overflow-y-auto">
        <ClientCard
          v-for="client in clients"
          :key="client.uuid"
          :client="client"
        />
      </div>
    </div>
    <div
      v-if="ppaClientStore.selectedClient"
      class="max-h-screen flex-1 overflow-y-auto border-l-2 border-slate-200 pt-8"
    >
      <div class="mb-4">
        <span class="p-4 font-semibold">{{
          ppaClientStore.selectedClient.company ||
          ppaClientStore.selectedClient.full_name
        }}</span>
        <NotificationBadge
          :count="ppaClientStore.selectedClient.unread_count"
          class="inline py-2"
        >
          <router-link
            :to="`/clients/${ppaClientStore.selectedClient.uuid}/workspace`"
            class="border-vgmedturq text-vgmedturq ml-4 rounded-md border-2 border-solid bg-white px-6 py-2 text-base font-semibold"
            >Open Client Workspace
          </router-link>
        </NotificationBadge>
      </div>
      <div class="shrink-0 grow">
        <div
          ref="tablist"
          class="flex flex-auto gap-2 overflow-x-auto p-4 text-sm font-semibold"
        >
          <button
            class="shrink-0 grow rounded-md border border-solid border-slate-300 px-6 py-2 uppercase"
            :class="
              tab === 'new'
                ? 'bg-vgmedturq text-white'
                : 'bg-white hover:bg-slate-200 text-slate-500'
            "
            data-tab="new"
            @click="tab = 'new'"
          >
            My Draft Tasks ({{ ppaClientStore.newTasks.length }})
          </button>
          <NotificationBadge
            :count="ppaClientStore.activeTasksNotificationCount"
            class="shrink-0 grow"
          >
            <button
              class="w-full rounded-md border border-solid border-slate-300 px-6 py-2 uppercase"
              :class="
                tab === 'active'
                  ? 'bg-vgmedturq text-white'
                  : 'bg-white hover:bg-slate-200 text-slate-500'
              "
              data-tab="active"
              @click="tab = 'active'"
            >
              My Tasks ({{ ppaClientStore.activeTasks.length }})
            </button>
          </NotificationBadge>
          <NotificationBadge
            :count="ppaClientStore.reviewTasksNotificationCount"
            class="shrink-0 grow"
          >
            <button
              class="w-full rounded-md border border-solid border-slate-300 px-6 py-2 uppercase"
              :class="
                tab === 'review'
                  ? 'bg-vgmedturq text-white'
                  : 'bg-white hover:bg-slate-200 text-slate-500'
              "
              data-tab="review"
              @click="tab = 'review'"
            >
              Review Ready ({{ ppaClientStore.reviewTasks.length }})
            </button>
          </NotificationBadge>
        </div>
        <div class="h-full rounded-lg bg-[#FBFBFB]">
          <div class="p-4">
            <div
              v-if="tab === 'new' && ppaClientStore.selectedTasks.length === 0"
              class="p-8 text-center text-slate-500"
            >
              Hey! Add a task and let’s start crushing your goals.
            </div>
            <div
              v-else-if="ppaClientStore.selectedTasks.length === 0"
              class="p-8 text-center text-slate-500"
            >
              {{ noTasksMessage }}
            </div>
            <div
              v-else
              class="mt-8 grid w-full flex-none grid-cols-12 px-4 text-slate-500"
              role="row"
            >
              <div class="col-span-12 sm:col-span-6" role="columnheader">
                <h3 class="mr-4 inline font-semibold uppercase">
                  Tasks ({{ ppaClientStore.selectedTasks.length }})
                </h3>
              </div>
              <div
                v-if="!uiStore.tabletOrSmaller && tab !== 'new'"
                class="col-span-5 flex justify-between gap-2"
              >
                <div
                  role="columnheader"
                  class="col-span-5 hidden self-end text-sm font-semibold uppercase sm:block"
                >
                  Assigned Assistant
                </div>
                <div
                  role="columnheader"
                  class="col-span-3 hidden self-end text-sm font-semibold uppercase sm:block"
                >
                  Time Logged
                </div>
              </div>
            </div>
            <PpaTaskCard
              v-for="task in ppaClientStore.selectedTasks"
              :key="task.uuid"
              :task="task"
              :client="ppaClientStore.selectedClient"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { nextTick } from 'vue';
import { noTasksMessage } from '@/config';
import { mapStores } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useUiStore } from '@/stores/ui';
import { usePpaClientStore } from '@/stores/ppaClient';
import ClientCard from '@/components/ClientCard.vue';
import PpaTaskCard from '@/components/PpaTaskCard.vue';
import NotificationBadge from '@/components/NotificationBadge.vue';
import { useRoute } from 'vue-router';
import tailwind from 'tailwind.config';

export default {
  name: 'PpaTasks',
  components: {
    ClientCard,
    PpaTaskCard,
    NotificationBadge,
  },
  data() {
    const route = useRoute();

    return {
      route,
      showOnline: false,
      vgMedTurq: tailwind.theme.extend.colors.vgmedturq[500],
      vgStone: tailwind.theme.extend.colors.vgstone[600],
    };
  },
  computed: {
    ...mapStores(useUserStore, usePpaClientStore, useUiStore),
    tab: {
      get() {
        return this.ppaClientStore.selectedClient?.tab;
      },
      set(value) {
        this.ppaClientStore.selectedClient.tab = value;
      },
    },
    noTasksMessage() {
      return noTasksMessage[this.tab] ?? noTasksMessage.default;
    },
    clients() {
      return this.showOnline
        ? Object.values(this.ppaClientStore.clients)?.filter(
            (client) => client.last_online === 'now'
          )
        : this.ppaClientStore.clients;
    },
  },
  watch: {
    'route.params.user_uuid': {
      handler: function (value) {
        this.ppaClientStore.selectClient(value);
      },
    },
    tab: {
      immediate: true,
      handler() {
        nextTick(() => {
          this.$refs.tablist
            ?.querySelector('[data-tab="' + this.tab + '"]')
            ?.scrollIntoView({ block: 'nearest', inline: 'center' });
        });
      },
    },
  },
  async created() {
    try {
      await this.ppaClientStore.loadClients();
      await this.ppaClientStore.selectClient(this.route.params.user_uuid);
    } catch (error) {
      this.$root.$snackbar.error(
        error.data?.message ??
          'An error occurred while getting clients, please refresh the page'
      );
    }
  },
};
</script>
