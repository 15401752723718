import { getSocket } from '@/services/clientSessionService';
import { getTasks } from '@/services/taskService';
import { defineStore } from 'pinia';

export const useBettyTaskStore = defineStore('bettyTask', {
  state: () => ({
    tasks: [],
    rowCount: 0,
    tab: 'my',
  }),
  getters: {
    myTasks() {
      return this.tasks.filter(
        (task) =>
          task.status !== 'complete' &&
          task.relationship_type === 'assigned' &&
          !task.has_change_requests
      );
    },
    myTasksNotificationCount() {
      return this.myTasks.reduce(
        (accumulator, task) =>
          accumulator + (task.unread_count || 0) + (task.unread ? 1 : 0),
        0
      );
    },
    inviteTasks() {
      return this.tasks.filter(
        (task) =>
          task.status === 'active' && task.relationship_type === 'invited'
      );
    },
    changedTasks() {
      return this.tasks.filter(
        (task) =>
          task.status !== 'complete' &&
          task.relationship_type === 'assigned' &&
          task.has_change_requests
      );
    },
    changedTasksNotificationCount() {
      return this.changedTasks.reduce(
        (accumulator, task) =>
          accumulator + (task.unread_count || 0) + (task.unread ? 1 : 0),
        0
      );
    },
    completeTasks() {
      return this.tasks.filter(
        (task) =>
          task.status === 'complete' && task.relationship_type === 'assigned'
      );
    },
    completeTasksNotificationCount() {
      return this.completeTasks.reduce(
        (accumulator, task) =>
          accumulator + (task.unread_count || 0) + (task.unread ? 1 : 0),
        0
      );
    },
    selectedTasks() {
      switch (this.tab) {
        case 'my':
          return this.myTasks;
        case 'invite':
          return this.inviteTasks;
        case 'changed':
          return this.changedTasks;
        case 'complete':
          return this.completeTasks;
        default:
          return [];
      }
    },
  },
  actions: {
    loadTasks() {
      const taskUpdatedListener = async () => {
        const result = await getTasks([`relatedToMe=1`]).catch(() => ({
          tasks: [],
          rowCount: 0,
        }));
        this.tasks = result.tasks;
      };
      if (
        !getSocket()
          .listeners('askbetty.task_updated')
          ?.some((f) => f.name === 'taskUpdatedListener')
      ) {
        getSocket().on('askbetty.task_updated', taskUpdatedListener);
      }
      if (
        !getSocket()
          .listeners('askbetty.invite_updated')
          ?.some((f) => f.name === 'taskUpdatedListener')
      ) {
        getSocket().on('askbetty.invite_updated', taskUpdatedListener);
      }
      if (
        !getSocket()
          .listeners('askbetty.chat_updated')
          ?.some((f) => f.name === 'taskUpdatedListener')
      ) {
        getSocket().on('askbetty.chat_updated', taskUpdatedListener);
      }

      return taskUpdatedListener();
    },
  },
});
