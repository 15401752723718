<template>
  <div class="flex h-full flex-col bg-[#FBFBFB]">
    <div v-if="!uiStore.tabletOrSmaller" class="h-16 p-4 pb-0 text-right">
      <div class="pr-4">
        <span class="text-vgnavy text-xs uppercase"> Account Balance </span>
        <span class="text-vgmedturq ml-2 font-semibold">
          {{ formatDurationShort(userStore.client?.client?.minutes_balance) }}
        </span>
      </div>
    </div>
    <div class="flex h-full">
      <div class="flex-1 overflow-y-auto p-4 lg:h-[calc(100vh-4rem)]">
        <h1 v-if="uiStore.mobile" class="my-2 block font-semibold">Tasks</h1>
        <div
          v-if="!uiStore.tabletOrSmaller"
          class="max-w-xl pt-0 tracking-tight"
        >
          <h1 class="text-vgnavy mt-2 text-xl font-semibold">
            {{ clientTaskStore.welcomeTitle }},
            {{ userStore.user.known_as || userStore.user.full_name }}!
          </h1>
          <p class="text-vgmedturq font-semibold">
            {{ clientTaskStore.welcomeSubtitle }}
          </p>
        </div>
        <div
          v-if="!uiStore.mobile"
          ref="tablist"
          class="flex gap-2 overflow-x-auto py-4 text-sm font-semibold"
        >
          <button
            class="shrink-0 grow rounded-md border border-solid border-slate-300 px-6 py-2 uppercase tracking-wide"
            :class="
              clientTaskStore.tab === 'new'
                ? 'bg-vgmedturq text-white'
                : 'bg-white hover:bg-slate-200 text-slate-500'
            "
            data-tab="new"
            @click="clientTaskStore.tab = 'new'"
          >
            My Draft Tasks ({{ clientTaskStore.newTasks.length }})
          </button>
          <NotificationBadge
            :count="clientTaskStore.activeTasksNotificationCount"
            class="shrink-0 grow"
          >
            <button
              class="w-full rounded-md border border-solid border-slate-300 px-6 py-2 uppercase tracking-wide"
              :class="
                clientTaskStore.tab === 'active'
                  ? 'bg-vgmedturq text-white'
                  : 'bg-white hover:bg-slate-200 text-slate-500'
              "
              data-tab="active"
              @click="clientTaskStore.tab = 'active'"
            >
              In Progress ({{ clientTaskStore.activeTasks.length }})
            </button>
          </NotificationBadge>
          <NotificationBadge
            :count="clientTaskStore.reviewTasksNotificationCount"
            class="shrink-0 grow"
          >
            <button
              class="w-full rounded-md border border-solid border-slate-300 px-6 py-2 uppercase tracking-wide"
              :class="
                clientTaskStore.tab === 'review'
                  ? 'bg-vgmedturq text-white'
                  : 'bg-white hover:bg-slate-200 text-slate-500'
              "
              data-tab="review"
              @click="clientTaskStore.tab = 'review'"
            >
              Review Ready ({{ clientTaskStore.reviewTasks.length }})
            </button>
          </NotificationBadge>
          <NotificationBadge
            :count="clientTaskStore.completeTasksNotificationCount"
            class="shrink-0 grow"
          >
            <button
              class="w-full rounded-md border border-solid border-slate-300 px-6 py-2 uppercase tracking-wide"
              :class="
                clientTaskStore.tab === 'complete'
                  ? 'bg-vgmedturq text-white'
                  : 'bg-white hover:bg-slate-200 text-slate-500'
              "
              data-tab="complete"
              @click="clientTaskStore.tab = 'complete'"
            >
              Complete ({{ clientTaskStore.completeTasks.length }})
            </button>
          </NotificationBadge>
        </div>
        <div class="rounded-lg bg-white">
          <div
            v-if="clientTaskStore.tab === 'new'"
            class="bg-vgmedturq-100 flex flex-col rounded-t-lg"
          >
            <div class="flex flex-row gap-4 px-4 pt-4">
              <v-text-field
                v-model.trim="clientTaskStore.newTaskTitle"
                variant="outlined"
                :color="vgMedTurq"
                rounded="lg"
                bg-color="#fff"
                placeholder="Give your task a title..."
                maxlength="200"
                counter="200"
                density="compact"
                @keyup.enter="handleCreateTask"
              />
              <button
                class="bg-vgmedturq h-10 self-start rounded-lg px-4 text-xl uppercase text-white md:px-6 md:text-sm"
                :loading="clientTaskStore.newTaskLoading"
                @click="handleCreateTask"
              >
                {{ uiStore.mobile ? '+' : 'Add' }}
              </button>
            </div>
            <button
              v-if="uiStore.mobile"
              class="text-vgmedturq flex items-center gap-1 px-4 pb-4 font-semibold uppercase"
              @click="uiStore.showTemplatesDialog = true"
            >
              <span class="mr-2 text-lg font-bold">or</span>
              <fa-icon
                class="text-vgmedturq self-center text-xl leading-10 opacity-70 hover:opacity-100"
                icon="fa-light fa-wand-magic-sparkles "
              />
              <span class="text-sm tracking-wide"> Start with a template </span>
            </button>
          </div>
          <div class="p-4" role="table">
            <div
              v-if="
                clientTaskStore.tab === 'new' &&
                clientTaskStore.selectedTasks.length === 0
              "
              class="p-8 text-center text-slate-500"
            >
              Hey! Add a task and let’s start crushing your goals.
            </div>
            <div
              v-else-if="clientTaskStore.selectedTasks.length === 0"
              class="p-8 text-center text-slate-500"
            >
              {{ noTasksLabel }}
            </div>
            <div
              v-else-if="!uiStore.mobile"
              class="mt-2 grid w-full flex-none grid-cols-12 px-4"
              role="row"
            >
              <div class="col-span-12 sm:col-span-7" role="columnheader">
                <h3 class="mr-4 inline font-semibold">
                  Tasks ({{ clientTaskStore.selectedTasks.length }})
                </h3>
              </div>
            </div>
            <div v-if="!uiStore.mobile">
              <ClientTaskCard
                v-for="task in clientTaskStore.selectedTasks"
                :key="task.uuid"
                :task="task"
                :hand-off-to="
                  userStore.client?.ppa?.known_as ||
                  userStore.client?.ppa?.display_name ||
                  'PPA'
                "
                :client="userStore.client?.client"
              />
            </div>
            <div v-else-if="organizedTasks">
              <v-expansion-panels v-model="panel" variant="accordion" multiple>
                <v-expansion-panel
                  v-for="(type, id) in Object.keys(organizedTasks)"
                  :key="id"
                  :value="type"
                >
                  <v-expansion-panel-title hide-actions>
                    <div class="flex max-w-2xl grow items-center font-semibold">
                      <fa-icon
                        :icon="
                          panel.includes(type)
                            ? 'fa-regular fa-chevron-up'
                            : 'fa-regular fa-chevron-down'
                        "
                        class="text-vgmedturq mr-2 text-lg"
                      />
                      <div
                        class="text-vgmedturq font-bold uppercase tracking-wider"
                      >
                        {{ taskType[type].label }} ({{
                          clientTaskStore.organizedTasks[type]?.length
                        }})
                      </div>
                      <div
                        class="mx-2 mb-2 flex grow self-end border-b-[1px]"
                      ></div>
                    </div>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <ClientTaskCard
                      v-for="task in organizedTasks[type]"
                      :key="task.uuid"
                      :task="task"
                      :hand-off-to="
                        userStore.client?.ppa?.known_as ||
                        userStore.client?.ppa?.display_name ||
                        'PPA'
                      "
                      :client="userStore.client?.client"
                    />
                    <v-btn
                      v-if="
                        organizedTasks[type].length <
                        clientTaskStore.organizedTasks[type].length
                      "
                      variant="text"
                      class="text-vgmedturq mx-auto flex font-semibold normal-case"
                      @click="showMore(type)"
                    >
                      See
                      {{
                        clientTaskStore.organizedTasks[type].length -
                          organizedTasks[type].length >=
                        5
                          ? 5
                          : clientTaskStore.organizedTasks[type].length -
                            organizedTasks[type].length
                      }}
                      more ...
                    </v-btn>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
        </div>
      </div>
      <ChatWrapper type="client" :client="userStore.client?.client" />
    </div>
  </div>
</template>
<script setup>
import { nextTick, ref, computed, watch, reactive, inject } from 'vue';
import ChatWrapper from '@/components/ChatWrapper.vue';
import ClientTaskCard from '@/components/ClientTaskCard.vue';
import NotificationBadge from '@/components/NotificationBadge.vue';
import tailwind from 'tailwind.config';
import { formatDurationShort } from '@/services/formattingService';
import { noTasksMessage } from '@/config';
import { useUserStore } from '@/stores/user';
import { useUiStore } from '@/stores/ui';
import { useClientTaskStore } from '@/stores/clientTask';

const userStore = useUserStore();
const uiStore = useUiStore();
const clientTaskStore = useClientTaskStore();
const snackbar = inject('snackbar');

async function init() {
  await clientTaskStore.loadTasks();
  organizeTasks();
}
init();

const organizedTasks = ref({});
const panel = ref(['new']);
const tablist = ref(null);
const newTaskSaving = ref(false);

const vgMedTurq = tailwind.theme.extend.colors.vgmedturq[500];

const noTasksLabel = computed(() => {
  return noTasksMessage[clientTaskStore.tab] ?? noTasksMessage.default;
});

const taskType = reactive({
  new: { label: 'My Draft Tasks', count: 5 },
  active: {
    label: 'In Progress',
    count: 5,
  },
  review: { label: 'Review Ready', count: 5 },
  complete: { label: 'Completed', count: 5 },
});

watch(
  () => clientTaskStore.tab,
  () => {
    nextTick(() => {
      tablist.value
        ?.querySelector('[data-tab="' + clientTaskStore.tab + '"]')
        .scrollIntoView({ block: 'nearest', inline: 'center' });
    });
  },
  { immediate: true }
);
watch(
  () => clientTaskStore.tasks,
  () => {
    organizeTasks();
  }
);

async function handleCreateTask() {
  if (!clientTaskStore.newTaskTitle || newTaskSaving.value) {
    return;
  }
  try {
    newTaskSaving.value = true;
    await clientTaskStore.createTask();
  } catch (e) {
    snackbar.error('An error occurred while creating a new task');
  }
  newTaskSaving.value = false;
}

function organizeTasks() {
  organizedTasks.value = {};
  Object.keys(clientTaskStore.organizedTasks).forEach((key) => {
    organizedTasks.value[key] = clientTaskStore.organizedTasks[key].slice(
      0,
      taskType[key].count
    );
  });
}

function showMore(type) {
  taskType[type].count += 5;
  organizeTasks();
}
</script>
<style scoped>
* :deep(.v-expansion-panel::after) {
  @apply !border-none;
}
* :deep(.v-expansion-panel-title) {
  @apply m-0 p-0;
}
* :deep(.v-expansion-panel__shadow) {
  @apply hidden;
}
* :deep(.v-expansion-panel-title__overlay) {
  @apply hidden;
}
* :deep(.v-expansion-panel-text__wrapper) {
  @apply p-0;
}
</style>
