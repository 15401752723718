import { defineStore } from 'pinia';

export const useUiStore = defineStore('ui', {
  state: () => ({
    uiReady: false,
    navDrawerOpen: false,
    chatOverlayOpen: false,
    email: '',
    referralToken: null,
    mobile: false,
    tablet: false,
    showTemplatesDialog: false,
    showFeedbackDialog: false,
    showNewVersionAvailable: false,
  }),
  getters: {
    tabletOrSmaller(state) {
      return state.mobile || state.tablet;
    },
  },
  actions: {
    setNavDrawerOpen(payload) {
      if (this.navDrawerOpen !== payload) {
        this.navDrawerOpen = payload;
      }
    },
    setNotificationDrawerOpen(payload) {
      if (this.notificationDrawerOpen !== payload) {
        this.notificationDrawerOpen = payload;
      }
    },
    setNotificationDrawerExpanded(payload) {
      if (this.notificationDrawerExpanded !== payload) {
        this.notificationDrawerExpanded = payload;
      }
    },
    setEmail(payload) {
      this.email = payload;
    },
    setReferralToken(payload) {
      this.referralToken = payload;
    },
    setMobile(payload) {
      this.mobile = payload;
    },
    setTablet(payload) {
      this.tablet = payload;

      if (!this.tablet && !this.mobile) {
        this.navDrawerOpen = false;
        this.chatOverlayOpen = false;
        this.notificationDrawerOpen = false;
      }
    },
    setReady() {
      this.uiReady = true;
    },
  },
});
