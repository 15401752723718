import { getJSON, putJSON, postJSON } from '@/services/httpService';

export const getFullUserById = (id) => getJSON(`/user/${id}?full=true`);

export const getUserConfig = () => getJSON(`/user/config`);

export const getPreferences = () => getJSON(`/user/preferences`);
export const setPreferences = (body) => putJSON(`/user/preferences`, body);

export const searchUsers = (filters) =>
  getJSON(`/user/search?${filters.join('&')}`);

export const saveUser = (user) =>
  user.uuid ? putJSON(`/user/${user.uuid}`, user) : postJSON(`/user`, user);

export const giveEarlyAccessPermission = () => putJSON(`/user/earlyAccess/`);

export const createUserWithClient = (user, client) =>
  postJSON(`/user?client_uuid=${client.uuid}`, user);

export const getTimezone = (latitude, longitude) =>
  postJSON(`/user/timezone`, { latitude, longitude });

export const getClientRelationshipsFromUser = (uuid) =>
  getJSON(`/user/${uuid}/relationships/client/`);
