import { createRouter, createWebHistory } from 'vue-router';
import Login from '@/views/Login.vue';
import TasksWrapper from '@/views/TasksWrapper.vue';
import PpaClientWorkspace from '@/views/PpaClientWorkspace.vue';
import TaskDetails from '@/views/TaskDetails.vue';
import TaskInvitations from '@/views/TaskInvitations.vue';
import TaskTimeEntries from '@/views/TaskTimeEntries.vue';
import ClientBilling from '@/views/ClientBilling.vue';
import ClientBillingHistory from '@/views/ClientBillingHistory.vue';
import ProfileWrapper from '@/views/ProfileWrapper.vue';
import EarlyAccess from '@/views/EarlyAccess.vue';
import HealthCheck from '@/views/HealthCheck.vue';
import {
  hasSession,
  sessionUserHasPermission,
  getSocket,
} from '@/services/clientSessionService';
import { checkBuildVersion } from '@/services/buildService';
import { init as initApm } from '@elastic/apm-rum';
import { useUserStore } from '@/stores/user';
import { useUiStore } from '@/stores/ui';
import { useConfigStore } from '@/stores/config';

const DEFAULT_TITLE = 'VG OnDemand';

const router = createRouter({
  history: createWebHistory(),
  base: import.meta.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (to.name === 'search') {
      return savedPosition; // preserve scroll
    }

    if (to.hash) {
      return { el: to.hash };
    }

    return { left: 0, top: 0 };
  },
  routes: [
    {
      path: '/',
      component: Login,
      meta: {
        title: 'VG OnDemand - Login',
      },
    },
    {
      path: '/tasks',
      component: TasksWrapper,
      meta: {
        title: 'VG OnDemand - Tasks',
        requiresAuth: true,
      },
    },
    {
      path: '/clients/:user_uuid?',
      component: TasksWrapper,
      meta: {
        permissionsSome: ['askbetty_ppa'],
        title: 'VG OnDemand - Client Tasks',
        requiresAuth: true,
      },
    },
    {
      path: '/clients/:user_uuid/workspace',
      component: PpaClientWorkspace,
      name: 'ppaClientWorkspace',
      meta: {
        permissionsSome: ['askbetty_ppa'],
        title: 'VG OnDemand - Client Workspace',
        requiresAuth: true,
        showBack: 'Clients',
        alwaysShowBack: true,
      },
    },
    {
      path: '/tasks/:task_uuid',
      component: TaskDetails,
      name: 'taskDetails',
      meta: {
        title: 'VG OnDemand - Task Details',
        requiresAuth: true,
        headerTitle: 'Task',
        showClose: 'Task',
      },
    },
    {
      path: '/tasks/:task_uuid/collaborate',
      component: TaskDetails,
      meta: {
        title: 'VG OnDemand - Task Details',
        permissionsSome: ['askbetty_ppa'],
        requiresAuth: true,
        collaborateChat: true,
        headerTitle: 'Task',
        showClose: 'Task',
      },
    },
    {
      path: '/tasks/:task_uuid/invite',
      component: TaskInvitations,
      meta: {
        permissionsSome: ['askbetty_ppa'],
        title: 'VG OnDemand - Task Invitiations',
        requiresAuth: true,
        showBack: 'Task',
        alwaysShowBack: true,
      },
    },
    {
      path: '/tasks/:task_uuid/time',
      component: TaskTimeEntries,
      meta: {
        permissionsSome: ['askbetty_va'],
        title: 'VG OnDemand - Task Time Entries',
        requiresAuth: true,
        showBack: 'Task',
        alwaysShowBack: true,
      },
    },
    {
      path: '/billing',
      component: ClientBilling,
      meta: {
        permissionsSome: ['askbetty_client'],
        title: 'VG OnDemand - Billing',
        requiresAuth: true,
      },
    },
    {
      path: '/billing/history',
      component: ClientBillingHistory,
      meta: {
        permissionsSome: ['askbetty_client'],
        title: 'VG OnDemand - Billing History',
        requiresAuth: true,
      },
    },
    {
      path: '/profile',
      component: ProfileWrapper,
      meta: {
        permissionsSome: ['askbetty_client', 'askbetty_ppa'],
        title: 'VG OnDemand - Profile',
        requiresAuth: true,
      },
    },
    {
      path: '/early_access',
      component: EarlyAccess,
      meta: {
        title: 'VG OnDemand - Early Access',
      },
    },
    {
      path: '/healthcheck',
      component: HealthCheck,
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore();

  const configStore = useConfigStore();
  if (!configStore.configsLoaded) {
    await configStore
      .loadConfig()
      .then(() => {
        if (configStore.apmServerUrl) {
          window.apm = initApm({
            serviceName: 'VG OnDemand',
            serverUrl: configStore.apmServerUrl,
            serviceVersion: import.meta.env.APP_VERSION,
            active: import.meta.env.VITE_NODE_ENV !== 'development',
            environment: import.meta.env.VITE_NODE_ENV,
          });
        }
      })
      .then(async () => {
        await userStore.loadSession().then(() => userStore.initUser());
      });
  }
  to.meta.fromFullPath = from.fullPath;

  if (from.meta.postLoginURL) {
    to.meta.postLoginURL = from.meta.postLoginURL;
  }
  if (hasSession() && to.fullPath === '/') {
    if (
      userStore.user.permissions.includes('askbetty_ppa') ||
      userStore.user.permissions.includes('askbetty_va') ||
      userStore.user.permissions.includes('askbetty_client')
    ) {
      return next(userStore.homeLink);
    }
  }
  if (!hasSession() && to.fullPath !== '/') {
    if (to.fullPath === '/early_access') {
      return next();
    }
    return next('/');
  }
  if (
    (to.meta.featureFlag || to.meta.featurePermission) &&
    (!to.meta.featureFlag ||
      !useConfigStore().checkFeatureFlag(to.meta.featureFlag)) &&
    (!to.meta.featurePermission ||
      !sessionUserHasPermission(to.meta.featurePermission))
  ) {
    return next('/');
  }

  if (to.meta.permissionsSome) {
    if (!hasSession()) {
      from.meta.postLoginURL = to.fullPath;
      return next('/');
    }
    if (
      !to.meta.permissionsSome.some((permission) =>
        sessionUserHasPermission(permission)
      )
    ) {
      return next('/');
    }
  }
  if (to.meta.permissionsRequired) {
    if (!hasSession()) {
      from.meta.postLoginURL = to.fullPath;
      return next('/');
    }
    if (
      to.meta.permissionsRequired.some(
        (permission) => !sessionUserHasPermission(permission)
      )
    ) {
      return next('/');
    }
  }
  getSocket().emit('current_page', to.fullPath);
  next();
});

router.afterEach((to) => {
  document.title = to.meta.title || DEFAULT_TITLE;
  useUiStore().setReady();
  checkBuildVersion(true);
});
export default router;
