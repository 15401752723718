<template>
  <div>
    <ClientProfile v-if="type === 'client'" />
    <PpaProfile v-else-if="type === 'ppa'" />
  </div>
</template>

<script setup>
import ClientProfile from '@/components/ClientProfile.vue';
import PpaProfile from '@/components/PpaProfile.vue';
import { useUserStore } from '@/stores/user';
import { computed } from 'vue';

const userStore = useUserStore();
const type = computed(() => {
  return userStore.user.permissions.includes('askbetty_client')
    ? 'client'
    : userStore.user.permissions.includes('askbetty_ppa')
    ? 'ppa'
    : 'assistant';
});
</script>
