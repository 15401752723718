import moment from 'moment';

export const formatSecondsAgo = (seconds, shortFormat) => {
  let hourLabel = shortFormat ? 'h' : ' hours';
  let minLabel = shortFormat ? 'm' : ' minutes';
  let secLabel = shortFormat ? 's' : ' seconds';

  let h = Math.floor(seconds / 3600);

  let remainder = seconds % 3600;
  let m = Math.floor(remainder / 60);

  let s = Math.floor(remainder % 60);

  let tokens = [];
  if (h > 0) tokens.push(`${h}${hourLabel}`);
  if (m > 0) tokens.push(`${m}${minLabel}`);
  if (s > 0) tokens.push(`${s}${secLabel}`);

  return tokens.length === 0 ? '0s' : tokens.join(' ');
};

export const formatDaysAgo = (dateString) => {
  const now = moment(new Date());
  const date = moment(dateString);

  return Math.round(moment.duration(now.diff(date)).asDays());
};

export const formatSecondsAgoAsDate = (seconds) => {
  return moment().subtract({ seconds }).format('MMM Do YYYY h:mm:ss A');
};

export const formatDateTime = (dateString) => {
  if (!dateString) return '';

  return moment(dateString).format('MMM Do YYYY h:mm:ss A');
};

export const formatDate = (dateString) => {
  if (!dateString) return '';

  if (dateString?.endsWith?.('Z')) {
    dateString = dateString.substring(0, dateString.length - 1);
  }

  return moment(dateString).format('MMM Do YYYY');
};

export const formatShortDate = (dateString, preserveTimezone) => {
  if (!dateString) return '';

  if (!preserveTimezone && dateString?.endsWith?.('Z')) {
    dateString = dateString.substring(0, dateString.length - 1);
  }

  return moment(dateString).format('YYYY-MM-DD');
};

export const formatShortTime = (dateString) => {
  if (!dateString) return '';

  return moment(dateString).format('HH:mm:ss');
};

export const formatShortDateTime = (dateString) => {
  if (!dateString) return '';

  return moment(dateString).format('DD/MM/YYYY - h:mm A');
};

export const formatTimestamp = (dateString) => {
  let now = moment(new Date());
  let ds = moment(dateString);
  let days = moment.duration(now.diff(ds)).asDays();

  if (days >= 1) {
    return moment(dateString).format('h:mm A, MMM Do');
  }

  return moment(dateString).format('h:mm A');
};

export const formatRelativeDate = (dateString) => {
  return moment(moment(dateString)).fromNow();
};

export const formatCurrency = (amount, currency) => {
  if (isNaN(amount)) return '';
  return `${Intl.NumberFormat(
    'en-CA',
    currency
      ? {
          style: 'currency',
          currency,
          minimumFractionDigits: 2,
        }
      : {
          style: 'decimal',
          minimumFractionDigits: 2,
        }
  ).format(amount)}`;
};

export const formatSafeUpperCase = (text) => {
  return text && typeof text === 'string' ? text.toUpperCase() : text;
};

export const capitalizeFirstLetter = (string) => {
  return string && typeof string === 'string'
    ? string.charAt(0).toUpperCase() + string.slice(1)
    : string;
};

export const addSuffixToDay = (day) => {
  if (day === 1 || day === 21 || day === 31) {
    return 'st';
  } else if (day === 2 || day === 22) {
    return 'nd';
  } else if (day === 3 || day === 23) {
    return 'rd';
  }
  return 'th';
};

export const formatDuration = (hoursDecimal) => {
  if (isNaN(parseFloat(hoursDecimal))) return '0h 0m (0.0h)';
  hoursDecimal = Math.round(hoursDecimal * 100) / 100;
  return `${Math.floor(hoursDecimal)}h ${Math.round(
    (hoursDecimal - Math.floor(hoursDecimal)) * 60
  )}m (${hoursDecimal}h)`;
};

export const formatDurationShort = (minutes) => {
  if (isNaN(parseFloat(minutes))) return '0h 0m';

  if (minutes < 60) {
    return `${minutes}m`;
  }

  return `${Math.floor(minutes / 60)}h ${minutes % 60}m`;
};

export const formatDurationSeconds = (seconds) => {
  if (isNaN(parseFloat(seconds))) return '0m';

  if (seconds < 60) {
    return `${seconds}s`;
  }

  const remainingSeconds = seconds % 60;

  if (seconds < 60 * 60) {
    return `${Math.floor(seconds / 60)}m ${
      remainingSeconds > 0 ? `${remainingSeconds}s` : ''
    }`;
  }

  const hours = Math.floor(seconds / (60 * 60));
  const minutes = Math.floor((seconds % (60 * 60)) / 60);

  return `${hours > 0 ? `${hours}h` : ''} ${minutes > 0 ? `${minutes}m` : ''} ${
    remainingSeconds > 0 ? `${remainingSeconds}s` : ''
  }`;
};

export const formatTimestampTo12 = (timestamp) => {
  return moment.utc(timestamp).local().format('hh:mm a');
};

export const formatHoursMinutes = (dateString) => {
  return moment(dateString).format('HH:mm');
};

export const truncateText = (text, size = 128) => {
  return text?.length > size ? `${text.substring(0, size)}...` : text;
};

export const formatBytes = (bytes) => {
  if (!bytes || isNaN(bytes)) {
    return '';
  }

  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));

  return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(0))} ${sizes[i]}`;
};
