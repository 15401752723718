<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    persistent
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <div class="rounded-2xl bg-white p-8">
      <v-form @submit.prevent="ok">
        <div class="flex flex-col items-center gap-4 p-4 text-center">
          <p class="text-vgnavy pb-2 text-2xl font-semibold">
            Ready for review?
          </p>
          <div class="flex flex-col items-center">
            <p>Task Time</p>
            <div class="text-vgmedturq mt-1 text-xl font-semibold">
              {{ formatDurationShort(time_logged_minutes) }}
            </div>
          </div>

          <div class="flex flex-col justify-evenly gap-4 sm:flex-row">
            <button
              class="bg-vgmedturq mt-4 min-w-[80%] rounded-full p-2 px-6 text-base font-semibold uppercase text-white disabled:opacity-50"
              @click="cancel"
            >
              Not yet
            </button>
            <button
              class="bg-vgmedturq mt-4 min-w-[80%] rounded-full p-2 px-6 text-base font-semibold uppercase text-white disabled:opacity-50"
              type="submit"
            >
              Confirm
            </button>
          </div>
        </div>
      </v-form>
    </div>
  </v-dialog>
</template>

<script>
import {
  formatCurrency,
  formatDurationShort,
} from '@/services/formattingService';
import { ASKBETTY_APP_URL } from '@/config';

const defaultOptions = {
  width: 600,
  zIndex: 200,
};

export default {
  name: 'ConfirmMinutesDialog',
  data: () => ({
    ASKBETTY_APP_URL,
    dialog: false,
    resolve: null,
    reject: null,
    time_logged_minutes: 0,
    options: defaultOptions,
  }),
  methods: {
    formatCurrency,
    formatDurationShort,
    open(time_logged_minutes) {
      this.dialog = true;
      this.time_logged_minutes = time_logged_minutes;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    ok() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
