import { API_URL } from '@/config';
import { httpOptions } from '@/services/clientSessionService';

export const getJSON = (path) =>
  fetch(`${API_URL}${path}`, httpOptions()).then((res) =>
    res.ok
      ? res.json().catch(() => 'Ok')
      : res
          .json()
          .catch(() => {
            throw 'An Error occurred';
          })
          .then((json) => Promise.reject({ status: res.status, data: json }))
  );

export const getRaw = (path) => fetch(`${API_URL}${path}`, httpOptions());

export const deleteAction = (path) =>
  fetch(`${API_URL}${path}`, httpOptions('DELETE')).then((res) =>
    res.ok
      ? res.json().catch(() => 'Ok')
      : res
          .json()
          .catch(() => {
            throw 'An Error occurred';
          })
          .then((json) => Promise.reject({ status: res.status, data: json }))
  );

export const postJSON = (path, body, headers = {}) =>
  fetch(
    `${API_URL}${path}`,
    httpOptions(
      'POST',
      body ? (headers['Content-Type'] ? body : JSON.stringify(body)) : null,
      headers
    )
  ).then((res) =>
    res.ok
      ? res.json().catch(() => 'Ok')
      : res
          .json()
          .catch(() => {
            throw 'An Error occurred';
          })
          .then((json) => Promise.reject({ status: res.status, data: json }))
  );

export const putJSON = (path, body) =>
  fetch(
    `${API_URL}${path}`,
    httpOptions('PUT', body ? JSON.stringify(body) : null)
  ).then((res) =>
    res.ok
      ? res.json().catch(() => 'Ok')
      : res
          .json()
          .catch(() => {
            throw 'An Error occurred';
          })
          .then((json) => Promise.reject({ status: res.status, data: json }))
  );

export const canGoBack = function () {
  return !!window.history.state?.back;
};
