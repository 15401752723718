<template>
  <div>
    <h1 class="mt-3 p-4 text-xl font-semibold">Your tasks</h1>
    <div
      ref="tablist"
      class="flex gap-2 overflow-x-auto p-4 text-sm font-semibold"
    >
      <NotificationBadge
        :count="bettyTaskStore.myTasksNotificationCount"
        class="shrink-0 grow"
      >
        <button
          class="w-full rounded-md border border-solid border-slate-300 px-6 py-2 uppercase"
          :class="
            bettyTaskStore.tab === 'my'
              ? 'bg-vgmedturq text-white'
              : 'bg-white hover:bg-slate-200 text-slate-500'
          "
          data-tab="my"
          @click="bettyTaskStore.tab = 'my'"
        >
          My Tasks ({{ bettyTaskStore.myTasks.length }})
        </button>
      </NotificationBadge>
      <button
        class="shrink-0 grow rounded-md border border-solid border-slate-300 px-6 py-2 uppercase"
        :class="
          bettyTaskStore.tab === 'invite'
            ? 'bg-vgmedturq text-white'
            : 'bg-white hover:bg-slate-200 text-slate-500'
        "
        data-tab="invite"
        @click="bettyTaskStore.tab = 'invite'"
      >
        Invites ({{ bettyTaskStore.inviteTasks.length }})
      </button>
      <NotificationBadge
        :count="bettyTaskStore.changedTasksNotificationCount"
        class="shrink-0 grow"
      >
        <button
          class="w-full rounded-md border border-solid border-slate-300 px-6 py-2 uppercase"
          :class="
            bettyTaskStore.tab === 'changed'
              ? 'bg-vgmedturq text-white'
              : 'bg-white hover:bg-slate-200 text-slate-500'
          "
          data-tab="changed"
          @click="bettyTaskStore.tab = 'changed'"
        >
          Change Requests ({{ bettyTaskStore.changedTasks.length }})
        </button>
      </NotificationBadge>
      <NotificationBadge
        :count="bettyTaskStore.completeTasksNotificationCount"
        class="shrink-0 grow"
      >
        <button
          class="w-full rounded-md border border-solid border-slate-300 px-6 py-2 uppercase"
          :class="
            bettyTaskStore.tab === 'complete'
              ? 'bg-vgmedturq text-white'
              : 'bg-white hover:bg-slate-200 text-slate-500'
          "
          data-tab="complete"
          @click="bettyTaskStore.tab = 'complete'"
        >
          Completed ({{ bettyTaskStore.completeTasks.length }})
        </button>
      </NotificationBadge>
    </div>
    <div class="rounded-lg bg-[#FBFBFB]">
      <div class="p-4">
        <div
          v-if="bettyTaskStore.selectedTasks.length === 0"
          class="p-8 text-center text-slate-500"
        >
          {{ noTasksMessage }}
        </div>
        <div
          v-else
          class="mt-8 grid w-full flex-none grid-cols-12 px-4 text-slate-500"
          role="row"
        >
          <h3
            class="col-span-12 font-semibold sm:col-span-6"
            role="columnheader"
          >
            Task / status
          </h3>
          <div
            v-if="!uiStore.mobile"
            class="col-span-5 flex justify-between gap-2"
          >
            <div
              role="columnheader"
              class="col-span-2 hidden self-end text-sm font-semibold uppercase sm:block"
            >
              {{ bettyTaskStore.tab === 'invite' ? 'Invited' : 'Assigned' }} by
            </div>
            <div
              role="columnheader"
              class="col-span-3 hidden self-end text-sm font-semibold uppercase sm:block"
            >
              Actions
            </div>
          </div>
        </div>
        <BettyTaskCard
          v-for="task in bettyTaskStore.selectedTasks"
          :key="task.uuid"
          :task="task"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { nextTick } from 'vue';
import { mapStores } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useBettyTaskStore } from '@/stores/bettyTask';
import { useUiStore } from '@/stores/ui';
import BettyTaskCard from '@/components/BettyTaskCard.vue';
import NotificationBadge from '@/components/NotificationBadge.vue';

export default {
  name: 'BettyTasks',
  components: {
    BettyTaskCard,
    NotificationBadge,
  },
  data() {
    return {
      noTasksMessage: 'No tasks found',
    };
  },
  computed: {
    ...mapStores(useUserStore, useBettyTaskStore, useUiStore),
  },
  watch: {
    'bettyTaskStore.tab': {
      immediate: true,
      handler() {
        nextTick(() => {
          this.$refs.tablist
            ?.querySelector('[data-tab="' + this.bettyTaskStore.tab + '"]')
            .scrollIntoView({ block: 'nearest', inline: 'center' });
        });
      },
    },
  },
  created() {
    this.bettyTaskStore.loadTasks();
  },
};
</script>
