<template>
  <div v-if="task" class="bg-[#FBFBFB]">
    <div
      class="border-vgmedturq text-vgnavy flex flex-col border-b-4 border-solid p-4"
    >
      <button v-if="!uiStore.tabletOrSmaller" class="flex" @click="backToTask">
        <fa-icon
          icon="fa-regular fa-chevron-left"
          class="bg-vgmedturq h-8 w-8 rounded-full px-1 pt-1 text-center text-lg text-white"
          style="line-height: 1.5rem"
        />
        <span
          class="text-vgmedturq self-center pl-2 text-left text-sm uppercase"
        >
          Back to task
        </span>
      </button>
      <p class="ml-4 mt-4 text-sm uppercase">Invite Assistants to task:</p>
      <p class="ml-4 font-semibold">TASK: {{ task.title }}</p>
    </div>
    <div class="md:flex md:flex-row-reverse">
      <div class="p-4 md:max-w-xs">
        <strong>Invited Assistants ({{ inviteList.length }})</strong>
        <p v-if="!inviteMessage" class="text-sm">
          Invites are sent first come first serve and will be sent in this
          order:
        </p>
        <div
          v-for="betty in inviteList"
          :key="betty.uuid"
          class="group my-4 flex w-full flex-none flex-row gap-4 rounded-lg bg-white p-4 shadow"
          role="row"
        >
          <div class="flex flex-auto self-center" role="cell">
            <v-avatar size="42">
              <img
                :src="
                  betty.avatar_id
                    ? `${API_URL}/file/avatar/${betty.avatar_id}`
                    : '/img/img-quote_avatar.png'
                "
                alt="avatar"
              />
            </v-avatar>
            <div class="ml-4 flex flex-col self-center" role="cell">
              <div>
                {{ betty.full_name }}
              </div>
            </div>
          </div>
          <div class="self-center" role="cell">
            <button
              class="text-vgmedturq whitespace-nowrap bg-white text-base font-semibold"
              @click="removeFromInviteList(betty)"
            >
              <fa-icon
                icon="fa-solid fa-minus"
                class="bg-vgmedturq rounded-full p-1 text-white"
              />
              Remove
            </button>
          </div>
        </div>
        <div v-if="inviteList.length > 0" class="w-full text-center">
          <button
            class="bg-vgmedturq rounded-lg p-2 px-4 text-sm font-semibold uppercase text-white"
            @click="sendInvites"
          >
            Send invites to this task now
          </button>
        </div>
        <div v-if="inviteMessage" class="pt-8 text-sm">
          <p>{{ inviteMessage }}</p>
        </div>
      </div>
      <div class="flex-1 p-4">
        <div class="flex gap-2 p-4">
          <button
            class="rounded-md border border-solid border-slate-300 px-6 py-2 text-sm font-semibold uppercase"
            :class="
              tab === 'available'
                ? 'bg-vgmedturq text-white'
                : 'bg-white hover:bg-slate-200 text-slate-500'
            "
            @click="tab = 'available'"
          >
            Available ({{ filteredAvailableBettys.length }})
          </button>
          <button
            class="rounded-md border border-solid border-slate-300 px-6 py-2 text-sm font-semibold uppercase"
            :class="
              tab === 'invited'
                ? 'bg-vgmedturq text-white'
                : 'bg-white hover:bg-slate-200 text-slate-500'
            "
            @click="tab = 'invited'"
          >
            Invited ({{ task?.bettys.length ?? 0 }})
          </button>
        </div>
        <div>
          <div class="flex gap-4 rounded-t-lg p-4">
            <v-text-field
              v-model.trim="searchQuery"
              variant="outlined"
              :color="vgMedTurq"
              class="bg-white"
              rounded="lg"
              placeholder="Search talent pool..."
              hide-details
              @keyup.enter="search"
            />
            <button
              class="bg-vgmedturq rounded-lg px-6 text-sm font-semibold uppercase text-white"
              :loading="searchLoading"
              @click="search"
            >
              Search
            </button>
          </div>
          <div class="p-4">
            <div
              v-if="tab === 'new' && availableBettys.length === 0"
              class="p-8 text-center text-slate-500"
            >
              No Assistants Available
            </div>
            <div
              v-else-if="tab === 'invited' && task?.bettys.length === 0"
              class="p-8 text-center text-slate-500"
            >
              No Assistants Invited
            </div>
            <div
              v-else-if="tab === 'available' && availableBettys.length !== 0"
              class="mt-8 px-4 font-semibold"
            >
              Results ({{ filteredAvailableBettys.length ?? 0 }})
            </div>
            <div
              v-for="betty in displayBettys"
              :key="betty.uuid"
              class="group my-4 flex w-full flex-none flex-col gap-4 rounded-lg bg-white p-4 shadow sm:flex-row"
              role="row"
            >
              <div class="flex flex-auto self-center" role="cell">
                <v-avatar size="42">
                  <img
                    :src="
                      betty.avatar_id
                        ? `${API_URL}/file/avatar/${betty.avatar_id}`
                        : '/img/img-quote_avatar.png'
                    "
                    alt="avatar"
                  />
                </v-avatar>
                <div class="ml-4 flex flex-1 flex-col self-center" role="cell">
                  <div>
                    {{ betty.full_name }}
                  </div>
                  <div class="text-base">
                    {{
                      betty.last_online
                        ? `Last online: ${
                            betty.last_online === 'now'
                              ? 'online now'
                              : formatRelativeDate(betty.last_online)
                          }`
                        : ''
                    }}
                  </div>
                  <div
                    v-if="tab === 'available'"
                    class="text-base italic text-slate-700"
                  >
                    {{ betty.relationship_type }}
                  </div>
                </div>
              </div>
              <div v-if="tab === 'available'" class="self-center" role="cell">
                <button
                  v-if="inviteList.includes(betty)"
                  class="bg-vgmedturq rounded-xl p-4 text-base font-semibold uppercase text-white"
                  @click="removeFromInviteList(betty)"
                >
                  Remove from invite list
                </button>
                <button
                  v-else
                  class="border-vgmedturq text-vgmedturq rounded-xl border border-solid bg-white p-4 text-base font-semibold uppercase"
                  @click="addToInviteList(betty)"
                >
                  Add to invite list
                </button>
              </div>
              <div
                v-else-if="betty.relationship_type === 'invited'"
                class="text-vgmedturq flex items-center justify-end gap-1 self-center"
                role="cell"
              >
                <button
                  class="bg-vgmedturq mr-4 rounded-md p-2 px-4 font-semibold text-white"
                  @click="uninvite(betty)"
                >
                  Uninvite
                </button>
                <template
                  v-if="
                    !task?.bettys.some(
                      (betty) => betty.relationship_type === 'assigned'
                    )
                  "
                >
                  <fa-icon icon="fa-solid fa-check" /> Pending acceptance
                </template>
              </div>
              <div
                v-else-if="betty.relationship_type === 'assigned'"
                class="text-vgmedturq self-center"
                role="cell"
              >
                <button
                  class="bg-vgmedturq mr-4 rounded-md p-2 px-4 font-semibold text-white"
                  @click="unassign(betty)"
                >
                  Unassign
                </button>
                <fa-icon icon="fa-solid fa-check" /> Assigned
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapStores } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useUiStore } from '@/stores/ui';
import { getTask } from '@/services/taskService';
import { getBettys, inviteBetty, updateInvite } from '@/services/bettyService';
import { canGoBack } from '@/services/httpService';
import { getSocket } from '@/services/clientSessionService';
import { formatRelativeDate } from '@/services/formattingService';
import { API_URL } from '@/config';
import tailwind from 'tailwind.config';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'TaskInvitations',
  components: {},
  data() {
    const router = useRouter();
    const route = useRoute();
    return {
      route,
      router,
      task: null,
      tab: 'available',
      searchQuery: '',
      searchLoading: false,
      vgMedTurq: tailwind.theme.extend.colors.vgmedturq[500],
      availableBettys: [],
      inviteList: [],
      inviteMessage: '',
      API_URL,
    };
  },
  computed: {
    ...mapStores(useUserStore, useUiStore),
    displayBettys() {
      return this.tab === 'available'
        ? this.filteredAvailableBettys
        : this.task?.bettys ?? [];
    },
    filteredAvailableBettys() {
      return this.availableBettys.filter(
        (betty) =>
          !this.task?.bettys?.some((taskBetty) => taskBetty.uuid === betty.uuid)
      );
    },
  },
  async created() {
    try {
      await this.taskUpdatedListener({ uuid: this.route.params.task_uuid });
    } catch (error) {
      this.$root.$snackbar.error(
        error.data?.message ?? 'An error occurred while getting task details'
      );
    }
    getSocket().on('askbetty.task_updated', this.taskUpdatedListener);
    this.search();
  },
  beforeUnmount() {
    getSocket().off('askbetty.task_updated', this.taskUpdatedListener);
  },
  methods: {
    formatRelativeDate,
    backToTask() {
      if (canGoBack()) {
        this.router.go(-1);
      } else {
        this.router.push(`/tasks/${this.route.params.task_uuid}`);
      }
    },
    async taskUpdatedListener(payload) {
      if (payload.uuid === this.route.params.task_uuid) {
        this.task = await getTask(this.route.params.task_uuid);
      }
    },
    async search() {
      this.searchLoading = true;
      const results = await getBettys([
        `searchQuery=${encodeURIComponent(this.searchQuery)}`,
        `task_uuid=${this.task.uuid}`,
        `state[]=active`,
        `state[]=probationary`,
        `state[]=ineligible`,
        `pagination.limit=100`,
        `pagination.sortBy[]=last_online`,
        `pagination.sortDesc[]=true`,
      ]).catch((error) => {
        this.$root.$snackbar.error(
          error?.data?.message ?? 'An error occurred, please try again later.'
        );

        return { bettys: [], rowCount: 0 };
      });
      this.availableBettys = results.bettys;
      this.searchLoading = false;
    },
    addToInviteList(betty) {
      this.inviteMessage = '';
      if (this.inviteList.includes(betty)) {
        return;
      }
      this.inviteList.push(betty);
    },
    removeFromInviteList(betty) {
      this.inviteList = this.inviteList.filter(
        (invitee) => invitee.uuid !== betty.uuid
      );
    },
    async sendInvites() {
      try {
        const inviteListLength = this.inviteList.length;

        for (const betty of this.inviteList) {
          await inviteBetty({
            user_uuid: betty.uuid,
            task_uuid: this.task.uuid,
          });
          this.removeFromInviteList(betty);
          betty.relationship_type = 'invited';
          this.task.bettys = this.task.bettys ?? [];
          this.task.bettys.push(betty);
        }
        this.inviteMessage = `Success, your invite has been sent out to ${inviteListLength} Assistant${
          inviteListLength > 1 ? 's' : ''
        }.`;
      } catch (error) {
        this.$root.$snackbar.error(
          error?.data?.message ??
            'An error occurred while inviting Assistant, please try again later.'
        );
      }
    },
    async unassign(betty) {
      try {
        if (
          !(await this.$root.$confirm.open(
            `Unassign Assistant?`,
            `${betty.full_name} will no longer be able to work on this task. Are you sure you want to unassign?`,
            { okText: 'Ok', cancelText: 'Cancel' }
          ))
        ) {
          return;
        }
        await updateInvite({
          task_uuid: this.task.uuid,
          user_uuid: betty.uuid,
          relationship_type: 'unassigned',
        });
        betty.relationship_type = 'unassigned';
        const matchingAvailableBetty = this.availableBettys.find(
          (availableBetty) => availableBetty.uuid === betty.uuid
        );
        if (matchingAvailableBetty) {
          matchingAvailableBetty.relationship_type = 'unassigned';
        }
      } catch (error) {
        this.$root.$snackbar.error(
          error?.data?.message ??
            'An error occurred while unassigning Assistant, please try again later.'
        );
      }
    },
    async uninvite(betty) {
      try {
        if (
          !(await this.$root.$confirm.open(
            `Uninvite Assistant?`,
            `${betty.full_name} will no longer be able to accept this task. Are you sure you want to uninvite?`,
            { okText: 'Ok', cancelText: 'Cancel' }
          ))
        ) {
          return;
        }
        await updateInvite({
          task_uuid: this.task.uuid,
          user_uuid: betty.uuid,
          relationship_type: 'uninvited',
        });
        betty.relationship_type = 'uninvited';
        const matchingAvailableBetty = this.availableBettys.find(
          (availableBetty) => availableBetty.uuid === betty.uuid
        );
        if (matchingAvailableBetty) {
          matchingAvailableBetty.relationship_type = 'uninvited';
        }
      } catch (error) {
        this.$root.$snackbar.error(
          error?.data?.message ??
            'An error occurred while uninviting Assistant, please try again later.'
        );
      }
    },
  },
};
</script>
