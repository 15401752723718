<template>
  <div v-if="uiStore.tabletOrSmaller">
    <button
      class="fixed bottom-0 right-0 rounded-tl-2xl bg-[#EAEAEA] p-3"
      :class="{ 'z-10': !uiStore.chatOverlayOpen }"
      @click="uiStore.chatOverlayOpen = true"
    >
      <NotificationBadge :count="task?.unread_count ?? client?.unread_count">
        <v-avatar size="42">
          <img
            :src="
              chatParticipantAvatar
                ? `${API_URL}/file/avatar/${chatParticipantAvatar}`
                : '/img/img-quote_avatar.png'
            "
          />
        </v-avatar>
      </NotificationBadge>
    </button>
    <Transition name="slide-fade" mode="out-in">
      <div
        v-if="uiStore.chatOverlayOpen"
        class="fixed inset-0 h-screen bg-white"
      >
        <ClientChat
          v-if="type === 'client'"
          mode="full"
          :client="client"
          @close="uiStore.chatOverlayOpen = false"
        />
        <TaskChat
          v-else-if="type === 'task' && task"
          mode="full"
          :task="task"
          @close="uiStore.chatOverlayOpen = false"
        />
        <PpaClientChat
          v-else-if="type === 'ppaClient'"
          mode="full"
          @close="uiStore.chatOverlayOpen = false"
        />
      </div>
    </Transition>
  </div>
  <div
    v-else
    class="h-[calc(100vh-8rem)] w-[20rem] lg:h-[calc(100vh-4rem)] lg:w-[26rem]"
  >
    <ClientChat v-if="type === 'client'" :client="client" />
    <TaskChat v-else-if="type === 'task' && task" :task="task" />
    <PpaClientChat v-else-if="type === 'ppaClient'" />
  </div>
</template>
<script>
import { API_URL } from '@/config';
import ClientChat from '@/components/ClientChat.vue';
import TaskChat from '@/components/TaskChat.vue';
import PpaClientChat from '@/components/PpaClientChat.vue';
import NotificationBadge from '@/components/NotificationBadge.vue';
import { mapStores } from 'pinia';
import { useUiStore } from '@/stores/ui';
import { useUserStore } from '@/stores/user';
import { usePpaClientStore } from '@/stores/ppaClient';

export default {
  name: 'ChatWrapper',
  components: {
    ClientChat,
    TaskChat,
    PpaClientChat,
    NotificationBadge,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    task: {
      type: Object,
      default: () => {
        return {};
      },
    },
    client: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      API_URL,
    };
  },
  computed: {
    ...mapStores(useUiStore, useUserStore, usePpaClientStore),
    chatParticipantAvatar() {
      if (this.type === 'client') {
        return this.userStore.client.ppa.avatar_id;
      } else if (this.type === 'task') {
        return this.userStore.user.avatar_id !== this.task.ppa_avatar_id
          ? this.task.ppa_avatar_id
          : this.task.bettys.find(
              (betty) => betty.relationship_type === 'assigned'
            )?.avatar_id;
      } else if (this.type === 'ppaClient') {
        return this.ppaClientStore.selectedClient?.avatar_id;
      }
      return null;
    },
  },
};
</script>
<style scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s ease;
}
@media (prefers-reduced-motion) {
  .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition: none;
  }
}
.slide-fade-enter {
  transform: translateY(600px);
  opacity: 0;
}
.slide-fade-leave-to {
  transform: translateY(-600px);
  opacity: 0;
}
</style>
