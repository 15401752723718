<template>
  <div v-if="taskLoadError" class="flex flex-col gap-4" role="alert">
    <p>
      The task could not be loaded. This may be because you don't have
      permission to view the selected task.
    </p>
    <button
      class="bg-vgmedturq mx-auto rounded p-2 px-4 text-white"
      @click="closeTask"
    >
      Close
    </button>
  </div>
  <div v-else-if="task" class="flex h-full flex-initial flex-col bg-[#FBFBFB]">
    <div
      v-if="userStore.user?.permissions.includes('askbetty_client')"
      class="flex h-16 p-4 pb-0"
      :class="{ 'self-end': uiStore.tabletOrSmaller }"
    >
      <button
        v-if="!uiStore.tabletOrSmaller"
        class="flex flex-auto items-center"
        @click="closeTask"
      >
        <fa-icon
          icon="fa-regular fa-arrow-left"
          class="bg-vgmedturq h-8 w-8 rounded-full px-1 pt-1 text-center text-lg text-white"
          style="line-height: 1.5rem"
        />
        <span class="text-vgmedturq pl-2 text-left text-sm uppercase"
          >Back</span
        >
      </button>
      <div class="pr-4">
        <span class="text-vgnavy text-xs uppercase"> Account Balance </span>
        <span class="text-vgmedturq ml-2 font-semibold">
          {{ formatDurationShort(minutes_balance) }}
        </span>
      </div>
    </div>
    <div
      v-else
      class="border-vgmedturq flex h-16 gap-4 border-b-4 border-solid p-4 pb-0"
    >
      <div class="grow">{{ task.client_name }}</div>
      <div v-if="userStore.user?.permissions.includes('askbetty_ppa')">
        <span class="text-sm uppercase"> Account Balance </span>
        <span class="text-vgmedturq ml-2 font-semibold">
          {{ formatDurationShort(minutes_balance) }}
        </span>
      </div>
    </div>
    <div class="flex">
      <div
        class="h-[calc(100vh-8rem)] flex-1 overflow-y-auto lg:h-[calc(100vh-4rem)]"
      >
        <div
          v-if="userStore.user?.permissions.includes('askbetty_va')"
          class="flex w-[95%] gap-4 rounded-br-2xl p-2 shadow"
        >
          <button
            class="flex grow-0 flex-col items-center self-center"
            @click="closeTask"
          >
            <fa-icon
              icon="fa-regular fa-arrow-left"
              class="bg-vgmedturq h-6 w-6 rounded-full px-1 pt-1 text-center text-sm text-white"
              style="line-height: 1.1rem"
            />
            <span class="text-vgmedturq text-sm uppercase">Back</span>
          </button>
          <div
            class="self-center rounded-full px-2 text-center text-sm font-semibold uppercase text-white"
            :class="computedTaskDetails.taskStatusBgColor"
          >
            {{ computedTaskDetails.taskStatus }}
          </div>
          <div class="flex-1">
            <div :class="computedTaskDetails.taskStatusTextColor">
              {{ computedTaskDetails.taskStatusSummary }}
            </div>
          </div>
          <div class="flex grow-0 flex-row gap-4 self-center">
            <template
              v-if="
                computedTaskDetails.taskStatus === 'new invite' &&
                !task.assigned_betty_uuid
              "
            >
              <button
                :disabled="inviteLoading"
                class="border-vgmedturq text-vgmedturq bg-vgmedturq-100 rounded-lg border border-solid p-2 text-base font-semibold uppercase"
                @click="answerInvite('declined')"
              >
                <v-progress-circular
                  v-if="inviteLoading"
                  :size="25"
                  indeterminate
                ></v-progress-circular>
                <span v-else>Decline</span>
              </button>
              <button
                :disabled="inviteLoading"
                class="bg-vgmedturq rounded-lg p-2 text-base font-semibold uppercase text-white"
                @click="answerInvite('assigned')"
              >
                <v-progress-circular
                  v-if="inviteLoading"
                  :size="25"
                  indeterminate
                ></v-progress-circular>

                <span v-else> Accept Task</span>
              </button>
            </template>
            <template
              v-else-if="
                computedTaskDetails.taskStatus === 'new invite' &&
                task.assigned_betty_uuid
              "
            >
              <p>Task accepted by another Assistant</p>
            </template>
            <template v-else-if="task.status !== 'new'">
              <div
                class="shrink-0 self-center text-base uppercase text-slate-500"
              >
                Task Time
                <fa-icon
                  icon="fa-regular fa-pencil"
                  class="ml-1"
                  @click="router.push(`/tasks/${task.uuid}/time`)"
                />
              </div>
              <div
                v-if="
                  userStore.user?.permissions.includes('askbetty_ppa') ||
                  task.status !== 'active'
                "
                class="min-w-[4rem] whitespace-nowrap"
              >
                {{
                  task.time_logged_minutes
                    ? formatDurationShort(
                        task.time_logged_minutes + task.ongoing_time_entry
                      )
                    : '&nbsp;'
                }}
              </div>
              <template v-else>
                <button
                  v-if="task.ongoing_time_entry !== null"
                  class="bg-vgmedturq self-center rounded-full px-4 py-2 text-sm font-semibold uppercase text-white"
                  @click="handleStopTimerClick(task)"
                >
                  <fa-icon icon="fa-regular fa-stop" class="mr-1" />
                  Stop<span class="whitespace-nowrap normal-case"
                    >{{
                      task.time_logged_minutes
                        ? ': ' +
                          formatDurationShort(
                            task.time_logged_minutes + task.ongoing_time_entry
                          )
                        : ''
                    }}
                  </span>
                </button>
                <button
                  v-else
                  class="text-vgmedturq border-vgmedturq self-center rounded-full border border-solid px-4 py-1 text-sm font-semibold uppercase"
                  @click="handleStartTimerClick(task)"
                >
                  <fa-icon icon="fa-regular fa-play" class="mr-1" />
                  Start<span class="whitespace-nowrap normal-case"
                    >{{
                      task.time_logged_minutes
                        ? ': ' + formatDurationShort(task.time_logged_minutes)
                        : ''
                    }}
                  </span>
                </button>
              </template>
            </template>
          </div>
        </div>
        <div class="relative md:mx-4 lg:p-4">
          <div
            :class="{
              'rounded-xl border-2 border-slate-100 bg-white p-4 md:p-6 ': !uiStore.mobile,
            }"
          >
            <div
              :class="{
                'rounded-xl': !uiStore.mobile,
              }"
            >
              <div
                v-if="userStore.user?.permissions.includes('askbetty_client')"
                class="bg-vgmedturq-200/20 mb-6 mt-2 p-4 text-sm md:rounded-xl"
              >
                <div
                  class="mb-2 flex justify-between gap-3 font-semibold uppercase text-slate-600"
                  :class="{ 'flex-col': uiStore.mobile }"
                >
                  <h1 class="">Task Status</h1>
                  <span
                    v-if="handOffDate && !uiStore.mobile"
                    class="normal-case"
                  >
                    Handed off {{ formatShortDateTime(handOffDate) }}</span
                  >
                </div>
                <div class="flex flex-col gap-6 2xl:flex-row">
                  <div class="flex min-w-fit flex-row px-2 font-semibold">
                    <span class="mt-2 flex items-start gap-2 md:items-center">
                      <span
                        :class="status.classes.label"
                        class="rounded-full px-8 py-1 text-center text-sm uppercase tracking-wider text-white md:min-w-fit"
                      >
                        {{ status.label }}
                      </span>
                      <div
                        class="w-full text-base"
                        :class="status.classes.heading"
                      >
                        {{ status.heading }}
                        <div
                          v-if="handOffDate && uiStore.mobile"
                          class="text-vgmedturq text-sm normal-case"
                        >
                          Handed off {{ formatShortDateTime(handOffDate) }}
                        </div>
                      </div>
                    </span>
                  </div>

                  <span
                    class="mt-4 flex w-full items-center justify-between md:mt-0 md:justify-end"
                  >
                    <button
                      v-if="task.status === 'new'"
                      class="bg-vgmedturq w-full min-w-[8rem] items-center gap-2 rounded-full px-6 py-2 text-base font-semibold uppercase tracking-wide text-white md:flex md:w-fit"
                      :loading="taskStatusLoading"
                      @click="handleUpdateTaskStatus('active')"
                    >
                      <fa-icon icon="fa-regular fa-user-plus" class="text-xl" />
                      Hand off to
                      {{ task.ppa_known_as }}
                    </button>
                    <button
                      v-else-if="
                        computedTaskDetails.taskStatus === 'in progress'
                      "
                      class="border-vgmedturq text-vgmedturq w-full items-center gap-2 rounded-full border border-solid bg-white px-6 py-2 text-base font-semibold uppercase tracking-wide md:flex md:w-fit"
                    >
                      <fa-icon
                        icon="fa-regular fa-check"
                        class="text-xl font-semibold"
                      />
                      {{ task.ppa_known_as }} is on it!
                    </button>
                    <div
                      v-else-if="
                        task.status === 'client review' &&
                        !editChangeRequest.show
                      "
                      class="mt-4 flex w-full items-center justify-between gap-2 rounded-xl xl:my-0 xl:gap-10"
                    >
                      <div
                        class="flex min-w-[6rem] flex-col items-center rounded-md border-r-2 p-2 text-left md:justify-center md:border-none"
                      >
                        <p
                          class="max-w-[8rem] pr-4 text-sm font-semibold uppercase text-slate-600"
                        >
                          Current Accrued time
                        </p>
                        <div class="text-vgnavy w-full text-xl font-semibold">
                          {{ formatDurationShort(task.time_logged_minutes) }}
                        </div>
                      </div>
                      <div
                        class="grid grid-flow-row gap-1 md:grid-flow-col md:gap-2"
                      >
                        <button
                          class="bg-vgmedturq md:text-vgmedturq md:min-w-24 flex shrink items-center gap-2 rounded-full px-4 py-3 text-sm font-semibold uppercase text-white md:bg-transparent"
                          @click="editChangeRequest.show = true"
                        >
                          <fa-icon
                            icon="fa-solid fa-repeat"
                            class="text-sm lg:text-xl"
                          />
                          <span class="ml-2 text-left"> Request Changes </span>
                        </button>
                        <button
                          class="bg-vgmedturq md:min-w-24 flex shrink items-center gap-2 rounded-full px-4 py-3 text-sm font-semibold uppercase text-white"
                          :loading="taskStatusLoading"
                          @click="handleMarkAsDone()"
                        >
                          <fa-icon
                            icon="fa-regular fa-thumbs-up"
                            class="text-sm lg:text-xl"
                          />
                          Mark as Done
                        </button>
                      </div>
                    </div>
                    <span
                      v-if="task.status === 'complete'"
                      class="bg-vgmedturq-100/50 flex min-w-[6rem] shrink flex-row items-end gap-2 rounded-md p-2 md:flex-col md:items-center"
                    >
                      <h2
                        class="mb-1 text-sm font-semibold uppercase tracking-wide text-slate-600"
                      >
                        Total Task Time
                      </h2>
                      <div class="text-vgnavy text-2xl font-semibold">
                        {{ formatDurationShort(task.time_logged_minutes) }}
                      </div>
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div
              :class="{
                'm-4 rounded-xl border border-slate-100 bg-white p-4 shadow-md md:p-6':
                  uiStore.mobile,
              }"
            >
              <div class="flex flex-row">
                <div class="flex-1">
                  <h1 class="text-sm font-semibold uppercase text-slate-600">
                    Task Title
                  </h1>
                  <div v-if="editTask.show" class="flex">
                    <v-text-field
                      ref="editTaskTitle"
                      v-model.trim="editTask.value"
                      variant="outlined"
                      :color="vgMedTurq"
                      class="bg-white"
                      rounded="lg"
                      hide-details
                      density="compact"
                      maxlength="200"
                      aria-label="Edit Task Title - Press Tab to save, Esc to cancel"
                      @keyup.enter="handleUpdateTitle"
                      @blur="handleUpdateTitle"
                      @keyup.esc.prevent="
                        editTask.value = '';
                        editTask.show = false;
                      "
                    />
                    <button
                      class="bg-vgmedturq ml-2 rounded-lg px-6 text-sm uppercase text-white"
                      @click="handleUpdateTitle"
                    >
                      <v-progress-circular
                        v-if="editTask.loading"
                        :size="25"
                        indeterminate
                        aria-label="Saving"
                      ></v-progress-circular>
                      <span v-else>Update</span>
                    </button>
                  </div>
                  <div v-else class="flex items-start">
                    <p
                      class="text-lg font-semibold"
                      :class="{ 'cursor-pointer': isPpaOrClient }"
                      @click="handleToggleTitle"
                    >
                      {{ task.title }}
                    </p>
                    <fa-icon
                      v-if="isPpaOrClient"
                      icon="fa-regular fa-pencil"
                      class="pl-4 pt-1 text-base text-slate-500"
                      @click="handleToggleTitle"
                    />
                  </div>
                </div>
                <button
                  v-if="
                    userStore.user?.permissions.includes('askbetty_ppa') &&
                    computedTaskDetails.assignedBetty
                  "
                  class="text-base"
                  @click="router.push(`/tasks/${task.uuid}/invite`)"
                >
                  <div class="flex self-center">
                    <div class="ml-4 flex flex-col self-center text-sm">
                      <div class="uppercase text-slate-500">
                        Assigned Assistant
                      </div>
                      <div class="font-semibold">
                        {{
                          computedTaskDetails.assignedBetty.known_as ||
                          computedTaskDetails.assignedBetty.display_name
                        }}
                      </div>
                    </div>
                    <v-avatar size="42" class="ml-1">
                      <img
                        :src="
                          computedTaskDetails.assignedBetty.avatar_id
                            ? `${API_URL}/file/avatar/${computedTaskDetails.assignedBetty.avatar_id}`
                            : '/img/img-quote_avatar.png'
                        "
                        alt="avatar"
                      />
                    </v-avatar>
                  </div>
                </button>
              </div>
              <h2
                class="mb-4 mt-8 text-sm font-semibold uppercase text-slate-600"
              >
                Task Description
              </h2>
              <div v-if="editDescription.show" class="flex flex-col">
                <QuillEditor
                  ref="descriptionEditor"
                  v-model:content="editDescription.value"
                  content-type="html"
                  class="min-h-[10rem]"
                  @click="$refs.descriptionEditor.focus()"
                  @ready="() => $refs.descriptionEditor.focus()"
                />
                <div class="mt-4 flex justify-end gap-4">
                  <button
                    v-if="!editDescription.loading"
                    class="text-vgmedturq border-vgmedturq rounded-lg border border-solid bg-white p-2 px-6 text-sm uppercase"
                    @click="editDescription.show = false"
                  >
                    Cancel
                  </button>
                  <div class="text-right">
                    <button
                      class="bg-vgmedturq rounded-lg p-2 px-6 text-sm uppercase text-white"
                      :loading="editDescription.loading"
                      @click="handleUpdateDescription"
                    >
                      <v-progress-circular
                        v-if="editDescription.loading"
                        :size="25"
                        indeterminate
                        aria-label="Saving"
                      ></v-progress-circular>
                      <span v-else>Update</span>
                    </button>
                  </div>
                </div>
              </div>
              <div v-else class="flex items-start">
                <div
                  v-dompurify-html="
                    task.description || taskDescriptionPlaceholder
                  "
                  class="revert-tailwind text-base"
                  :class="{
                    'cursor-pointer': isPpaOrClient,
                    'text-slate-600': !task.description,
                  }"
                  @click="handleToggleDescription"
                ></div>
                <fa-icon
                  v-if="isPpaOrClient"
                  icon="fa-regular fa-pencil"
                  class="pl-4 text-base text-slate-500"
                  @click="
                    editDescription = {
                      value: task.description,
                      show: true,
                      loading: false,
                    }
                  "
                />
              </div>

              <div>
                <div
                  v-if="
                    task.changeRequests?.length > 0 || editChangeRequest.show
                  "
                  id="task-change-requests"
                  class="bg-vgmedturq-200/20 mt-4 flex flex-col gap-8 rounded-xl p-4"
                >
                  <div
                    v-for="request in task.changeRequests"
                    :key="request.uuid"
                  >
                    <div class="">
                      <div class="flex items-center">
                        <v-avatar size="42">
                          <img
                            :src="
                              request.avatar_id
                                ? `${API_URL}/file/avatar/${request.avatar_id}`
                                : '/img/img-quote_avatar.png'
                            "
                          />
                        </v-avatar>
                        <div class="w-full pl-2">
                          <div
                            class="flex justify-between text-base font-semibold text-slate-700"
                          >
                            <div>
                              {{
                                request.created_by === userStore.user?.uuid
                                  ? 'You'
                                  : `${request.known_as}`
                              }}
                            </div>
                            <div>
                              {{ formatShortDateTime(request.created_date) }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-dompurify-html="request.description"
                        class="ml-[3.2rem] text-base"
                      ></div>
                    </div>
                  </div>
                  <div v-if="editChangeRequest.show" class="mt-4 flex flex-col">
                    <h2 class="font-semibold">Change Request:</h2>
                    <QuillEditor
                      ref="changeRequestEditor"
                      v-model:content="editChangeRequest.value"
                      content-type="html"
                      class="min-h-[5rem]"
                      @click="$refs.changeRequestEditor.focus()"
                      @ready="() => $refs.changeRequestEditor.focus()"
                    />
                    <div class="mt-4 flex justify-end gap-4">
                      <button
                        v-if="!editChangeRequest.loading"
                        class="text-vgmedturq border-vgmedturq rounded-lg border border-solid bg-white p-2 px-6 text-sm uppercase"
                        @click="editChangeRequest.show = false"
                      >
                        Cancel
                      </button>
                      <div class="text-right">
                        <button
                          class="bg-vgmedturq rounded-lg p-2 px-6 text-sm uppercase text-white"
                          :loading="editChangeRequest.loading"
                          @click="handleSaveChangeRequest"
                        >
                          <v-progress-circular
                            v-if="editChangeRequest.loading"
                            :size="25"
                            indeterminate
                            aria-label="Saving"
                          ></v-progress-circular>
                          <span v-else>Submit Request</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="text-vgstone">No updates</div>
              </div>
              <div class="my-4 flex flex-row justify-end gap-4 md:justify-end">
                <button
                  v-if="
                    userStore.user?.permissions.includes('askbetty_ppa') &&
                    task.status === 'new'
                  "
                  class="bg-vgmedturq rounded-full p-4 text-base font-semibold uppercase text-white"
                  :loading="taskStatusLoading"
                  @click="handleUpdateTaskStatus('active')"
                >
                  <fa-icon icon="fa-regular fa-user-plus" />
                  Hand off to
                  {{
                    userStore.user.permissions.includes('askbetty_ppa')
                      ? 'me'
                      : task.ppa_known_as
                  }}
                </button>
                <button
                  v-else-if="
                    task.status === 'active' &&
                    userStore.user?.permissions.includes('askbetty_ppa') &&
                    !computedTaskDetails.assignedBetty &&
                    task.bettys?.length > 0
                  "
                  class="border-vgmedturq text-vgmedturq rounded-full border border-solid bg-[#D2E8F880] p-4 text-base font-semibold uppercase"
                  :loading="taskStatusLoading"
                  @click="router.push(`/tasks/${task.uuid}/invite`)"
                >
                  <fa-icon icon="fa-regular fa-check" />
                  Invites sent
                </button>
                <button
                  v-else-if="
                    task.status === 'active' &&
                    userStore.user?.permissions.includes('askbetty_ppa') &&
                    !computedTaskDetails.assignedBetty
                  "
                  class="bg-vgmedturq rounded-full p-4 text-base font-semibold uppercase text-white"
                  :loading="taskStatusLoading"
                  @click="router.push(`/tasks/${task.uuid}/invite`)"
                >
                  <fa-icon icon="fa-regular fa-user-plus" />
                  Invite Assistant(s) to task
                </button>
                <button
                  v-else-if="
                    computedTaskDetails.taskStatus === 'in progress' &&
                    task.ongoing_time_entry === null &&
                    userStore.user?.permissions.includes('askbetty_va')
                  "
                  class="bg-vgmedturq rounded-full p-4 text-base font-semibold uppercase text-white"
                  :loading="taskStatusLoading"
                  @click="confirmTaskMinutes('ppa to client review')"
                >
                  Ready for review / approval
                </button>
                <template
                  v-else-if="
                    (task.status === 'ppa to client review' ||
                      task.status === 'ppa to betty review' ||
                      task.status === 'client review') &&
                    userStore.user?.permissions.includes('askbetty_ppa') &&
                    !editChangeRequest.show
                  "
                >
                  <button
                    class="text-vgmedturq border-1 border-vgmedturq rounded-full border border-solid bg-white p-4 text-base font-semibold uppercase"
                    @click="editChangeRequest.show = true"
                  >
                    Request Changes
                  </button>
                  <button
                    v-if="task.status !== 'client review'"
                    class="bg-vgmedturq rounded-full p-4 text-base font-semibold uppercase text-white"
                    :loading="taskStatusLoading"
                    @click="confirmTaskMinutes('client review')"
                  >
                    Post to client for review
                  </button>
                </template>
              </div>
            </div>
          </div>
          <div class="mt-8 min-h-[10rem]" aria-live="polite">
            <h3 class="text-vgnavy mx-4 font-semibold">
              <fa-icon icon="fa-regular fa-list-tree" />
              Subtasks ({{ task.subtasks.length }})
            </h3>
            <div
              class="mx-4 mt-2 rounded-xl border border-solid border-slate-100 bg-white p-4 text-sm shadow-md md:mx-0 md:shadow-none"
            >
              <div
                v-for="subtask in [
                  ...task.subtasks,
                  editSubtask && !editSubtask.uuid ? editSubtask : null,
                ].filter(Boolean)"
                :key="subtask.uuid || 'new'"
                class="flex gap-4 border-b-2 border-solid border-slate-100 px-2 py-1"
              >
                <template v-if="editSubtask?.uuid === subtask.uuid">
                  <v-text-field
                    ref="subtaskTitleInput"
                    v-model.trim="editSubtask.title"
                    aria-label="Add a new subtask - Press Tab to save, Esc to cancel, Enter to save and add another"
                    variant="outlined"
                    :color="vgMedTurq"
                    class="bg-white"
                    rounded="lg"
                    hide-details
                    density="compact"
                    maxlength="200"
                    @keyup.enter="
                      addSubtask.enterPressed = true;
                      handleUpdateSubtasks();
                    "
                    @blur="handleUpdateSubtasks()"
                    @keyup.esc="
                      editSubtask = null;
                      addSubtask.enterPressed = false;
                    "
                  />
                  <button
                    class="bg-vgmedturq rounded-lg px-6 text-sm uppercase text-white"
                    @click="handleUpdateSubtasks()"
                  >
                    <v-progress-circular
                      v-if="editingSubtaskLoading"
                      :size="25"
                      indeterminate
                      aria-label="Saving"
                    ></v-progress-circular>
                    <span v-else>{{
                      editSubtask.uuid === null ? 'Add' : 'Update'
                    }}</span>
                  </button>
                </template>
                <template v-else>
                  <div class="mx-2 flex flex-auto flex-row items-start gap-2">
                    <v-checkbox
                      v-model="subtask.status"
                      class="flex-none"
                      true-value="complete"
                      false-value="new"
                      hide-details
                      density="compact"
                      :disabled="
                        !userStore.user.permissions.includes(
                          'askbetty_client'
                        ) &&
                        !userStore.user.permissions.includes('askbetty_ppa') &&
                        task.relationship_type !== 'assigned'
                      "
                      @update:modelValue="handleUpdateSubtaskStatus(subtask)"
                    />
                    <div
                      class="flex-1 self-center text-base"
                      :class="{
                        'text-slate-500 line-through':
                          subtask.status === 'complete',
                        'cursor-pointer': isPpaOrClient,
                      }"
                      @click="handleToggleSubtaskTitle(subtask)"
                    >
                      {{ subtask.title }}
                    </div>
                    <div id="subtask-menu-container">
                      <v-menu
                        location="bottom"
                        attach="#subtask-menu-container"
                      >
                        <template #activator="{ props }">
                          <button
                            v-bind="props"
                            class="focus:ring-vgmedturq rounded p-2 focus:ring-2"
                            aria-label="Credit card menu"
                            title="Credit card menu"
                          >
                            <fa-icon icon="fa-regular fa-ellipsis" />
                          </button>
                        </template>
                        <div
                          class="text-vgmedturq-600 flex flex-col rounded-lg border border-slate-100 shadow-sm shadow-gray-600"
                        >
                          <button
                            v-for="(item, i) in options"
                            :key="i"
                            :aria-label="item.title"
                            class="hover:bg-vgmedturq-100 focus:bg-vgmedturq-100 focus:ring-vgmedturq bg-white px-4 py-2 first:rounded-t-lg last:rounded-b-lg focus:ring-2"
                            @click="item.action(subtask)"
                          >
                            {{ item.title }}
                          </button>
                        </div>
                      </v-menu>
                    </div>
                  </div>
                </template>
              </div>
              <button
                v-if="isPpaOrClient || task.relationship_type === 'assigned'"
                class="text-vgmedturq flex gap-2 p-2 pl-4 text-sm font-semibold uppercase"
                @click="handleAddSubtask"
              >
                <fa-icon icon="fa-regular fa-plus" class="text-xl font-bold" />
                <span class="self-center"> Add subtask </span>
              </button>
            </div>
          </div>
          <div class="mt-8 min-h-[10rem]">
            <h3 class="text-vgnavy mx-4 block font-semibold">
              <fa-icon icon="fa-regular fa-link" />
              Files and assets
            </h3>
            <div
              class="mx-4 mb-8 mt-2 rounded-xl border border-solid border-slate-100 bg-white p-2 shadow-md md:mx-0 md:shadow-none"
            >
              <div
                v-if="attachments.length > 0"
                class="flex w-full gap-4 border-b-2 border-solid border-slate-100 p-4 text-sm font-semibold uppercase text-slate-700"
              >
                <div class="w-8 tracking-wider">User</div>
                <div v-if="!uiStore.mobile" class="w-8 tracking-wider">
                  Type
                </div>
                <div class="min-w-[8rem] flex-1 tracking-wider">File Name</div>
                <div
                  class="w-32 tracking-wider"
                  :class="uiStore.tabletOrSmaller ? 'mr-[2rem]' : 'xl:w-40'"
                >
                  Date Added
                </div>
                <div v-if="!uiStore.mobile" class="w-16 tracking-wider">
                  Size
                </div>
                <div
                  v-if="!uiStore.tabletOrSmaller"
                  class="w-20 tracking-wider"
                >
                  Actions
                </div>
              </div>
              <div
                v-for="attachment in attachments"
                :key="attachment.attachmentKey"
                class="flex w-full items-center gap-4 border-b-2 border-solid border-slate-100 px-4 py-2 text-base"
              >
                <v-avatar size="32">
                  <img :src="getAttachmentAvatar(attachment)" />
                </v-avatar>
                <div v-if="!uiStore.mobile" class="w-6 pl-2 text-center">
                  <fa-icon :icon="getTypeIcon(attachment.mimetype)" />
                </div>
                <a
                  class="min-w-[8rem] flex-1 overflow-hidden text-ellipsis px-2"
                  :aria-label="
                    attachment.mimetype === 'application/dataurl'
                      ? `Open link ${attachment.url}`
                      : `Download file ${attachment.file_name}`
                  "
                  :href="
                    attachment.mimetype === 'application/dataurl'
                      ? attachment.url
                      : `${API_URL}/askbetty/v2/task/attachment/${task.uuid}/${attachment.attachmentKey}/${attachment.file_name}`
                  "
                  target="_blank"
                >
                  {{
                    attachment.mimetype === 'application/dataurl'
                      ? attachment.url
                      : attachment.file_name
                  }}
                </a>
                <div
                  class="w-32"
                  :class="{ 'xl:w-40': !uiStore.tabletOrSmaller }"
                >
                  {{ formatDate(attachment.created_date) }}
                </div>
                <div v-if="!uiStore.mobile" class="w-16">
                  {{
                    formatBytes(
                      attachment.mimetype === 'application/dataurl'
                        ? ''
                        : attachment.file_size
                    )
                  }}
                </div>
                <div
                  v-if="!uiStore.tabletOrSmaller"
                  class="w-20 pr-4 text-center text-base text-slate-500"
                >
                  <a
                    v-if="attachment.mimetype === 'application/dataurl'"
                    :href="attachment.url"
                    target="_blank"
                  >
                    <fa-icon icon="fa-regular fa-arrow-up-right-from-square" />
                  </a>
                  <a
                    v-else
                    :href="`${API_URL}/askbetty/v2/task/attachment/${task.uuid}/${attachment.attachmentKey}/${attachment.file_name}`"
                    target="_blank"
                  >
                    <fa-icon icon="fa-regular fa-download"
                  /></a>
                  <fa-icon
                    v-if="
                      isPpaOrClient || task.relationship_type === 'assigned'
                    "
                    icon="fa-regular fa-trash"
                    class="ml-4"
                    @click="handleDeleteAttachment(attachment)"
                  />
                </div>
                <v-menu v-else>
                  <template #activator="{ props }">
                    <fa-icon icon="fa-regular fa-ellipsis" v-bind="props" />
                  </template>
                  <div
                    class="text-vgmedturq-600 flex w-[8rem] flex-col rounded-lg border border-slate-100 text-center shadow-sm shadow-gray-600"
                  >
                    <a
                      v-if="attachment.mimetype === 'application/dataurl'"
                      class="hover:bg-vgmedturq-100 focus:bg-vgmedturq-100 focus:ring-vgmedturq bg-white px-4 py-2 first:rounded-t-lg last:rounded-b-lg focus:ring-2"
                      :href="attachment.url"
                      target="_blank"
                    >
                      Open
                    </a>
                    <a
                      v-else
                      class="hover:bg-vgmedturq-100 focus:bg-vgmedturq-100 focus:ring-vgmedturq bg-white px-4 py-2 first:rounded-t-lg last:rounded-b-lg focus:ring-2"
                      :href="`${API_URL}/askbetty/v2/task/attachment/${task.uuid}/${attachment.attachmentKey}/${attachment.file_name}`"
                      target="_blank"
                    >
                      Download
                    </a>
                    <button
                      v-if="
                        isPpaOrClient || task.relationship_type === 'assigned'
                      "
                      class="hover:bg-vgmedturq-100 focus:bg-vgmedturq-100 focus:ring-vgmedturq bg-white px-4 py-2 first:rounded-t-lg last:rounded-b-lg focus:ring-2"
                      @click="handleDeleteAttachment(attachment)"
                    >
                      Delete
                    </button>
                  </div>
                </v-menu>
              </div>
              <div
                v-if="showUploadAttachment"
                class="flex items-start gap-4 border-b border-solid border-slate-100 p-2"
              >
                <v-file-input
                  id="attachment_input"
                  ref="attachment_input"
                  v-model="file"
                  :color="vgMedTurq700"
                  prepend-icon=""
                  variant="outlined"
                  density="compact"
                  chips
                >
                  <template #prepend-inner>
                    <fa-icon
                      icon="fa-regular fa-paperclip-vertical"
                      class="text-vgstone -mt-1 pr-2 text-lg"
                      @click="
                        $refs.attachment_input.$el
                          .querySelector('input')
                          .click()
                      "
                    />
                  </template>
                </v-file-input>
                <button
                  v-if="!addAttachmentLoading"
                  class="text-vgmedturq border-vgmedturq rounded-lg border border-solid bg-white px-4 py-3 text-sm uppercase"
                  @click="
                    file = [];
                    showUploadAttachment = false;
                  "
                >
                  Cancel
                </button>
                <button
                  class="bg-vgmedturq rounded-lg px-4 py-3 text-sm uppercase text-white"
                  :disabled="addAttachmentLoading"
                  @click="handleUploadAttachment"
                >
                  <v-progress-circular
                    v-if="addAttachmentLoading"
                    :size="25"
                    indeterminate
                    aria-label="Saving"
                  ></v-progress-circular>
                  <span v-else>Upload</span>
                </button>
              </div>
              <div
                v-else-if="showAddUrl"
                class="flex gap-4 border-b border-solid border-slate-300 p-2"
              >
                <v-text-field
                  ref="newUrlInput"
                  v-model.trim="newUrl"
                  variant="outlined"
                  :color="vgMedTurq"
                  class="bg-white text-base"
                  rounded="lg"
                  hide-details
                  density="compact"
                  maxlength="200"
                  aria-label="Add a new URL - Press Tab to save, Esc to cancel"
                  @blur="showAddUrl && handleAddUrl()"
                  @keyup.enter="handleAddUrl"
                  @keyup.esc="showAddUrl = false"
                />
                <button
                  class="bg-vgmedturq rounded-lg px-6 text-sm uppercase text-white"
                  :disabled="addAttachmentLoading"
                  @click="handleAddUrl"
                >
                  <v-progress-circular
                    v-if="addAttachmentLoading"
                    :size="25"
                    indeterminate
                    aria-label="Saving"
                  ></v-progress-circular>
                  <span v-else>Add</span>
                </button>
              </div>
              <div
                v-if="isPpaOrClient || task.relationship_type === 'assigned'"
                class="p-2 text-base font-semibold"
              >
                <button
                  class="text-vgmedturq mx-4 uppercase"
                  :disabled="addAttachmentLoading"
                  @click="
                    showAddUrl = true;
                    showUploadAttachment = false;
                    $nextTick(() =>
                      $refs.newUrlInput?.$el?.querySelector('input')?.focus()
                    );
                  "
                >
                  <fa-icon icon="fa-regular fa-link" />
                  Add URL
                </button>
                <button
                  class="text-vgmedturq mx-4 uppercase"
                  :disabled="addAttachmentLoading"
                  @click="handleUploadAttachmentClick"
                >
                  <fa-icon icon="fa-regular fa-file-circle-plus" />
                  Upload File
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChatWrapper
        v-if="computedTaskDetails.taskStatus !== 'new invite'"
        :type="
          userStore.user.permissions.includes('askbetty_client')
            ? 'client'
            : userStore.user.permissions.includes('askbetty_ppa') &&
              !route.meta.collaborateChat
            ? 'ppaClient'
            : 'task'
        "
        :task="task"
      />
    </div>
    <AddCreditCardDialog ref="addCreditCard"></AddCreditCardDialog>
    <TaskPaymentDialog ref="taskPayment"></TaskPaymentDialog>
    <PurchaseMinutesDialog ref="purchaseMinutes"></PurchaseMinutesDialog>
    <ConfirmMinutesDialog ref="confirmMinutes"></ConfirmMinutesDialog>
    <InfoToast ref="infoToast"></InfoToast>
  </div>
</template>
<script>
import { nextTick } from 'vue';
import { mapStores } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useUiStore } from '@/stores/ui';
import { usePpaClientStore } from '@/stores/ppaClient';
import {
  getTask,
  saveTask,
  markTaskRead,
  computeTaskDetails,
  getHandOffDate,
} from '@/services/taskService';
import { updateInvite } from '@/services/bettyService';
import { canGoBack } from '@/services/httpService';
import { getSocket } from '@/services/clientSessionService';
import {
  getTaskAttachments,
  uploadTaskAttachment,
  uploadTaskUrl,
  deleteTaskAttachment,
} from '@/services/fileService';
import {
  formatShortDateTime,
  formatBytes,
  formatDurationShort,
  formatDate,
} from '@/services/formattingService';
import { startTimer, stopTimer } from '@/services/timeEntryService';
import AddCreditCardDialog from '@/dialogs/AddCreditCardDialog.vue';
import TaskPaymentDialog from '@/dialogs/TaskPaymentDialog.vue';
import PurchaseMinutesDialog from '@/dialogs/PurchaseMinutesDialog.vue';
import ConfirmMinutesDialog from '@/dialogs/ConfirmMinutesDialog.vue';
import InfoToast from '@/dialogs/InfoToast.vue';
import { getUuid } from '@/services/utilService';
import ChatWrapper from '@/components/ChatWrapper.vue';
import { API_URL } from '@/config';
import tailwind from 'tailwind.config';
import { useRoute, useRouter } from 'vue-router';

const TASK_READ_TIMEOUT = 2000;
const handOffMessages = [
  {
    title: '🎉 Woohoo!',
    message:
      'Task successfully handed off! We’ll shout out if we have any questions. Keep rocking!',
  },
  {
    title: '🤩 Yay!',
    message:
      'Task handoff for the win! We’ll nudge you if there’s any queries. Keep crushing it!',
  },
  {
    title: '🌟 Awesome!',
    message:
      'Your task is now in expert hands! We’ll ping you if we need more info. Go conquer your day!',
  },
  {
    title: '✨ High five!',
    message:
      'Your task is on our desk! If there are any hiccups or queries, you’ll be the first to know.',
  },
];
const doneMessages = [
  'Another win for the day! 🌈 Keep this streak going.',
  'Smashed it 🎉! Ready to tackle the next one? Keep the flow going.',
  "Task conquered! 🛡️ Line up the next challenge. You're on a roll!",
  "Victory dance done? 💃 Now, let's set the stage for the next win.",
  'That felt good, right? ☺️ Dive in and set your next task. Keep the rhythm!',
  'One down, more to go! 🚀 Set up your next task and keep soaring.',
  "Success is addictive! 😎 Got another task in mind? Let's get it set.",
  'Success! 🥳 One less thing on your plate. Celebrate the small wins!',
  'Look at you go! ✨ Another task off the list. Onward!',
];

const taskDescriptionPlaceholders = [
  "The devil's in the details! The more we know, the better we perform. Click here to add your description.",
  "Let's get specific! Clear details help us help you faster. Click here to add your description.",
  'Help us understand your vision. Detailed guidance ensures flawless execution. Click here to add your description.',
  'Paint us a picture of your task! Your details are our palette. Click here to add your description.',
];

const subtypeIconMap = {
  'application/dataurl': 'fa-regular fa-link',
  'application/pdf': 'fa-regular fa-file-pdf',
  'application/msword': 'fa-regular fa-file-word',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'fa-regular fa-file-word',
  'application/vnd.ms-excel': 'fa-regular fa-file-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    'fa-regular fa-file-excel',
  'application/vnd.ms-powerpoint': 'fa-regular fa-file-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    'fa-regular fa-file-powerpoint',
  'application/zip': 'fa-regular fa-file-zip',
};

const typeIconMap = {
  image: 'fa-regular fa-file-image',
  audio: 'fa-regular fa-file-music',
  video: 'fa-regular fa-file-video',
};

export default {
  name: 'TaskDetails',
  components: {
    AddCreditCardDialog,
    TaskPaymentDialog,
    PurchaseMinutesDialog,
    ConfirmMinutesDialog,
    ChatWrapper,
    InfoToast,
  },
  data() {
    const router = useRouter();
    const route = useRoute();
    return {
      route,
      router,
      task: null,
      taskLoadError: false,
      taskReadTimeout: null,
      taskDescriptionPlaceholder: '',
      attachments: [],
      editSubtask: null,
      editingSubtaskLoading: false,
      editTask: {
        show: false,
        value: '',
        loading: false,
      },
      editDescription: {
        show: false,
        value: '',
        loading: false,
      },
      editChangeRequest: {
        show: false,
        value: '',
        loading: false,
      },
      inviteLoading: false,
      file: null,
      isPpaOrClient: false,
      newUrl: 'https://',
      showUploadAttachment: false,
      showAddUrl: false,
      addAttachmentLoading: false,
      taskStatusLoading: false,
      handOffDate: null,
      addSubtask: { first: true, enterPressed: false },
      options: [],
      API_URL,
      vgMedTurq: tailwind.theme.extend.colors.vgmedturq[500],
      vgMedTurq700: tailwind.theme.extend.colors.vgmedturq[700],
    };
  },
  computed: {
    ...mapStores(useUserStore, useUiStore, usePpaClientStore),
    status() {
      let label = this.computedTaskDetails.taskStatus;
      let classes = '';
      let heading = '';
      switch (this.task.status) {
        case 'new':
          classes = { label: 'bg-slate-800', heading: 'text-slate-800' };
          heading =
            'Your task is in draft, click “Hand off” to start the task!';
          break;
        case 'active':
        case 'ppa to client review':
        case 'ppa to betty review':
          classes = {
            label: 'bg-vgmedturq',
            heading: 'text-vgmedturq',
          };
          heading = 'We’re working on it!';
          break;
        case 'client review':
          classes = { label: 'bg-vgorange ', heading: 'text-vgorange' };
          heading = 'Please review your task!';
          break;
        case 'complete':
          classes = { label: 'bg-vgnavy', heading: 'text-vgnavy' };
          heading = 'Success, your task is done!';
          break;
      }

      return { label, classes, heading };
    },
    computedTaskDetails() {
      return computeTaskDetails(
        this.task,
        this.userStore.user?.permissions.includes('askbetty_ppa'),
        this.userStore.user?.permissions.includes('askbetty_client')
      );
    },
    minutes_balance() {
      if (this.userStore.user.permissions.includes('askbetty_client')) {
        return this.userStore.client?.client?.minutes_balance;
      }
      if (
        this.userStore.user.permissions.includes('askbetty_ppa') &&
        this.task
      ) {
        return this.ppaClientStore.clients[this.task.user_uuid]
          ?.minutes_balance;
      }
      return 0;
    },
  },
  watch: {
    'route.params.task_uuid': {
      async handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          try {
            await this.taskUpdatedListener({
              uuid: this.route.params.task_uuid,
            });
            this.handOffDate = await getHandOffDate(
              this.route.params.task_uuid
            );
          } catch (error) {
            this.$root.$snackbar.error(
              error.data?.message ??
                'An error occurred while getting task details'
            );
            window.apm?.captureError(new Error(JSON.stringify(error)));
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    getSocket().on('askbetty.task_updated', this.taskUpdatedListener);

    this.isPpaOrClient =
      this.userStore.user?.permissions.includes('askbetty_ppa') ||
      this.userStore.user?.permissions.includes('askbetty_client');

    this.taskDescriptionPlaceholder =
      taskDescriptionPlaceholders[
        Math.floor(Math.random() * taskDescriptionPlaceholders.length)
      ];

    window.addEventListener('blur', this.handleWindowBlur);
    window.addEventListener('focus', this.handleWindowFocus);

    this.options = [
      {
        title: 'Edit',
        action: (subtask) =>
          (this.editSubtask = JSON.parse(JSON.stringify(subtask))),
      },
      {
        title: 'Delete',
        action: (subtask) => this.handleDeleteSubtask(subtask),
      },
    ];
  },
  beforeUnmount() {
    getSocket().off('askbetty.task_updated', this.taskUpdatedListener);

    if (this.taskReadTimeout) {
      clearTimeout(this.taskReadTimeout);
    }

    window.removeEventListener('blur', this.handleWindowBlur);
    window.removeEventListener('focus', this.handleWindowFocus);
  },
  methods: {
    formatShortDateTime,
    formatDurationShort,
    formatBytes,
    formatDate,
    closeTask() {
      if (canGoBack()) {
        this.router.go(-1);
      } else {
        this.router.push(
          this.route.meta.showBack === 'Clients' ? '/clients' : `/tasks`
        );
      }
    },
    async taskUpdatedListener(payload) {
      if (payload.uuid === this.route.params.task_uuid) {
        this.taskLoadError = false;
        try {
          this.task = await getTask(this.route.params.task_uuid);
          this.handOffDate = await getHandOffDate(this.route.params.task_uuid);
          this.attachments = await getTaskAttachments(
            this.route.params.task_uuid
          );
          if (this.task.unread && document.visibilityState === 'visible') {
            if (!this.taskReadTimeout) {
              clearTimeout(this.taskReadTimeout);
            }
            this.taskReadTimeout = setTimeout(() => {
              markTaskRead(this.route.params.task_uuid).catch(() => {});
            }, TASK_READ_TIMEOUT);
          }
          if (
            this.userStore.user.permissions.includes('askbetty_ppa') &&
            !this.ppaClientStore.selectedClient
          ) {
            await this.ppaClientStore.selectClient(this.task.user_uuid);
          }
        } catch (error) {
          this.taskLoadError = true;
        }
      }
    },
    handleToggleTitle() {
      if (this.isPpaOrClient) {
        this.editTask = { value: this.task.title, show: true, loading: false };
        this.$nextTick(() => {
          this.$refs.editTaskTitle?.$el?.querySelector('input')?.focus();
        });
      }
    },
    async handleUpdateTitle() {
      if (!this.editTask.value || this.editTask.loading) {
        return;
      }
      if (this.editTask.value === this.task.title) {
        this.editTask.value = '';
        this.editTask.show = false;
        return;
      }
      this.editTask.loading = true;
      try {
        await saveTask({ uuid: this.task.uuid, title: this.editTask.value });
      } catch (error) {
        return this.$root.$snackbar.error(
          error.data?.message ?? 'An error occurred while updating task title'
        );
      }
      this.editTask.value = '';
      this.editTask.loading = false;
      this.editTask.show = false;
    },
    handleToggleDescription() {
      if (this.isPpaOrClient) {
        this.editDescription = {
          value: this.task.description,
          show: true,
          loading: false,
        };
      }
    },
    async handleUpdateDescription() {
      if (!this.editDescription.value || this.editDescription.loading) {
        return;
      }
      this.editDescription.loading = true;
      try {
        await saveTask({
          uuid: this.task.uuid,
          description: this.editDescription.value,
        });
      } catch (error) {
        return this.$root.$snackbar.error(
          error.data?.message ??
            'An error occurred while updating task description'
        );
      }
      this.editDescription.value = '';
      this.editDescription.loading = false;
      this.editDescription.show = false;
    },
    async handleUpdateTaskStatus(status) {
      this.taskStatusLoading = true;
      try {
        if (
          this.task.status === 'new' &&
          status === 'active' &&
          !(
            this.task.has_cc ||
            this.task.has_bypass_cc ||
            ((await this.$refs.addCreditCard.open(
              this.userStore.user,
              this.task
            )) &&
              (this.minutes_balance >= 30 ||
                (await this.$refs.purchaseMinutes.open(this.userStore.user))))
          )
        ) {
          return;
        }
        await saveTask({
          uuid: this.task.uuid,
          status,
          time_logged_minutes:
            status === 'complete' ? this.task.time_logged_minutes : undefined,
        });
        if (status === 'complete') {
          this.$refs.infoToast.open(
            'Ta-done! ',
            doneMessages[Math.floor(Math.random() * doneMessages.length)]
          );
        } else if (
          status === 'active' &&
          this.userStore.user.permissions.includes('askbetty_client')
        ) {
          const message =
            handOffMessages[Math.floor(Math.random() * handOffMessages.length)];
          this.$refs.infoToast.open(message.title, message.message);
        }
      } catch (error) {
        return this.$root.$snackbar.error(
          error.data?.message ?? 'An error occurred while updating task',
          { persist: true }
        );
      }
      this.taskStatusLoading = false;
    },
    async handleSaveChangeRequest() {
      this.taskStatusLoading = true;
      try {
        const status =
          (this.task.status === 'ppa to client review' ||
            this.task.status === 'ppa to betty review' ||
            this.task.status === 'client review') &&
          this.userStore.user?.permissions.includes('askbetty_ppa')
            ? 'active'
            : this.task.status === 'client review' &&
              this.userStore.user?.permissions.includes('askbetty_client')
            ? 'ppa to betty review'
            : this.task.status;
        await saveTask({
          uuid: this.task.uuid,
          status,
          changeRequest: this.editChangeRequest.value
            ? { description: this.editChangeRequest.value }
            : undefined,
        });
      } catch (error) {
        return this.$root.$snackbar.error(
          error.data?.message ?? 'An error occurred while saving change request'
        );
      }
      this.taskStatusLoading = false;
      this.editChangeRequest.value = '';
      this.editChangeRequest.show = false;
    },
    async handleMarkAsDone() {
      if (await this.$refs.taskPayment.open(this.task)) {
        this.handleUpdateTaskStatus('complete');
      }
    },
    async confirmTaskMinutes(nextStage) {
      if (await this.$refs.confirmMinutes.open(this.task.time_logged_minutes)) {
        this.handleUpdateTaskStatus(nextStage);
      }
    },
    async answerInvite(relationship_type) {
      this.inviteLoading = true;
      try {
        await updateInvite({
          task_uuid: this.task.uuid,
          user_uuid: this.userStore.user.uuid,
          relationship_type,
        });
        if (relationship_type === 'declined') {
          this.closeTask();
        }
      } catch (error) {
        return this.$root.$snackbar.error(
          error.data?.message ?? 'An error occurred while answering invite'
        );
      } finally {
        this.inviteLoading = false;
      }
    },
    async handleUpdateSubtaskStatus(subtask) {
      this.editSubtask = subtask;

      try {
        await this.handleUpdateSubtasks();
      } catch (error) {
        this.$root.$snackbar.error(
          error.data?.message ??
            'An error occurred while updating subtask status'
        );
      }
    },
    async handleUpdateSubtasks() {
      if (this.editingSubtaskLoading) {
        return;
      }
      if (!this.editSubtask?.title) {
        this.editSubtask = null;
        return;
      }

      if (!this.editSubtask.uuid) {
        this.editingSubtaskLoading = true;
        try {
          this.editSubtask.uuid = await getUuid().then((result) => result.uuid);
        } catch (error) {
          return this.$root.$snackbar.error(
            error.data?.message ?? 'An error occurred while updating subtask'
          );
        } finally {
          this.editingSubtaskLoading = false;
        }
        this.task.subtasks.push(this.editSubtask);
      } else {
        const subtask = this.task.subtasks.find(
          (subtask) => subtask.uuid === this.editSubtask.uuid
        );
        if (subtask) {
          if (this.editSubtask.title === subtask.title) {
            this.editSubtask = null;
            return;
          }
          subtask.title = this.editSubtask.title;
        }
      }
      try {
        this.editingSubtaskLoading = true;
        await saveTask({
          uuid: this.task.uuid,
          subtasks: this.task.subtasks.filter((task) => task.title),
        });
        this.editSubtask = null;
      } catch (error) {
        return this.$root.$snackbar.error(
          error.data?.message ?? 'An error occurred while updating subtask'
        );
      } finally {
        this.editingSubtaskLoading = false;
      }

      if (this.addSubtask.enterPressed) {
        this.handleAddSubtask();
      }

      if (this.addSubtask.first) {
        this.addSubtask.first = false;
      } else {
        this.addSubtask.enterPressed = false;
      }
    },
    async handleDeleteSubtask(subtask) {
      if (
        !(await this.$root.$confirm.open(
          `Delete subtask?`,
          `Once deleted, it can not be restored. To confirm, please enter <b>CONFIRM</b> exactly as shown here and click <b>Delete</b>.`,
          {
            okText: 'Delete',
            cancelText: 'Cancel',
            requiredEntry: 'CONFIRM',
          }
        ))
      ) {
        return;
      }

      this.task.subtasks.splice(this.task.subtasks.indexOf(subtask), 1);

      try {
        await saveTask({ uuid: this.task.uuid, subtasks: this.task.subtasks });
      } catch (error) {
        this.$root.$snackbar.error(
          error.data?.message ?? 'An error occurred while deleting the subtask'
        );
      }
    },
    handleUploadAttachmentClick() {
      this.showUploadAttachment = true;
      this.showAddUrl = false;
      nextTick(() =>
        this.$refs.attachment_input.$el.querySelector('input').click()
      );
    },
    async handleUploadAttachment() {
      if (!this.file?.[0]) {
        return this.$root.$snackbar.error('Please select a file to upload');
      }

      this.addAttachmentLoading = true;

      try {
        const attachment = await uploadTaskAttachment(
          this.task.uuid,
          this.task.user_uuid,
          this.file[0]
        );
        this.attachments.unshift(attachment);
        this.file = null;
      } catch (error) {
        this.$root.$snackbar.error(
          error?.data?.message || 'An error occurred while uploading file'
        );
      } finally {
        this.addAttachmentLoading = false;
      }
    },
    async handleAddUrl() {
      if (!this.newUrl) {
        return this.$root.$snackbar.error('Please enter a URL');
      }
      if (this.newUrl === 'https://' || this.newUrl === 'http://') {
        this.showAddUrl = false;
        return;
      }

      if (
        !(
          this.newUrl.startsWith('https://') ||
          this.newUrl.startsWith('http://')
        )
      ) {
        return this.$root.$snackbar.error(
          'URL must start with https:// or http://'
        );
      }

      this.addAttachmentLoading = true;
      try {
        const attachment = await uploadTaskUrl(this.task.uuid, {
          url: this.newUrl,
          filename:
            this.newUrl.split('/').at(-1) ||
            this.newUrl.substring(this.newUrl.length - 100, this.newUrl.length),
          user_uuid: this.task.user_uuid,
        });
        this.attachments.unshift(attachment);
        this.showAddUrl = false;
        this.newUrl = 'https://';
      } catch (error) {
        this.$root.$snackbar.error(
          error?.data?.message || 'An error occurred while adding URL'
        );
      } finally {
        this.addAttachmentLoading = false;
      }
    },
    handleToggleSubtaskTitle(subtask) {
      if (this.isPpaOrClient) {
        this.editSubtask = JSON.parse(JSON.stringify(subtask));
        this.$nextTick(() => {
          this.$refs.subtaskTitleInput?.[0]?.$el
            ?.querySelector('input')
            ?.focus();
        });
      }
    },
    async handleAddSubtask() {
      if (this.editSubtask) {
        await this.handleUpdateSubtasks();
      }
      this.editSubtask = { status: 'new', title: '', uuid: null };
      nextTick(() => {
        const inputElement = this.$refs.subtaskTitleInput?.[0]?.$el?.querySelector(
          'input'
        );

        if (inputElement) {
          inputElement.focus();
          this.addSubtask.first ||
            inputElement.setAttribute('aria-label', 'Add another subtask');
        }
      });
    },
    getTypeIcon(mimetype) {
      if (!mimetype) {
        return 'fa-regular fa-file';
      }

      return (
        subtypeIconMap[mimetype] ??
        typeIconMap[mimetype.split('/')[0]] ??
        'fa-regular fa-file'
      );
    },
    async handleDeleteAttachment(attachment) {
      if (
        !(await this.$root.$confirm.open(
          `Delete attachment?`,
          `Once deleted, it can not be restored. To confirm, please enter <b>CONFIRM</b> exactly as shown here and click <b>Delete</b>.`,
          {
            okText: 'Delete',
            cancelText: 'Cancel',
            requiredEntry: 'CONFIRM',
          }
        ))
      ) {
        return;
      }
      try {
        await deleteTaskAttachment(this.task.uuid, attachment.attachmentKey);

        this.attachments = this.attachments.filter(
          (formAttachment) =>
            formAttachment.attachmentKey !== attachment.attachmentKey
        );
      } catch (error) {
        this.$root.$snackbar.error(
          error?.data?.message ?? 'Failed to delete attachment'
        );
      }
    },
    async handleStartTimerClick(task) {
      try {
        await startTimer(task);
      } catch (error) {
        this.$root.$snackbar.error(
          error?.data?.message || 'Unable to start timer'
        );
      }
    },
    async handleStopTimerClick(task) {
      try {
        await stopTimer(task);
      } catch (error) {
        this.$root.$snackbar.error(
          error?.data?.message || 'Unable to stop timer'
        );
      }
    },
    getAttachmentAvatar(attachment) {
      if (this.userStore.user.permissions.includes('askbetty_client')) {
        if (attachment.created_by !== this.userStore.user.uuid) {
          return this.userStore.client?.ppa?.avatar_id
            ? `${API_URL}/file/avatar/${this.userStore.client.ppa.avatar_id}`
            : '/img/img-quote_avatar.png';
        }
      }
      return attachment.created_by_avatar
        ? `${API_URL}/file/avatar/${attachment.created_by_avatar}`
        : '/img/img-quote_avatar.png';
    },
    handleWindowBlur() {
      if (this.taskReadTimeout) {
        clearTimeout(this.taskReadTimeout);
      }
    },
    handleWindowFocus() {
      if (this.task?.unread) {
        this.taskReadTimeout = setTimeout(() => {
          markTaskRead(this.route.params.task_uuid).catch(() => {});
        }, TASK_READ_TIMEOUT);
      }
    },
  },
};
</script>
<style scoped>
* :deep(.v-field__input) {
  font-size: 14px;
}
.revert-tailwind :deep(*) {
  all: revert;
}
.revert-tailwind :deep(p) {
  margin: 0;
}
</style>
